<template>
  <div
    class="material-card-content"
  >
    <div slot="header">
      <p>
        {{ description }}
      </p>
      <br>
      <button
        class="button is-primary"
        @click="crossHelper.openExternalLink(`${$websiteUrl}/contact-us`, true)"
      >
        Upgrade now
      </button>
    </div>
  </div>
</template>
<script>
import crossHelper from '@/helpers/cross';

export default {
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      crossHelper,
    };
  },
};
</script>
