<template>
  <card
    v-if="totalData > 0"
    external-card="material-card"
  >
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            Updates
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>
      <card
        v-if="hasCapacity"
        internal-card="card-content"
      >
        <div slot="body">
          <div style="display: flex; justify-content: space-between; flex-wrap: wrap; gap:10px">
            <div>
              <p v-if="device.pendingUpdatesCount != -1">
                <strong>Windows updates: </strong>
                {{ device.pendingUpdatesCount === 0 ? 'The system is up to date' :
                  (device.pendingUpdatesCount === 1 ? '1 Update pending' :
                    `${device.pendingUpdatesCount} Updates pending`) }}
              </p>
              <p v-if="device.windowsAutoUpdateStatus !== null">
                <strong>Windows auto update:</strong>
                {{ device.windowsAutoUpdateStatus ? 'Enabled' : 'Disabled' }}
              </p>
            </div>

            <div style="display: flex; flex-wrap: wrap; gap: 10px; flex-basis: content;">
              <b-button
                v-for="action in updateActions"
                v-show="validateIfHasPermission([permissions.UPDATE_EXECUTIONS.value.dbValue])"
                :key="action.id"
                class="is-primary"
                @click="startExecution(action)"
              >
                {{ action.name }}
              </b-button>
            </div>
          </div>
          <br>
          <strong>Installed updates ({{ totalData }}) </strong>
          <b-table
            :data="updatesResult"
            :mobile-cards="false"
            :per-page="maxQuantity"
            :paginated="totalData > maxQuantity"
            :loading="updatesResult.length === 0 && isFindUpdatesPending"
            :current-page.sync="currentPage"
            backend-pagination
            backend-sorting
            :total="totalData"
            default-sort="title"
            style="flex:1"
            @page-change="changePagination"
            @sort="sortTable"
          >
            <b-table-column
              field="title"
              label="Title"
              sortable
            >
              <template v-slot="props">
                {{ props.row.title }}
              </template>
            </b-table-column>

            <b-table-column
              field="updateId"
              label="Update Id"
              sortable
            >
              <template v-slot="props">
                {{ props.row.updateId }}
              </template>
            </b-table-column>

            <b-table-column
              field="installedOn"
              label="Installed on"
              sortable
            >
              <template v-slot="props">
                <div>
                  <timeago
                    :key="props.row.id"
                    :since="props.row.installedOn"
                    no-future
                  />
                </div>
              </template>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="file"
                      size="is-large"
                    />
                  </p>
                  <p>There are no updates to show.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </card>
      <disabled-feature
        v-else
        description="With this feature you can manage the Windows updates in your computers. Your
        Boardgent plan does not support it."
      />
    </b-collapse>
  </card>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import disabledFeature from '@/components/errors/DisabledFeature.vue';
import snackBarMessage from '@/helpers/snackBarMessage';
import ExecutionsMixin from '@/mixins/executions';
import RolesMixin from '@/mixins/roles';
import CompanyMixin from '../../mixins/company';
import vtulEnums from '../../../../cross';

const { executeAction: executeActionEnum } = vtulEnums.enum.execution;
const { permissions } = vtulEnums.enum.roles;

export default {
  name: 'DeviceUpdates',
  components: {
    card,
    learnMore,
    'disabled-feature': disabledFeature,
  },
  mixins: [ExecutionsMixin, CompanyMixin, RolesMixin, makeFindMixin({ service: 'updates', watch: true })],
  props: {
    device: {
      type: Object,
      default() {
        return { };
      },
    },
    maxQuantity: {
      type: Number,
      default: 0,
    },
    hasCapacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limitUpdates: this.maxQuantity,
      skipUpdates: 0,
      currentPage: 1,
      sortDirection: 'asc',
      sortField: 'title',
      activeTab: 0,
      updateActions: [
        {
          id: executeActionEnum.UPDATE_OPERATING_SYSTEM.value.id,
          name: 'Update Operating System',
          message: 'Updating OS...',
        },
        {
          id: executeActionEnum.GET_UPDATES_STATUS.value.id,
          name: 'Check OS available updates',
          message: 'Checking available updates...',
        },
      ],
      permissions,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    currentPaginationQuery() {
      return {
        $limit: this.limitUpdates,
        $skip: this.skipUpdates,
      };
    },
    queryForSearchUpdates() {
      const query = {
        $sort: { [this.sortField]: this.sortDirection === 'asc' ? [1, 1] : [-1, -1] },
        deviceId: this.device.id,
        companyId: this.currentCompany.id,
        enabled: true,
      };
      return query;
    },
    updatesParams() {
      const queryWithoutSpecialFilters = JSON.parse(JSON.stringify(this.queryForSearchUpdates));
      delete queryWithoutSpecialFilters.$client;
      queryWithoutSpecialFilters.$sort = { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 };
      return {
        query: {
          ...this.currentPaginationQuery,
          ...queryWithoutSpecialFilters,
        },
      };
    },
    updatesFetchParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchUpdates,
        },
      };
    },
    updatesResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.updatesLatestQuery || !this.updatesPaginationData.default
        || !this.updatesPaginationData.default[this.updatesLatestQuery.queryId]
        || !this.updatesPaginationData.default[this.updatesLatestQuery.queryId][paginationId]) {
        return [];
      }

      const { queryId } = this.updatesLatestQuery;
      let updatesIds = this.updatesPaginationData.default[queryId][paginationId].ids;
      if (this.currentPage === 1) {
        // This is a trick to display the new updates reactive
        const filterUpdates = this.updates;
        updatesIds = filterUpdates.map((x) => x.id).concat(updatesIds);
        updatesIds = [...new Set(updatesIds)];
      }

      const updates = this.findUpdatesInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: updatesIds,
          },
          $limit: this.limitUpdates,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
        },
      });

      return updates.data;
    },
    totalData() {
      if (!this.updatesLatestQuery || !this.updatesPaginationData.default
          || !this.updatesPaginationData.default[this.updatesLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.updatesLatestQuery;
      const { total } = this.updatesPaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitUpdates);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }
      return total;
    },
  },
  watch: {
    device() {
      this.activeTab = 0;
    },
  },
  methods: {
    async startExecution(action) {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id], action.id);
      const router = this.$router;
      snackBarMessage.showSuccess(action.message, {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipUpdates = (this.currentPage - 1) * this.limitUpdates;
    },
    sortTable(field, direction) {
      this.sortField = field;
      this.sortDirection = direction;
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
