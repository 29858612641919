<template>
  <div>
    <div
      v-if="validateIfHasCapability([
        planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      ])"
    >
      <card
        v-if="validateIfHasPermission([
          permissions.SEE_LOCAL_PASSWORDS.value.dbValue,
          permissions.LOCAL_PASSWORD_EXECUTIONS.value.dbValue])"
        external-card="material-card mb-3"
      >
        <b-collapse
          slot="external-header"
          :open="false"
          class="card"
        >
          <card
            slot="trigger"
            slot-scope="props"
            internal-card="card-header"
            style-internal-header="display:flex; width:100%"
            style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
          >
            <p
              slot="header"
              class="card-header-title header-title"
            >
              Operating system local administrator
            </p>
            <a
              slot="body"
              class="card-header-icon"
            >
              <b-icon
                v-show="props.open"
                icon="menu-up"
              />
              <b-icon
                v-show="!props.open"
                icon="menu-down"
              />
            </a>
          </card>
          <card
            internal-card="material-card-content"
          >
            <div
              slot="header"
              style="width:100%"
            >
              <b-message
                v-if="activeProfile.localAccountPasswordAutoChange"
                type="is-success"
                style="margin-bottom: 8px;"
              >
                <p class="center-vertically-action-button">
                  <span>
                    Boardgent changes this password automatically every {{ frecuenciesArray
                      .find(
                        (frequency) => frequency.minutes === activeProfile.localAccountPasswordAge)
                      .name }}.
                    You can change this behavior in settings of the
                    <router-link
                      :to="{ name: 'group', params: { profileId: activeProfile.id }}"
                    >
                      {{ activeProfile.name }}
                    </router-link> device group.
                  </span>
                </p>
              </b-message>
              <b-message
                v-else
                type="is-warning"
                style="margin-bottom: 8px;"
              >
                <p class="center-vertically-action-button">
                  <span>
                    There is not enabled an automatic password change.
                    You can enable it in settings of the
                    <router-link
                      :to="{ name: 'group', params: { profileId: activeProfile.id }}"
                    >{{ activeProfile.name }}</router-link> device group.
                  </span>
                </p>
              </b-message>
              <b-message
                v-if="localAccount && localAccount.isManagementPaused"
                type="is-danger"
                style="margin-bottom: 8px;"
              >
                <p class="center-vertically-action-button">
                  <span>
                    <span>
                      {{ localPasswordManagementPausedError }}
                    </span>
                    <br>
                  </span>
                  <button
                    class="button is-primary is-pulled-right"
                    @click="resumeLocalPasswordManagement"
                  >
                    Confirm
                  </button>
                </p>
              </b-message>
              <div class="columns">
                <div class="column">
                  <p
                    class="centerVertically informationText"
                  >
                    <strong>Username: </strong>
                    <span>
                      {{ localAccount && localAccount.username
                        ? localAccount.username : 'Unknown' }}
                    </span>
                  </p>
                  <p
                    v-if="passwordChangeLastSuccessResultTimeStamp
                      && localAccount.passwordChangeLastResult !== programmerAgentErrors
                        .LOCAL_ACCOUNT_NOT_FOUND.value.message"
                    class="centerVertically informationText"
                  >
                    <strong>Current password: </strong>
                    <span
                      v-if="localAccountPassword"
                      class="password-style material-card-content is-size-7-mobile is-rounded"
                    >
                      <span
                        v-for="(letter, index) in localAccountPassword.split('')"
                        :key="index"
                        :class="getClass(letter)"
                      >
                        {{ letter }}
                      </span>
                    </span>
                    <span v-else>
                      **********
                    </span>
                    <section
                      v-if="validateIfHasPermission(
                        [permissions.SEE_LOCAL_PASSWORDS.value.dbValue]
                      )"
                    >
                      <b-button
                        v-if="!localAccountPassword"
                        :class="{'is-loading': isGettinglocalAccountPassword }"
                        class="button is-small is-primary ml-1"
                        icon-left="eye"
                        @click="validateIfNeedReason('Local Account Password',
                                                     getLocalAccountPassword)"
                      >
                        Reveal
                      </b-button>
                      <b-button
                        v-else
                        icon-left="eye-off"
                        class="button is-small is-primary"
                        @click="getLocalAccountPassword"
                      >
                        Conceal
                      </b-button>
                      <b-button
                        v-if="localAccountPassword"
                        v-clipboard:copy="localAccountPassword"
                        class="button is-small is-primary is-outlined ml-1"
                        icon-left="clipboard-outline"
                        @click="showToastCopy"
                      >
                        Copy
                      </b-button>
                    </section>
                  </p>
                </div>
                <div class="column">
                  <div>
                    <p class="informationText">
                      <strong>Last change: </strong>
                      <span v-if="passwordChangeLastSuccessResultTimeStamp">
                        <timeago
                          :since="passwordChangeLastSuccessResultTimeStamp"
                          no-future
                        />
                      </span>
                      <span v-else>
                        Never
                      </span>
                    </p>
                    <p
                      v-if="localAccount && localAccount.passwordChangeLastResult
                        && localAccount.passwordChangeLastResult !== 'Success'"
                      class="informationText"
                    >
                      <strong>Last change attempt: </strong>
                      <span v-if="passwordChangeLastResultTimeStamp">
                        <timeago
                          :since="passwordChangeLastResultTimeStamp"
                          no-future
                        />
                      </span>
                      <span v-else>
                        Never
                      </span>
                    </p>
                    <p
                      v-if="localAccount && localAccount.passwordChangeLastResult
                        && localAccount.passwordChangeLastResult !== 'Success'"
                    >
                      <strong>Last change attempt result: </strong>
                      {{ localAccount.passwordChangeLastResult }}
                    </p>

                    <p
                      v-if="localAccount"
                      class="centerVertically informationText"
                    >
                      <strong>Next automatic change: </strong>
                      <span
                        v-if="localPasswordExpirationDate"
                      >
                        <span
                          v-if="isDeviceOnline || localPasswordExpirationDate.isAfter()"
                        >
                          <timeago
                            :since="localPasswordExpirationDate.toISOString()"
                            no-past
                          />
                        </span>
                        <span v-else>
                          When the device goes online
                        </span>
                      </span>
                      <span v-else>
                        Not set
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <hr>
              <div style="display: flex; flex-wrap: wrap; gap: 8px; justify-content:center ">
                <b-button
                  v-if="validateIfHasPermission([permissions.SEE_LOCAL_PASSWORDS.value.dbValue])"
                  :class="{'is-loading': isGettingLocalUserPasswordHistory }"
                  icon-left="eye"
                  class="button is-primary"
                  style="margin-right:10px"
                  @click="validateIfNeedReason('Local Account Password History',
                                               getLocalUserPasswordHistory)"
                >
                  <span class="is-size-7-mobile">Reveal history</span>
                </b-button>
                <button
                  v-if="validateIfHasPermission(
                    [permissions.LOCAL_PASSWORD_EXECUTIONS.value.dbValue]
                  )"
                  class="button is-primary"
                  @click="expiratelocalAccountPassword"
                >
                  <span class="is-size-7-mobile">Change password now</span>
                </button>
              </div>
            </div>
          </card>
        </b-collapse>
      </card>
    </div>
    <div
      v-if="biosVendorIsSupported(device.vendor) && validateIfHasCapability([
        planCapabilities.BIOS_PASSWORD_MANAGER.value, ]) && validateIfHasPermission([
        permissions.SEE_BIOS_PASSWORDS.value.dbValue,
        permissions.BIOS_MANAGEMENT_EXECUTIONS.value.dbValue]) && device.OSVendor === 'Microsoft' "
      class="mb-3"
    >
      <card external-card="material-card mb-3">
        <b-collapse
          slot="external-header"
          :open="false"
          class="card"
        >
          <card
            slot="trigger"
            slot-scope="props"
            internal-card="card-header"
            style-internal-header="display:flex; width:100%"
            style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
          >
            <p
              slot="header"
              class="card-header-title header-title"
            >
              BIOS or UEFI administrator
            </p>
            <a
              slot="body"
              class="card-header-icon"
            >
              <b-icon
                v-show="props.open"
                icon="menu-up"
              />
              <b-icon
                v-show="!props.open"
                icon="menu-down"
              />
            </a>
          </card>
          <card
            internal-card="material-card-content"
          >
            <div
              slot="header"
              style="width:100%"
            >
              <p class="informationText">
                <b-icon
                  icon="checkbox-blank-circle"
                  :type="Object.values(biosPasswordRequirements)
                    .filter(x => x === false).length === 0
                    ? 'is-success' : (Object.values(biosPasswordRequirements)
                      .filter(x => x === false)
                      .length === 4 ? 'is-danger' : 'is-warning')"
                  size="is-small"
                />
                <strong>
                  {{ `Boardgent ${Object.values(biosPasswordRequirements)
                    .some(x => !x)
                    ? 'cannot' : 'can'} manage the BIOS password of this device ` }}
                </strong>
              </p>
              <div class="columns">
                <div class="column">
                  <p class="informationText">
                    <b-icon
                      icon="checkbox-blank-circle"
                      :type="biosPasswordRequirements.haveWmiClasses ? 'is-success' : 'is-danger'"
                      size="is-small"
                    />
                    <span> Have installed the WMI classes to manage BIOS </span>
                  </p>
                </div>
                <div class="column">
                  <p class="informationText">
                    <b-icon
                      icon="checkbox-blank-circle"
                      :type="biosPasswordRequirements.passwordInBios ? 'is-success' : 'is-danger'"
                      size="is-small"
                    />
                    <span> Password set in BIOS </span>
                  </p>
                </div>
              </div>
              <div
                v-if="Object.values(biosPasswordRequirements)
                  .filter(x => x === false).length === 0"
              >
                <hr>
                <b-message
                  v-if="activeProfile.biosPasswordAutoChange"
                  type="is-success"
                  style="margin-bottom: 8px;"
                >
                  <p>
                    <span>
                      Boardgent changes this password automatically every {{ frecuenciesArray
                        .find(
                          (frequency) => frequency.minutes === activeProfile.biosPasswordAge)
                        .name }}.
                      You can change this behavior in settings of the
                      <router-link
                        :to="{ name: 'group', params: { profileId: activeProfile.id }}"
                      >{{ activeProfile.name }}</router-link> device group.
                    </span>
                  </p>
                </b-message>
                <b-message
                  v-else
                  type="is-warning"
                  style="margin-bottom: 8px;"
                >
                  <p class="center-vertically-action-button">
                    <span>
                      There is not enabled an automatic password change.
                      You can enable it in settings of the
                      <router-link
                        :to="{ name: 'group', params: { profileId: activeProfile.id }}"
                      >{{ activeProfile.name }}</router-link> device group.
                    </span>
                  </p>
                </b-message>
                <b-message
                  v-if="device.biosManagementPaused"
                  type="is-danger"
                  style="margin-bottom: 8px;"
                >
                  <p class="center-vertically-action-button">
                    <span>
                      <span>
                        {{ biosManagementPausedError }}
                      </span>
                      <br>
                      <span>
                        BIOS response: {{ device.biosPasswordValidation }}
                      </span>
                    </span>
                    <button
                      class="button is-primary is-pulled-right"
                      @click="resumeBiosManagement"
                    >
                      Confirm
                    </button>
                  </p>
                </b-message>
                <b-message
                  v-if="biosErrors.length > 0"
                  type="is-danger"
                >
                  <p
                    v-for="error in biosErrors"
                    :key="error"
                  >
                    {{ error }}
                  </p>
                </b-message>
                <b-message
                  v-if="biosWarnings.length > 0"
                  type="is-warning"
                >
                  <p
                    v-for="warning in biosWarnings"
                    :key="warning"
                  >
                    {{ warning }}
                  </p>
                </b-message>
                <div class="columns">
                  <div class="column">
                    <div>
                      <p
                        v-if="isDeviceOnline && restartToApply
                          && biosPasswordLastSuccessResultTimeStamp && device.backupBiosPassword"
                        class="centerVertically informationText"
                      >
                        <strong>Password before restart: </strong>
                        <span
                          v-if="backupBiosPassword"
                          class="password-style material-card-content is-size-7-mobile is-rounded"
                        >
                          <span
                            v-for="(letter, index) in backupBiosPassword.split('')"
                            :key="index"
                            :class="getClass(letter)"
                          >
                            {{ letter }}
                          </span>
                        </span>
                        <span v-else>
                          **********
                        </span>
                        <section
                          v-if="validateIfHasPermission(
                            [permissions.SEE_BIOS_PASSWORDS.value.dbValue]
                          )"
                        >
                          <b-button
                            v-if="!backupBiosPassword"
                            icon-left="eye"
                            :class="{'is-loading': isGettingBackupBiosPassword }"
                            class="button is-small is-primary"
                            @click="validateIfNeedReason('BIOS password before restart',
                                                         getBackupBiosPassword)"
                          >
                            Reveal
                          </b-button>
                          <b-button
                            v-else
                            class="button is-small is-primary"
                            icon-left="eye-off"
                            @click="getBackupBiosPassword"
                          >
                            Conceal
                          </b-button>
                          <b-button
                            v-if="backupBiosPassword"
                            v-clipboard:copy="backupBiosPassword"
                            icon-left="clipboard-outline"
                            class="button is-small is-primary is-outlined"
                            @click="showToastCopy"
                          >
                            Copy
                          </b-button>
                        </section>
                      </p>
                      <p
                        v-if="biosPasswordLastSuccessResultTimeStamp"
                        class="centerVertically informationText"
                      >
                        <strong>{{ isDeviceOnline && restartToApply ? 'Password after restart'
                          : 'Current password' }} : </strong>
                        <span
                          v-if="biosPassword"
                          class="password-style material-card-content is-size-7-mobile is-rounded"
                        >
                          <span
                            v-for="(letter, index) in biosPassword.split('')"
                            :key="index"
                            :class="getClass(letter)"
                          >
                            {{ letter }}
                          </span>
                        </span>
                        <span v-else>
                          **********
                        </span>
                        <section
                          v-if="validateIfHasPermission(
                            [permissions.SEE_BIOS_PASSWORDS.value.dbValue]
                          )"
                        >
                          <b-button
                            v-if="!biosPassword"
                            :class="{'is-loading': isGettingBiosPassword }"
                            class="button is-small is-primary ml-1"
                            icon-left="eye"
                            @click="validateIfNeedReason('BIOS Password', getBiosPassword)"
                          >
                            Reveal
                          </b-button>
                          <b-button
                            v-else
                            class="button is-small is-primary"
                            icon-left="eye-off"
                            @click="getBiosPassword"
                          >
                            Conceal
                          </b-button>
                          <button
                            v-if="biosPassword"
                            v-clipboard:copy="biosPassword"
                            class="button is-small is-primary is-outlined ml-1"
                            icon-left="clipboard-outline"
                            @click="showToastCopy"
                          >
                            Copy
                          </button>
                        </section>
                      </p>
                      <p
                        v-if="!biosPasswordLastSuccessResultTimeStamp"
                        class="centerVertically informationText"
                      >
                        <strong>Current password: </strong>
                        <span>
                          Unknown
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="column">
                    <div>
                      <p class="informationText">
                        <strong>Last change: </strong>
                        <span v-if="biosPasswordLastSuccessResultTimeStamp">
                          <timeago
                            :since="biosPasswordLastSuccessResultTimeStamp"
                            no-future
                          />
                        </span>
                        <span v-else>
                          Never
                        </span>
                      </p>
                      <p
                        v-if="device.biosPasswordChangeLastResult
                          && device.biosPasswordChangeLastResult !== 'Success'"
                        class="informationText"
                      >
                        <strong>Last change attempt: </strong>
                        <span v-if="biosPasswordLastResultTimeStamp">
                          <timeago
                            :since="biosPasswordLastResultTimeStamp"
                            no-future
                          />
                        </span>
                        <span v-else>
                          Never
                        </span>
                      </p>
                      <p
                        v-if="device.biosPasswordChangeLastResult
                          && device.biosPasswordChangeLastResult !== 'Success'"
                      >
                        <strong>Last change attempt result: </strong>
                        {{ device.biosPasswordChangeLastResult }}
                      </p>
                      <p
                        v-if="device"
                        class="centerVertically informationText"
                      >
                        <strong>Next automatic change: </strong>
                        <span v-if="device.biosManagementPaused">
                          Paused until password is confirmed
                        </span>
                        <span
                          v-else-if="biosExpirationDate"
                        >
                          <span
                            v-if="(isDeviceOnline && !restartRequired)
                              || biosExpirationDate.isAfter()"
                          >
                            <timeago
                              :since="biosExpirationDate.toISOString()"
                              no-past
                            />
                          </span>
                          <span
                            v-else-if="isDeviceOnline && restartRequired
                              && biosExpirationDate.isBefore()"
                          >
                            In the next reboot
                          </span>
                          <span v-else>
                            When the device goes online
                          </span>
                        </span>
                        <span v-else>Not set</span>
                      </p>
                    </div>
                  </div>
                </div>
                <hr>
                <div style="display: flex; flex-wrap: wrap; gap: 8px; justify-content:center ">
                  <b-button
                    v-if="validateIfHasPermission(
                      [permissions.SEE_BIOS_PASSWORDS.value.dbValue]
                    )"
                    :class="{'is-loading': isGettingBiosPasswordHistory }"
                    class="button is-primary"
                    style="margin-right:10px"
                    icon-left="eye"
                    @click="validateIfNeedReason('BIOS Password History',
                                                 getBiosPasswordHistory)"
                  >
                    <span class="is-size-7-mobile">Reveal History</span>
                  </b-button>
                  <button
                    v-if="validateIfHasPermission(
                      [permissions.LOCAL_PASSWORD_EXECUTIONS.value.dbValue]
                    )"
                    class="button is-primary"
                    @click="expirateBiosPassword"
                  >
                    <span class="is-size-7-mobile">Change password now</span>
                  </button>
                </div>
              </div>
            </div>
          </card>
        </b-collapse>
      </card>
    </div>
    <div
      v-if="validateIfHasPermission([permissions.SEE_AMT_PASSWORDS.value.dbValue,
                                     permissions.AMT_MANAGEMENT_EXECUTIONS.value.dbValue])
        && validateIfHasCapability([planCapabilities.AMT_MANAGEMENT.value])
        && amtInfo && amtInfo.amtPassword
        && amtInfo.amtStatus !== amtStatus.UNCONFIGURED.value.dbValue"
      class="mb-3"
    >
      <card external-card="material-card">
        <b-collapse
          slot="external-header"
          :open="false"
          class="card"
        >
          <card
            slot="trigger"
            slot-scope="props"
            internal-card="card-header"
            style-internal-header="display:flex; width:100%"
            style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
          >
            <p
              slot="header"
              class="card-header-title header-title"
            >
              Intel® vPro AMT administrator
            </p>
            <a
              slot="body"
              class="card-header-icon"
            >
              <b-icon
                v-show="props.open"
                icon="menu-up"
              />
              <b-icon
                v-show="!props.open"
                icon="menu-down"
              />
            </a>
          </card>
          <card internal-card="material-card-content">
            <div
              slot="body"
            >
              <p><strong>Username: </strong>admin</p>
              <p
                class="centerVertically informationText"
              >
                <strong>Current password: </strong>
                <span
                  v-if="amtPassword"
                  class="password-style material-card-content is-size-7-mobile is-rounded"
                >
                  <span
                    v-for="(letter, index) in amtPassword.split('')"
                    :key="index"
                    :class="getClass(letter)"
                  >
                    {{ letter }}
                  </span>
                </span>
                <span v-else>
                  **********
                </span>
                <section
                  v-if="validateIfHasPermission(
                    [permissions.SEE_AMT_PASSWORDS.value.dbValue]
                  )"
                >
                  <b-button
                    v-if="!amtPassword"
                    :class="{'is-loading': isGettingAmtPassword }"
                    class="button is-small is-primary ml-1"
                    icon-left="eye"
                    @click="validateIfNeedReason('AMT Password', getAmtPassword)"
                  >
                    Reveal
                  </b-button>
                  <b-button
                    v-else
                    class="button is-small is-primary"
                    icon-left="eye-off"
                    @click="getAmtPassword"
                  >
                    Conceal
                  </b-button>
                  <b-button
                    v-if="amtPassword"
                    v-clipboard:copy="amtPassword"
                    class="button is-small is-primary is-outlined ml-1"
                    icon-left="clipboard-outline"
                    @click="showToastCopy"
                  >
                    Copy
                  </b-button>
                </section>
              </p>
            </div>
          </card>
        </b-collapse>
      </card>
    </div>
    <b-modal
      slot="footer"
      :parent="this"
      :active.sync="isLocalUserPasswordHistoryVisible"
      has-modal-card
    >
      <devicePasswordHistory
        title="Local user"
        service="local-user-password"
      />
    </b-modal>
    <b-modal
      slot="footer"
      :parent="this"
      :active.sync="isBiosPasswordHistoryVisible"
      has-modal-card
    >
      <devicePasswordHistory
        title="Bios"
        service="bios-password"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ExecutionsMixin from '@/mixins/executions';
import ValidationsMixin from '@/mixins/validations';
import CompanyMixin from '@/mixins/company';
import RoleMixin from '@/mixins/roles';
import toastMessage from '@/helpers/toastMessage';
import snackBarMessage from '@/helpers/snackBarMessage';
import parseEnum from '@/helpers/parseEnums';
import card from '@/components/cross/Card.vue';
import devicePasswordHistory from '@/components/device/DevicePasswordHistory.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import vtulEnums from '../../../../cross/index';

const { frequencies } = vtulEnums.enum.settings;
const { permissions } = vtulEnums.enum.roles;
const { planCapabilities } = vtulEnums.enum.planCapabilities;
const { executeAction } = vtulEnums.enum.execution;
const { deviceStatus } = vtulEnums.enum.device;
const { amtStatus } = vtulEnums.enum.amt;
const { programmerAgentErrors } = vtulEnums.enum.agent;

export default {
  name: 'DevicePasswordManager',
  components: {
    card,
    devicePasswordHistory,
  },
  mixins: [ExecutionsMixin, ValidationsMixin, RoleMixin, CompanyMixin],
  props: {
    device: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    amtInfo: {
      type: Object,
      default() {
        return { };
      },
    },
    activeProfile: {
      type: Object,
      default() {
        return { };
      },
    },
  },
  data() {
    return {
      biosPassword: '',
      backupBiosPassword: '',
      amtPassword: '',
      isGettingBiosPassword: false,
      isGettingBackupBiosPassword: false,
      isGettingAmtPassword: false,
      isLocalUserPasswordHistoryVisible: false,
      isBiosPasswordHistoryVisible: false,
      isGettingBiosPasswordHistory: false,
      isGettingLocalUserPasswordHistory: false,
      localAccountPassword: '',
      isGettinglocalAccountPassword: false,
      expirationType: null,
      permissions,
      planCapabilities,
      frecuenciesArray: parseEnum.enumToArray(frequencies),
      frequenciesEnum: frequencies,
      deviceStatus,
      amtStatus,
      programmerAgentErrors,
    };
  },
  computed: {
    ...mapGetters('local-user', { findLocalUsersInStore: 'find' }),
    localAccount() {
      const users = this.findLocalUsersInStore({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
        },
      });
      return users.data.length > 0 ? users.data[0] : null;
    },
    localPasswordExpirationDateWhenSuccessChange() {
      if (!this.activeProfile || !this.activeProfile.localAccountPasswordAutoChange
        || !this.passwordChangeLastSuccessResultTimeStamp) {
        return '';
      }
      if (moment(this.passwordChangeLastSuccessResultTimeStamp)
        .isBefore(moment(this.passwordChangeLastResultTimeStamp))) {
        return moment(this.passwordChangeLastResultTimeStamp).add(1, 'hour');
      }
      return moment(this.passwordChangeLastSuccessResultTimeStamp)
        .add(this.activeProfile.localAccountPasswordAge, 'minutes');
    },
    localPasswordExpirationDateWhenReveal() {
      if (!this.localAccount || !this.activeProfile
      || !this.activeProfile.localAccountPasswordChangeOnReveal
      || !this.activeProfile.localAccountPasswordChangeOnReveal.enabled
      || !this.localAccount.lastTimePasswordSeen
      || !this.passwordChangeLastSuccessResultTimeStamp
      || moment(this.passwordChangeLastSuccessResultTimeStamp)
        .isAfter(moment(this.localAccount.lastTimePasswordSeen))) {
        return '';
      }
      return moment(this.localAccount.lastTimePasswordSeen)
        .add(this.activeProfile.localAccountPasswordChangeOnReveal.frequency, 'minutes');
    },
    localPasswordExpirationDate() {
      if (!this.activeProfile || !this.localAccount) return '';

      if (this.localPasswordExpirationDateWhenSuccessChange
      && this.localPasswordExpirationDateWhenReveal) {
        if (this.localPasswordExpirationDateWhenSuccessChange
          .isAfter(this.localPasswordExpirationDateWhenReveal)) {
          return this.localPasswordExpirationDateWhenReveal;
        }
        return this.localPasswordExpirationDateWhenSuccessChange;
      }

      if (this.localPasswordExpirationDateWhenSuccessChange) {
        return this.localPasswordExpirationDateWhenSuccessChange;
      }

      if (this.localPasswordExpirationDateWhenReveal) {
        return this.localPasswordExpirationDateWhenReveal;
      }
      return '';
    },
    isDeviceOnline() {
      return this.device.status === deviceStatus.ONLINE.value.dbValue;
    },
    biosExpirationDateWhenSuccessChange() {
      if (!this.activeProfile || !this.activeProfile.biosPasswordAutoChange
        || !this.biosPasswordLastSuccessResultTimeStamp) {
        return '';
      }
      return moment(this.biosPasswordLastSuccessResultTimeStamp)
        .add(this.activeProfile.biosPasswordAge, 'minutes');
    },
    biosExpirationDateWhenReveal() {
      if (!this.device || !this.activeProfile
      || !this.activeProfile.biosPasswordChangeOnReveal
      || !this.activeProfile.biosPasswordChangeOnReveal.enabled
      || !this.device.lastTimeBiosPasswordSeen
      || !this.biosPasswordLastSuccessResultTimeStamp
      || moment(this.biosPasswordLastSuccessResultTimeStamp)
        .isAfter(moment(this.device.lastTimeBiosPasswordSeen))) {
        return '';
      }
      return moment(this.device.lastTimeBiosPasswordSeen)
        .add(this.activeProfile.biosPasswordChangeOnReveal.frequency, 'minutes');
    },
    biosExpirationDate() {
      if (!this.activeProfile || !this.device) return '';

      if (this.biosExpirationDateWhenSuccessChange && this.biosExpirationDateWhenReveal) {
        if (this.biosExpirationDateWhenSuccessChange
          .isAfter(this.biosExpirationDateWhenReveal)) {
          return this.biosExpirationDateWhenReveal;
        }
        return this.biosExpirationDateWhenSuccessChange;
      }

      if (this.biosExpirationDateWhenSuccessChange) {
        return this.biosExpirationDateWhenSuccessChange;
      }

      if (this.biosExpirationDateWhenReveal) {
        return this.biosExpirationDateWhenReveal;
      }

      return '';
    },

    hasBiosPassword() {
      if (this.device.isBiosPasswordSetError) {
        return `Unknown: ${this.device.isBiosPasswordOutput}`;
      }
      if (this.device.isBiosPasswordSet === null) {
        return 'Unknown';
      }
      if (this.device.isBiosPasswordSet) {
        return 'true';
      }
      return 'false';
    },
    biosPasswordLastSuccessResultTimeStamp() {
      if (this.device.biosPasswordLastSuccessResultTimeStamp
          && moment(this.device.biosPasswordLastSuccessResultTimeStamp).isAfter('2000-01-01')) {
        return this.device.biosPasswordLastSuccessResultTimeStamp;
      }
      return null;
    },
    biosPasswordLastResultTimeStamp() {
      if (this.device.biosPasswordLastResultTimeStamp
          && moment(this.device.biosPasswordLastResultTimeStamp).isAfter('2000-01-01')) {
        return this.device.biosPasswordLastResultTimeStamp;
      }
      return null;
    },
    passwordChangeLastSuccessResultTimeStamp() {
      if (this.localAccount && this.localAccount.passwordChangeLastSuccessResultTimeStamp
          && moment(this.localAccount.passwordChangeLastSuccessResultTimeStamp).isAfter('2000-01-01')) {
        return this.localAccount.passwordChangeLastSuccessResultTimeStamp;
      }
      return null;
    },
    passwordChangeLastResultTimeStamp() {
      if (this.localAccount && this.localAccount.passwordChangeLastResultTimeStamp
          && moment(this.localAccount.passwordChangeLastResultTimeStamp).isAfter('2000-01-01')) {
        return this.localAccount.passwordChangeLastResultTimeStamp;
      }
      return null;
    },
    biosManagementPausedError() {
      let error = 'Management paused because the password configured in Boardgent does not match the password configured in the BIOS';
      const invalidPwdTimestamp = moment(this.device.InvalidBiosPasswordTimeStamp);
      if (invalidPwdTimestamp && this.device.biosPasswordChangeLastResult
                            && this.device.biosPasswordChangeLastResult.toLowerCase() === 'success') {
        error += ` since ${invalidPwdTimestamp.format('LLL')}`;
      }
      error += ', please confirm it.';
      return error;
    },
    localPasswordManagementPausedError() {
      let error = 'Management paused because the password configured in Boardgent does not match account password';
      const invalidPwdTimestamp = moment(this.localAccount.invalidLocalPasswordTimeStamp);
      if (invalidPwdTimestamp) {
        error += ` since ${invalidPwdTimestamp.format('LLL')}`;
      }
      error += ', please confirm it.';
      return error;
    },
    restartToApply() {
      const lastSuccessResult = moment(this.device.biosPasswordLastSuccessResultTimeStamp);
      const upTime = moment(this.device.uptime);
      return upTime.isBefore(lastSuccessResult);
    },
    restartToRetry() {
      const lastFailedvalidation = moment(this.device.biosPasswordLastFailedValidation);
      const upTime = moment(this.device.uptime);
      return upTime.isBefore(lastFailedvalidation);
    },
    biosErrors() {
      const errors = [];
      if (this.isDeviceOnline && !this.device.biosManagementPaused && this.restartToRetry) {
        errors.push(`Boardgent validated the password ${moment(this.device.biosPasswordLastFailedValidation).fromNow()}, but it failed. The BIOS requires a restart before trying again.`);
      }
      return errors;
    },
    biosWarnings() {
      const warnings = [];
      if (this.isDeviceOnline && this.restartToApply) {
        warnings.push(`Boardgent changed the password ${moment(this.device.biosPasswordLastSuccessResultTimeStamp).fromNow()}, but the BIOS requires a restart to finish the process.`);
      }
      return warnings;
    },
    restartRequired() {
      return this.device.biosPasswordRestartRequired || this.restartToApply || this.restartToRetry;
    },
    biosPasswordRequirements() {
      const requirements = {
        passwordInBios: false,
        haveWmiClasses: false,
      };
      requirements.passwordInBios = this.device.isBiosPasswordSet;
      requirements.haveWmiClasses = this.device.haveWmiBiosClasses;
      return requirements;
    },
  },
  watch: {
    localAccount: {
      handler(account) {
        if (!this.localAccountPassword) {
          return;
        }
        if (account && account.password !== this.localAccountPassword) {
          // Hide password when it changes
          this.localAccountPassword = '';
        }
      },
      deep: true,
    },
    device: {
      handler(newDevice, oldDevice) {
        if (newDevice && oldDevice && newDevice.id !== oldDevice.id) {
          this.biosPassword = '';
          this.amtPassword = '';
          this.localAccountPassword = '';
          this.fetchApi();
        }
        if (!this.biosPassword) {
          return;
        }
        if (newDevice && newDevice.biosPassword !== this.biosPassword) {
          // Hide password when it changes
          this.biosPassword = '';
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchApi();
  },
  methods: {
    ...mapActions('local-user', { findLocalUsers: 'find', updateLocalUser: 'patch' }),
    ...mapActions('devices', { patchDeviceInfo: 'patch' }),
    getReason(elementName, cb) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: (reason) => cb(reason.text),
          title: `Show ${elementName} of ${this.device.name}`,
          confirmText: 'Reveal',
          confirmIcon: 'eye',
          message: 'Please type the reason why you need this password',
          getInformationFromTextArea: true,
          informationIsRequired: true,
        },
      });
    },
    async getLocalAccountPassword(reason) {
      if (this.localAccountPassword) {
        this.localAccountPassword = '';
        return;
      }
      this.isGettinglocalAccountPassword = true;
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.GET_LOCAL_ACCOUNT_PASSWORD.value.id, { reason });
        this.isGettinglocalAccountPassword = false;
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting OS Local administrator password.');
          return;
        }

        if (execution.results[0].exitCode === '0') {
          this.localAccountPassword = this.localAccount ? this.localAccount.password : null;
          await this.updateLocalUser([this.localAccount.id, {
            lastTimePasswordSeen: Date.now(),
          }, {
            query: {
              companyId: this.currentCompany.id,
            },
          }]);
          if (this.activeProfile.localAccountPasswordChangeOnReveal.enabled) {
            toastMessage.showSuccess(`Boardgent will change the password automatically in
            ${this.getRightFrequencyArticle(this.activeProfile.localAccountPasswordChangeOnReveal.frequency)}
            ${this.frecuenciesArray.find((frequency) => frequency.minutes
              === this.activeProfile.localAccountPasswordChangeOnReveal.frequency).name}`);
          }
          return;
        }
        toastMessage.showError(execution.results[0].executionResult || 'You do not have access to get this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        this.isGettinglocalAccountPassword = false;
        throw err;
      }
    },
    async getBiosPassword(reason) {
      if (this.biosPassword) {
        this.biosPassword = '';
        return;
      }
      this.isGettingBiosPassword = true;
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.GET_BIOS_PASSWORD.value.id, { reason });
        this.isGettingBiosPassword = false;
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting BIOS password.');
          return;
        }

        if (execution.results[0].exitCode === '0') {
          this.biosPassword = this.device.biosPassword;
          await this.patchDeviceInfo([this.device.id, {
            lastTimeBiosPasswordSeen: Date.now(),
          }, {
            query: {
              companyId: this.currentCompany.id,
            },
          }]);
          if (this.activeProfile.biosPasswordChangeOnReveal.enabled) {
            toastMessage.showSuccess(`Boardgent will change the password automatically in
            ${this.getRightFrequencyArticle(this.activeProfile.biosPasswordChangeOnReveal.frequency)}
            ${this.frecuenciesArray.find((frequency) => frequency.minutes
              === this.activeProfile.biosPasswordChangeOnReveal.frequency).name}`);
          }
          return;
        }
        toastMessage.showError(execution.results[0].executionResult || 'You do not have access to get this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        this.isGettingBiosPassword = false;
        throw err;
      }
    },
    async getBackupBiosPassword(reason) {
      if (this.backupBiosPassword) {
        this.backupBiosPassword = '';
        return;
      }
      this.isGettingBackupBiosPassword = true;
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.GET_BACKUP_BIOS_PASSWORD.value.id, { reason });
        this.isGettingBackupBiosPassword = false;
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting BIOS password before restart.');
          return;
        }

        if (execution.results[0].exitCode === '0') {
          this.backupBiosPassword = this.device.backupBiosPassword;
          return;
        }
        toastMessage.showError(execution.results[0].executionResult || 'You do not have access to get this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        this.isGettingBackupBiosPassword = false;
        throw err;
      }
    },
    async getAmtPassword(reason) {
      if (this.amtPassword) {
        this.amtPassword = '';
        return;
      }
      this.isGettingAmtPassword = true;
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.GET_AMT_PASSWORD.value.id, { reason });
        this.isGettingAmtPassword = false;
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting AMT password.');
          return;
        }

        if (execution.results[0].exitCode === '0') {
          this.amtPassword = this.amtInfo.amtPassword;
          return;
        }
        toastMessage.showError(execution.results[0].executionResult || 'You do not have access to get this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        this.isGettingAmtPassword = false;
        throw err;
      }
    },
    async getBiosPasswordHistory(reason) {
      this.isGettingBiosPasswordHistory = true;
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.GET_BIOS_PASSWORD_HISTORY.value.id, { reason });
        this.isGettingBiosPasswordHistory = false;
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting BIOS password history.');
          return;
        }

        if (execution.results[0].exitCode === '0') {
          this.isBiosPasswordHistoryVisible = true;
          return;
        }
        toastMessage.showError(execution.results[0].executionResult || 'You do not have access to get this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        this.isGettingBiosPasswordHistory = false;
        throw err;
      }
    },
    async getLocalUserPasswordHistory(reason) {
      this.isGettingLocalUserPasswordHistory = true;
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.GET_LOCAL_USER_PASSWORD_HISTORY.value.id, { reason });
        this.isGettingLocalUserPasswordHistory = false;
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting user password history.');
          return;
        }

        if (execution.results[0].exitCode === '0') {
          this.isLocalUserPasswordHistoryVisible = true;
          return;
        }
        toastMessage.showError(execution.results[0].executionResult || 'You do not have access to get this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        this.isGettingLocalUserPasswordHistory = false;
        throw err;
      }
    },
    async expirateBiosPassword() {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeAction.FORCE_CHANGE_BIOS_PASSWORD.value.id);
      const router = this.$router;
      snackBarMessage.showSuccess('Changing BIOS Password.', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async expiratelocalAccountPassword() {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeAction.FORCE_CHANGE_LOCAL_ACCOUNT_PASSWORD.value.id);
      const router = this.$router;
      snackBarMessage.showSuccess('Changing OS Local administrator password.', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    getClass(letter) {
      if ('0123456789'.includes(letter)) {
        return 'number-char';
      }
      if ('~!@#$%^&*_-+=`|(){}[]:;"\'<>,.?/'.includes(letter)) {
        return 'special-char';
      }
      return 'text-char';
    },
    fetchApi() {
      this.findLocalUsers({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
        },
      });
    },
    async enableBiosManagement(biosPasswordParameters) {
      try {
        const resumeBiosManagement = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.RESUME_BIOS_MANAGEMENT.value.id);
        if (!resumeBiosManagement || resumeBiosManagement.results.length === 0) {
          toastMessage.showError('Error confirming BIOS password.');
          return;
        }
        if (resumeBiosManagement.results[0].exitCode === '0') {
          this.patchDeviceInfo([this.device.id, {
            biosManagementPaused: false,
            biosPassword: biosPasswordParameters.text,
          }, {
            query: {
              companyId: this.currentCompany.id,
            },
          }]);
          if (biosPasswordParameters.checkSelected) {
            const restartDevice = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
              [this.device.id], executeAction.RESTART.value.id, { onlyOnlineDevices: true });
            if (!restartDevice || restartDevice.results.length === 0) {
              toastMessage.showError('Error restarting device.');
              return;
            }
            toastMessage.showSuccess('We are restarting the device now.');
          } else if (!this.restartRequired && this.isDeviceOnline) {
            toastMessage.showSuccess('Validating the provided password.');
            await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
              [this.device.id], executeAction.FORCE_CHANGE_BIOS_PASSWORD.value.id,
              { onlyOnlineDevices: true });
          }
          return;
        }
        toastMessage.showError('You do not have access to change this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        throw err;
      }
    },
    async resumeBiosManagement() {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: (reason) => this.enableBiosManagement(reason),
          title: 'Confirm current BIOS password',
          message: `Please confirm the password configured in the BIOS ${this.restartRequired ? 'and restart the device' : ''}.</br></br>Current BIOS Password`,
          confirmText: 'Confirm',
          field: true,
          informationIsRequired: true,
          showCheck: this.restartRequired && this.isDeviceOnline,
          checkText: 'Restart device now',
          passwordField: true,
        },
      });
    },
    async enableLocalAccountManagement(password) {
      try {
        const resumeLocalAccountManagement = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeAction.RESUME_LOCAL_ACCOUNT_MANAGEMENT.value.id);
        if (!resumeLocalAccountManagement || resumeLocalAccountManagement.results.length === 0) {
          toastMessage.showError('Error confirming local account password.');
          return;
        }
        if (resumeLocalAccountManagement.results[0].exitCode === '0') {
          await this.updateLocalUser([this.localAccount.id, {
            isManagementPaused: false,
            password: password.text,
          }, {
            query: {
              companyId: this.currentCompany.id,
            },
          }]);
          if (this.isDeviceOnline) {
            toastMessage.showSuccess('Validating the provided password.');
            await this.createExecutions(
              this.currentCompany.id,
              this.$store.getters['auth/user'].id,
              [this.device.id],
              executeAction.FORCE_CHANGE_LOCAL_ACCOUNT_PASSWORD.value.id,
              {
                resetDefaultPassword: true,
                resetLoginKeyChain: false,
              },
            );
          }
          return;
        }
        toastMessage.showError('You do not have access to change this information.');
      } catch (err) {
        toastMessage.showError(err.toString());
        throw err;
      }
    },
    async resumeLocalPasswordManagement() {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: (reason) => this.enableLocalAccountManagement(reason),
          title: 'Confirm current local password',
          message: `Please confirm the password configured in ${this.localAccount.username}'s account.</br></br>Current password`,
          confirmText: 'Confirm',
          field: true,
          informationIsRequired: true,
          passwordField: true,
        },
      });
    },
    showToastCopy() {
      toastMessage.showSuccess('Password copied to clipboard');
    },
    getRightFrequencyArticle(frequency) {
      let text = '';
      if (frequency === this.frequenciesEnum.ONE_WEEK.value.minutes
      || frequency === this.frequenciesEnum.ONE_MONTH.value.minutes
      || frequency === this.frequenciesEnum.ONE_YEAR.value.minutes
      || frequency === this.frequenciesEnum.TWENTY_FOUR_HOURS.value.minutes) {
        text = 'a';
      } else if (frequency === this.frequenciesEnum.ONE_HOUR.value.minutes) {
        text = 'an';
      }
      return text;
    },
    validateIfNeedReason(elementName, cb) {
      if (this.currentCompany.needReasonToShowPassword) {
        this.getReason(elementName, cb);
      } else {
        cb();
      }
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
.centerVertically > strong {
    margin-right: 4px;
}
.centerVertically > button {
    margin-left: 4px;
}
.informationText {
  margin: 4px 0px;
}
.number-char {
  color: #0572EC;
  display:inline-block;
  width: 8px;
}
.special-char {
  color: #E60000;
  display:inline-block;
  width: 8px;
}
.text-char {
  display:inline-block;
  width: 8px;
}
.center-vertically-action-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
