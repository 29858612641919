<template>
  <section>
    <div
      style="text-align:center"
      class="columns is-multiline"
    >
      <div class="column">
        <h1 class="plan-title-text">
          Unlock Intel® AMT KVM
        </h1>
        <div class="plan-content">
          <div class="plan-section-div">
            <p
              class="plan-text"
            >
              To use Intel® AMT KVM upgrade to one of our paid plans.
            </p>
          </div>
          <router-link
            class="button is-primary purchase-plan-button"
            :to="{ name: 'upgradeplan'}"
          >
            Upgrade Now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    currentCompany: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
<style scoped>
.plan-title-section {
  padding: 10px;
}
.plan-title-text {
  font-size: 27px;
  font-weight: 500;
}
.plan-content {
  padding: 10px;
}
.plan-section-div {
  height: 42px;
}
.plan-text {
  font-size: 20px;
}
</style>
