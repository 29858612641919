<template>
  <card
    external-card="material-card"
  >
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            {{ title }}
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>
      <card
        internal-card="card-content"
      >
        <div
          slot="body"
          style="display: flex; flex-wrap: wrap; gap: 8px;"
        >
          <b-button
            v-for="action in actions"
            :key="action.id"
            type="is-dark"
            :icon-left="action.icon"
            @click="openExecutionModal(action)"
          >
            {{ action.name }}
          </b-button>
          <execution-modal
            v-if="actionToExecute"
            v-model="isExecuteModalActive"
            :component="executeActionComponent"
            :select-action="actionToExecute"
            :default-parameters="actionDefaultParameters"
            :selected-devices="[device.id]"
          />
        </div>
      </card>
    </b-collapse>
  </card>
</template>

<script>
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import ExecutionModal from '@/components/cross/ModalExecuteAction.vue';
import powerExecutionParams from '@/components/executions/powerExecutionParams.vue';
import deleteFilesFromFolder from '@/components/executions/deleteFilesFromFolder.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';

import snackBarMessage from '@/helpers/snackBarMessage';

import ExecutionsMixin from '@/mixins/executions';
import CompanyMixin from '@/mixins/company';

import vtulEnums from '../../../../cross';

const { executeAction: executeActionEnum } = vtulEnums.enum.execution;

export default {
  name: 'DevicePowerOptions',
  components: {
    card,
    learnMore,
    'execution-modal': ExecutionModal,
  },
  mixins: [ExecutionsMixin, CompanyMixin],
  props: {
    device: {
      type: Object,
      default() {
        return { };
      },
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      executeActionEnum,
      powerActions: [
        {
          action: executeActionEnum.SHUTDOWN.value,
          name: 'Shutdown',
          message: 'Shuting down the device...',
          icon: 'power-off',
          component: powerExecutionParams,
          defaultParameters: {
            onlyOnlineDevices: true,
          },
        },
        {
          action: executeActionEnum.RESTART.value,
          name: 'Restart',
          message: 'Restarting the device...',
          icon: 'power-cycle',
          component: powerExecutionParams,
          defaultParameters: {
            onlyOnlineDevices: true,
          },
        },
        {
          action: executeActionEnum.HIBERNATE.value,
          name: 'Hibernate',
          message: 'Putting to hibernate the device...',
          icon: 'power',
          defaultParameters: {
            onlyOnlineDevices: true,
          },
        },
        {
          action: executeActionEnum.SLEEP.value,
          name: 'Sleep',
          message: 'Putting to sleep the device...',
          icon: 'power-sleep',
          defaultParameters: {
            onlyOnlineDevices: true,
          },
        },
      ],
      optimizationActions: [
        {
          action: executeActionEnum.CLEAN_FOLDERS.value,
          name: 'Clean folders',
          message: 'Cleaning folder...',
          icon: 'folder-remove',
          component: deleteFilesFromFolder,
        },
      ],
      isExecuteModalActive: false,
      executeActionComponent: null,
      actionToExecute: null,
      actionDefaultParameters: null,
    };
  },
  computed: {
    actions() {
      if (this.type === 'power') {
        return this.powerActions;
      }
      if (this.type === 'script') {
        return this.scriptActions;
      }
      if (this.type === 'optimizations') {
        return this.optimizationActions;
      }

      return [];
    },
    title() {
      if (this.type === 'optimizations') {
        return 'Cleaning';
      }
      if (this.type === 'power') {
        return 'Power';
      }
      if (this.type === 'scripts') {
        return 'Run scripts';
      }
      return '';
    },
  },
  methods: {
    async openExecutionModal(execution) {
      if (execution.component) {
        this.actionToExecute = execution.action;
        this.executeActionComponent = execution.component;
        this.actionDefaultParameters = execution.defaultParameters;
        this.isExecuteModalActive = true;
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: confirmation,
          hasModalCard: true,
          props: {
            onConfirm: async () => {
              const createdExecution = await this.createExecutions(this.currentCompany.id,
                this.$store.getters['auth/user'].id, [this.device.id], execution.action.id,
                execution.defaultParameters);
              const router = this.$router;
              snackBarMessage.showSuccess(execution.message, {
                text: 'See execution',
                run: () => {
                  router.push(`/${this.currentCompany.id}/execution/${createdExecution.executionGroupId}/1`);
                },
              });
            },
            title: `Execute ${execution.action.name}`,
            confirmText: 'Run',
            message: `Are you sure you want to execute ${execution.action.name}?`,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
