<template>
  <card
    external-card="material-card"
  >
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex; flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            Intel® vPro AMT
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>
      <card
        v-if="hasCapacity"
        internal-card="material-card-content"
      >
        <div slot="header">
          <div
            v-if="!device.amtVersion || device.amtVersion === '0'"
          >
            Not supported
          </div>
          <div
            v-else
          >
            <p><strong>Version: </strong> {{ device.amtVersion }}</p>
            <p v-if="device.amtSku">
              <strong>SKU: </strong>
              {{ device.amtSku }}
            </p>
            <p v-if="device.amtCapabilities">
              <strong>Capabilities: </strong>
              {{ device.amtCapabilities }}
            </p>
            <p>
              <strong>Provisioning Mode: </strong>
              <span v-if="amtInfo">
                {{ amtInfo.amtMode === amtMode.ENTERPRISE_MODE.value
                  ? 'Enterprise Control Mode' :
                    amtInfo.amtMode === amtMode.CLIENT_MODE.value
                      ? 'Client Control Mode (CCM)' :
                        amtInfo.amtMode === amtMode.ADMIN_MODE.value
                          ? 'Admin Control Mode (ACM)' : 'Not Provisioned' }}
              </span>
              <span v-else>
                Not Provisioned
              </span>
            </p>
          </div>
        </div>
        <div
          v-if="amtInfo && amtInfo.amtStatus !== amtStatus.UNCONFIGURED.value.dbValue"
          slot="body"
        >
          <div>
            <p style="display: inline-block;">
              <strong>KVM Redirection: </strong>
              <span>
                {{ amtInfo.redirectionPortEnabled ? 'Enabled' : 'Disabled' }}
              </span>
            </p>
          </div>
          <div>
            <p style="display: inline-block;">
              <strong>User Consent: </strong>
              <span>
                {{ amtInfo.optInState === optInState.NECESSARY.value.dbValue
                  ? 'Required for All Operations' :
                    amtInfo.optInState === optInState.ONLY_KVM.value.dbValue
                      ? 'Required for All Operations' :
                        amtInfo.optInState === optInState.UNNECESSARY.value.dbValue
                          ? 'Not Required' : 'Unknown' }}
              </span>
            </p>
          </div>
          <div>
            <p style="display: inline-block;">
              <strong>EHBC: </strong>
              <span>
                {{ amtInfo.amtEHBC === amtEHBC.ENABLED.value ? 'Enabled' :
                  amtInfo.amtEHBC === amtEHBC.DISABLED.value ? 'Disabled' : 'Unknown' }}
              </span>
            </p>
          </div>
          <div>
            <p style="display: inline-block;">
              <strong>Wireless for Intel® AMT: </strong>
              <span>
                {{ amtInfo.wirelessState === amtWirelessStatus.DISABLED.value.dbValue ? 'Disabled' :
                  amtInfo.wirelessState === amtWirelessStatus.ENABLED_TURNED_ON.value.dbValue
                    ? 'Enabled when the device is on'
                    : amtInfo.wirelessState
                      === amtWirelessStatus.ENABLED_TURNED_ON_AND_SLEEP.value.dbValue
                      ? 'Enabled when the device is on and suspended (Only when connected to AC)'
                      : amtInfo.wirelessState === amtWirelessStatus.NOT_SUPPORTED.value.dbValue
                        ? 'Not Supported' : 'Unknown' }}
              </span>
            </p>
          </div>
          <div>
            <p style="display: inline-block;">
              <strong>Local TLS Security: </strong>
              <span>
                {{ getTLSName(amtInfo.tlsLocal) }}
              </span>
            </p>
          </div>
          <div>
            <p style="display: inline-block;">
              <strong>Remote TLS Security: </strong>
              <span>
                {{ getTLSName(amtInfo.tlsRemote) }}
              </span>
            </p>
          </div>
        </div>
      </card>
      <disabled-feature
        v-else
        description="If you have devices that support Intel vPro AMT, you can enable and manage it.
        Your Boardgent plan does not support it."
      />
    </b-collapse>
  </card>
</template>

<script>
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import parseEnum from '@/helpers/parseEnums';
import disabledFeature from '@/components/errors/DisabledFeature.vue';

import vtulEnums from '../../../../cross/index';

export default {
  name: 'AmtDetails',
  components: {
    card,
    learnMore,
    'disabled-feature': disabledFeature,
  },
  props: {
    device: {
      type: Object,
      default() {
        return { };
      },
    },
    amtInfo: {
      type: Object,
      default() {
        return { };
      },
    },
    hasCapacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amtMode: vtulEnums.enum.amt.amtMode,
      amtTLS: vtulEnums.enum.amt.amtTLS,
      amtEHBC: vtulEnums.enum.amt.amtEHBC,
      amtStatus: vtulEnums.enum.amt.amtStatus,
      optInState: vtulEnums.enum.optIn.optInState,
      amtWirelessStatus: vtulEnums.enum.amt.amtWirelessStatus,
      parseEnum,
    };
  },
  methods: {
    getTLSName(tlsValue) {
      const tlsValues = parseEnum.enumToArray(this.amtTLS);
      return tlsValues.find((x) => x.dbValue === tlsValue).name;
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
