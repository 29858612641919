<template>
  <div>
    <div
      v-if="device.firewall || device.antivirus"
      class="mb-3"
    >
      <card external-card="material-card">
        <b-collapse
          slot="external-header"
          :open="false"
          class="card"
        >
          <card
            slot="trigger"
            slot-scope="props"
            internal-card="card-header"
            style-internal-header="display:flex; width:100%"
            style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
          >
            <p
              slot="header"
              class="card-header-title header-title"
            >
              <span>
                Security features inventory
              </span>
              <learn-more url="" />
            </p>
            <a
              slot="body"
              class="card-header-icon"
            >
              <b-icon
                v-show="props.open"
                icon="menu-up"
              />
              <b-icon
                v-show="!props.open"
                icon="menu-down"
              />
            </a>
          </card>
          <card
            internal-card="material-card-content"
          >
            <div slot="header">
              <p v-if="device.firewall">
                <strong>Firewall: </strong>
                {{ getNameFromEnum(device.firewall, firewallEnumValues) }}
              </p>
              <p v-if="device.antivirus">
                <strong>Windows Defender:</strong>
                {{ getNameFromEnum(device.antivirus, antivirusEnumValues) }}
              </p>
            </div>
          </card>
        </b-collapse>
      </card>
    </div>
    <learn-more url="" />
  </div>
</template>

<script>
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import parseEnum from '@/helpers/parseEnums';
import vtulEnums from '../../../../cross/index';

export default {
  name: 'StatusOfSettings',
  components: {
    card,
    learnMore,
  },
  props: {
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      antivirusEnumValues: parseEnum.enumToArray(vtulEnums.enum.device.antivirusStatus),
      firewallEnumValues: parseEnum.enumToArray(vtulEnums.enum.device.firewallStatus),
    };
  },
  methods: {
    getNameFromEnum(value, enumIn) {
      const values = enumIn;
      return values.find((x) => x.dbValue === value).name;
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}

</style>
