<template>
  <card
    v-if="deviceHardware.length>=1"
    external-card="material-card"
  >
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            {{ isPeripheral ? "Peripherals": "Hardware" }}
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>

      <card
        v-if="hasCapacity"
        internal-card="material-card-content"
      >
        <div
          v-for="item in deviceHardware"
          :key="item.id"
          slot="body"
          class="columns is-desktop"
        >
          <div class="column">
            <p
              class="subtitle"
              style="margin-bottom:5px;"
            >
              {{ item.typeEnum.name }}
            </p>
            <b-tag
              v-if="item.fromOS || item.type === hardwaresTypes.BATTERY.value.dbValue"
              type="is-primary"
            >
              From OS
            </b-tag>
            &nbsp;
            <b-tag
              v-if="item.fromAmt"
              type="is-primary"
            >
              From AMT
            </b-tag>
          </div>
          <div class="column">
            <p v-if="item.name && item.type !== 'Memory' && !item.name.match(/^\s+$/)">
              <strong>Name: </strong> {{ item.name }}
            </p>
            <p
              v-if="item.type && item.type === 'Memory' && !item.type.match(/^\s+$/)
                && item.name"
            >
              <strong>Type: </strong> {{ item.name }}
            </p>
            <p v-if="item.manufacturer && !item.manufacturer.match(/^\s+$/)">
              <strong>Manufacturer: </strong> {{ item.manufacturer }}
            </p>
            <p v-if="item.model && !item.model.match(/^\s+$/)">
              <strong>Model: </strong> {{ item.model }}
            </p>
            <p v-if="item.version && !item.version.match(/^\s+$/)">
              <strong>Version: </strong> {{ item.version }}
            </p>
            <p
              v-if="item.capacity && !item.capacity.match(/^\s+$/)"
            >
              <span v-if="item.type !== hardwaresTypes.BATTERY.value.dbValue">
                <strong>Capacity: </strong> {{ item.capacity |bytesSize(toBytesMetrics(item)) }}
              </span>
              <span v-else>
                <strong>Capacity: </strong> {{ item.capacity }} Wh
              </span>
            </p>
            <p v-if="item.cores && !item.cores.match(/^\s+$/)">
              <strong>Cores: </strong> {{ item.cores }}
            </p>
            <p v-if="item.physicalCores && !item.physicalCores.match(/^\s+$/)">
              <strong>Physical cores: </strong> {{ item.physicalCores }}
            </p>
            <p v-if="item.speed && !item.speed.match(/^\s+$/)">
              <strong>Speed: </strong> {{ item.speed }}
              {{
                (item.type === hardwaresTypes.CPU.value.dbValue)
                  ? 'GHz' : (item.type === hardwaresTypes.MEMORY.value.dbValue) ? 'MHz' : ''
              }}
            </p>
            <p v-if="item.health">
              <strong>Health: </strong> {{ item.health }}%
              <status-indicator
                :status="changeColorHealth(item.health)"
              />
            </p>
            <p v-if="item.vendor">
              <strong>Vendor: </strong> {{ item.vendor }}
            </p>
            <p v-if="item.memoryMax">
              <strong>Maximum memory: </strong> {{ item.memoryMax|bytesSize('iec') }}
            </p>
            <p v-if="item.memorySlots">
              <strong>Memory slots: </strong> {{ item.memorySlots }}
            </p>
            <p v-if="item.SKU">
              <strong>SKU: </strong> {{ item.SKU }}
            </p>
            <p v-if="item.typeChassis && !item.typeChassis.match(/^\s+$/)">
              <strong>Type: </strong> {{ item.typeChassis }}
            </p>
            <p v-if="item.assetTag">
              <strong>Asset tag: </strong> {{ item.assetTag }}
            </p>
            <p v-if="item.audioType">
              <strong>Type: </strong> {{ item.audioType }}
            </p>
            <p v-if="item.in">
              <strong>In: </strong> {{ item.in }}
            </p>
            <p v-if="item.out">
              <strong>Out: </strong> {{ item.out }}
            </p>
            <p v-if="item.Channel">
              <strong>Channel: </strong> {{ item.Channel }}
            </p>
            <p v-if="item.main">
              <strong>Main Display: </strong> {{ item.main }}
            </p>
            <p v-if="item.connection">
              <strong>Connection: </strong> {{ item.connection }}
            </p>
            <p v-if="item.resolutionX && item.resolutionY">
              <strong>Resolution: </strong> {{ `${item.resolutionX} x  ${item.resolutionY}` }}
            </p>
            <p v-if="item.location">
              <strong>Location: </strong> {{ item.location }}
            </p>
            <p v-if="item.partNumber && !item.partNumber.match(/^\s+$/)">
              <strong>Part number: </strong> {{ item.partNumber }}
            </p>
            <p v-if="item.serialNumber && !item.serialNumber.match(/^\s+$/)">
              <strong>Serial: </strong> {{ item.serialNumber }}
            </p>
            <p v-if="item.releaseDate && !item.releaseDate.match(/^\s+$/)">
              <strong>Release date: </strong>
              <span>
                <timeago
                  :key="`${item.id}-releaseDate`"
                  :since="item.releaseDate"
                  no-future
                />
              </span>
            </p>
            <p v-if="item.updatedAt && !item.updatedAt.match(/^\s+$/)">
              <strong>Detected: </strong>
              <span>
                <timeago
                  :key="`${item.id}-detected`"
                  :since="item.updatedAt"
                  no-future
                />
              </span>
            </p>
          </div>
        </div>
      </card>
      <disabled-feature
        v-else
        description="You can control your  hardware inventory here.
        Your Boardgent plan does not support it.."
      />
    </b-collapse>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { orderBy } from 'lodash';
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import disabledFeature from '@/components/errors/DisabledFeature.vue';
import { hardwareTypes } from '../../../../cross/src/hardware.enum';

export default {
  name: 'DeviceHardware',
  components: {
    card,
    learnMore,
    'disabled-feature': disabledFeature,
  },
  props: {
    deviceId: {
      type: String,
      default: '',
    },
    osVendor: {
      type: String,
      default() {
        return {};
      },
    },
    hasCapacity: {
      type: Boolean,
      default: false,
    },
    isPeripheral: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hardwaresTypes: hardwareTypes,
    };
  },
  computed: {
    ...mapGetters('hardware', { findDeviceHardwareInStore: 'find' }),
    ...mapGetters('amt-hardware', { findDeviceAmtHardwareInStore: 'find' }),
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    storeHardware() {
      let hList;
      if (this.deviceId) {
        const query = {
          deviceId: this.deviceId,
          companyId: this.currentCompany.id,
        };
        query.peripheral = this.isPeripheral;
        hList = this.findDeviceHardwareInStore({ query });
      }
      return hList && hList.data ? hList.data : [];
    },
    storeAmtHardware() {
      let haList;
      if (this.deviceId) {
        haList = this.findDeviceAmtHardwareInStore({
          query: {
            deviceId: this.deviceId,
            companyId: this.currentCompany.id,
          },
        });
      }
      return haList && haList.data ? haList.data : [];
    },
    deviceHardware() {
      const osHardware = this.storeHardware;
      const amtHardware = this.storeAmtHardware;
      // Temporally fix for AMT hardware
      amtHardware.forEach((amtHw, index) => {
        if (typeof amtHw.type === 'string') {
          amtHardware[index].type = this.hardwaresTypes.enums.filter(
            (type) => amtHw.type.toLowerCase() === type.value.name.toLowerCase(),
          )[0].value.dbValue;
        }
      });

      osHardware.forEach((osHw, index) => {
        const amtHw = amtHardware.find((x) => x.type === osHw.type);
        if (amtHw !== undefined) {
          osHardware[index] = Object.assign(osHw, amtHw);
          osHardware[index].fromOS = true;
          osHardware[index].fromAmt = true;
          amtHardware.splice(amtHardware.indexOf(amtHw), 1);
        } else {
          osHardware[index].fromOS = true;
        }
      });

      amtHardware.forEach((amtHw, index) => {
        amtHardware[index].fromAmt = true;
      });
      const resultArrayHardware = osHardware.concat(amtHardware);
      let resultArray;
      resultArray = resultArrayHardware;

      const batteryType = this.hardwaresTypes.BATTERY.value.dbValue;
      const latestBattery = resultArray.reduce((latest, obj) => {
        if (obj.type === batteryType && (!latest || obj.createdAt > latest.createdAt)) {
          return obj;
        }
        return latest;
      }, null);

      resultArray = resultArray.filter((obj) => (
        obj.type !== batteryType || obj.createdAt >= latestBattery.createdAt
      ));

      // Adding the type enum to the resulting array
      resultArray = resultArray.map((obj) => ({
        ...obj,
        typeEnum: this.hardwaresTypes.enums.filter(
          (type) => obj.type === type.value.dbValue,
        )[0].value,
      }));

      return orderBy(resultArray, 'typeEnum.sortPosition', 'asc');
    },
  },
  watch: {
    deviceId() {
      this.fetchApi();
    },
  },
  created() {
    this.fetchApi();
  },
  methods: {
    ...mapActions('amt-hardware', { findDeviceAmtHardware: 'find' }),
    ...mapActions('hardware', { findDeviceHardware: 'find' }),
    fetchApi() {
      if (!this.currentCompany) return;
      this.fetchDeviceHardware(null, 'findDeviceAmtHardware', false);
      this.fetchDeviceHardware(null, 'findDeviceHardware', this.isPeripheral);
    },
    async fetchDeviceHardware(skip, action, isPeripheral) {
      let skipHardware = skip;
      const sortHardware = { createdAt: 1 };
      if (!skipHardware) skipHardware = 0;

      const query = {
        $sort: sortHardware,
        $skip: skipHardware,
        $limit: 50,
        companyId: this.currentCompany.id,
        deviceId: this.deviceId,
      };

      if (isPeripheral) {
        query.peripheral = true;
      }
      const { total, data } = await this[action]({ query });
      this.total = total;
      skipHardware += data.length;
      if (skipHardware < total) {
        await this.fetchDeviceHardware(skipHardware, action);
      }
    },
    toBytesMetrics(item) {
      const isApple = this.osVendor === 'Apple';
      switch (item.type) {
        case hardwareTypes.MEMORY.value.dbValue:
          return 'iec';
        case hardwareTypes.STORAGE.value.dbValue:
        case hardwareTypes.GRAPHICS_CONTROLLER.value.dbValue:
          return isApple ? 'metric' : 'iec';
        default:
          return 'metric';
      }
    },
    changeColorHealth(health) {
      if (health >= 70) return 'positive';
      if (health < 30) return 'negative';
      return 'intermediary';
    },

  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
