import { render, staticRenderFns } from "./LocationDetails.vue?vue&type=template&id=c576b9e8&scoped=true&"
import script from "./LocationDetails.vue?vue&type=script&lang=js&"
export * from "./LocationDetails.vue?vue&type=script&lang=js&"
import style0 from "./LocationDetails.vue?vue&type=style&index=0&id=c576b9e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c576b9e8",
  null
  
)

export default component.exports