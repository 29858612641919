<template>
  <card
    slot="external-body"
    ref="deviceLocationContent"
    external-card="card"
    style-internal-card="flex:auto"
    internal-card="material-card"
  >
    <div
      slot="body"
    >
      <!-- We write map code here, but it will render in  mapContainer -->
      <!-- locationsSelected is use to re-render map when the tab becomes visible -->
      <div
        id="wrapper"
        slot="body"
      >
        <gmap-map
          v-if="locationsSelected && mapLocation"
          :center="mapLocation"
          :zoom="zoom"
          :options="{
            'minZoom': 2,
            'maxZoom': 20,
            'streetViewControl': false,
            'mapTypeControl': false,
            'clickableIcons': false
          }"
          style="height:420px;z-index:0;"
          @click="addPath($event)"
        >
          <gmap-marker
            :position="mapMarker"
            :icon="{'url': require('@/assets/img/map_icon.svg')}"
            @click="toggleInfoWindow($event)"
          />
          <gmap-info-window
            :position="mapMarker"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false"
          >
            {{ `${locationSelected ? locationSelected.address : ''}` }}
          </gmap-info-window>
          <gmap-polygon
            v-for="(perimeter, index) in perimeters.filter(
              (x) => x.type.dbValue === locationPerimeterType.POLYGON.value.dbValue)"
            :key="index"
            ref="polygon"
            :editable="perimeter.value === selectedPerimeter"
            :paths="perimeter.paths"
            @paths_changed="updateEdited($event)"
          />
          <gmap-circle
            v-for="(perimeter) in perimeters.filter((x) =>
              x.type.dbValue === locationPerimeterType.MARKER.value.dbValue
              && x.center.lat && x.center.lng
              && x.radius)"
            :key="perimeter.name"
            :center="{lat: parseFloat(perimeter.center.lat), lng: parseFloat(perimeter.center.lng)}"
            :radius="parseFloat(perimeter.radius)"
            :visible="true"
            :editable="perimeter.value === selectedPerimeter"
            :options="{'fillColor':'red', 'fillOpacity':0.2}"
            @radius_changed="radiusChanged($event)"
            @center_changed="centerChanged($event)"
          />
        </gmap-map>
        <manage-zones
          v-if="validateIfHasCapability([planCapabilities.GEOFENCING.value])
            && locationsSelected && mapLocation"
          :perimeters="perimeters"
          :on-update-parameters="updatePerimeters"
          :on-save-perimeters="savePerimeters"
        />
      </div>
      <learn-more url="" />
      <b-tabs
        v-model="activeTab"
        :animated="false"
        style="width: -webkit-fill-available"
      >
        <b-tab-item label="Locations">
          <b-table
            :data="locationsResult"
            :mobile-cards="false"
            :paginated="totalData > maxQuantity"
            :per-page="maxQuantity"
            :loading="locationsResult.length === 0 && isFindLocationsPending"
            :selected.sync="locationSelected"
            :current-page.sync="currentPage"
            hoverable
            backend-pagination
            backend-sorting
            :total="totalData"
            default-sort="createdAt"
            default-sort-direction="desc"
            @select="changeMapMarker"
            @page-change="changePagination"
            @sort="sortTable"
          >
            <b-table-column
              field="address"
              label="Location"
            >
              <template v-slot="props">
                {{ props.row.address }}
              </template>
            </b-table-column>

            <b-table-column
              field="createdAt"
              label="Connected"
              sortable
            >
              <template v-slot="props">
                <div>
                  <timeago
                    :key="props.row.id"
                    :since="props.row.createdAt"
                    no-future
                  />
                </div>
              </template>
            </b-table-column>

            <b-table-column
              label="Time there"
            >
              <template
                v-slot="props"
              >
                <span
                  v-if="isOnline && props.row.firstRecord"
                >
                  <timeago
                    :key="props.row.id"
                    :since="props.row.createdAt"
                    without-suffix
                    no-future
                    no-date
                  />
                </span>
                <span
                  v-else
                >
                  {{ new Date(props.row.updatedAt) - new Date(props.row.createdAt) | duration }}
                </span>
              </template>
            </b-table-column>

            <b-table-column
              field="updatedAt"
              label="Disconnected"
              sortable
            >
              <template
                v-slot="props"
              >
                <span
                  v-if="isOnline && props.row.firstRecord"
                >
                  Still connected there
                </span>
                <span v-else>
                  <div>
                    <timeago
                      :key="props.row.id"
                      :since="props.row.updatedAt"
                      no-future
                    />
                  </div>
                </span>
              </template>
            </b-table-column>

            <b-table-column
              field=""
              label=""
            >
              <template v-slot="props">
                <button
                  class="button is-primary is-small main-card-form-button
                    is-outlined is-bgbuttonwhite is-bgbuttonred"
                  @click="showHideDetails(props.row.id, true)"
                >
                  <span>Details </span>
                </button>
              </template>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>There are no locations to show</p>
                </div>
              </section>
            </template>
          </b-table>
        </b-tab-item>
        <b-tab-item
          :visible="activeTab === 1"
          :disabled="locationSelected == undefined"
          label="Details"
          class="pl-4 pr-4"
        >
          <template
            v-if="locationSelected"
            slot="header"
          >
            <span>{{ locationSelected.address }}
            </span>
          </template>
          <div>
            <LocationDetails
              :location="locationSelected"
              :is-online="isOnline"
            />
            <button
              class="button is-primary force-right"
              @click="showHideDetails(locationSelected ? locationSelected.id : 0, false)"
            >
              <b-icon
                icon="arrow-left"
              />
              <span>Back to List</span>
            </button>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import { throttle } from 'lodash';
import moment from 'moment';
import locationHelpers from '@/helpers/locationHelpers';
import toastMessage from '@/helpers/toastMessage';
import LocationDetails from '@/components/location/LocationDetails.vue';
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import ManageGeofences from '@/components/cross/ManageGeofences.vue';
import CompanyMixin from '@/mixins/company';
import RoleMixin from '@/mixins/roles';
import bgEnums from '../../../../cross';

const { locationPerimeterType } = bgEnums.enum.location;
const { planCapabilities } = bgEnums.enum.planCapabilities;
const { permissions } = bgEnums.enum.roles;

export default {
  name: 'DeviceLocations',
  components: {
    LocationDetails,
    card,
    learnMore,
    'manage-zones': ManageGeofences,
  },
  mixins: [CompanyMixin, RoleMixin, makeFindMixin({ service: 'locations', watch: true })],
  props: {
    deviceId: {
      type: String,
      required: true,
      default: '',
    },
    maxQuantity: {
      type: Number,
      required: true,
      default: 0,
    },
    locationsSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
    isOnline: {
      type: Boolean,
      required: true,
      default: true,
    },
    locationBounds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      zoom: 15,
      activeTab: 0,
      locationSelected: undefined,
      mapLocation: null,
      mapMarker: null,
      maxLocationsAllowed: 200,
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      perimeters: [],
      selectedPerimeter: null,
      locationPerimeterType,
      planCapabilities,
      limitLocations: this.maxQuantity,
      skipLocations: 0,
      currentPage: 1,
      sortDirection: 'desc',
      sortField: 'createdAt',
      mvcPaths: null,
      permissions,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    currentPaginationQuery() {
      return {
        $limit: this.limitLocations,
        $skip: this.skipLocations,
      };
    },
    queryForSearchLocations() {
      const query = {
        $sort: { [this.sortField]: this.sortDirection === 'asc' ? [1, 1] : [-1, -1] },
        deviceId: this.deviceId,
        companyId: this.currentCompany.id,
        enabledInDatabase: true,
      };

      return query;
    },
    locationsParams() {
      const queryWithoutSpecialFilters = JSON.parse(JSON.stringify(this.queryForSearchLocations));
      delete queryWithoutSpecialFilters.$client;
      queryWithoutSpecialFilters.$sort = { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 };
      return {
        query: {
          ...this.currentPaginationQuery,
          ...queryWithoutSpecialFilters,
        },
      };
    },
    locationsFetchParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchLocations,
        },
      };
    },
    locationsResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.locationsLatestQuery || !this.locationsPaginationData.default
        || !this.locationsPaginationData.default[this.locationsLatestQuery.queryId]
        || !this.locationsPaginationData.default[this.locationsLatestQuery.queryId][paginationId]) {
        return [];
      }

      const { queryId } = this.locationsLatestQuery;
      let locationsIds = this.locationsPaginationData.default[queryId][paginationId].ids;
      if (this.currentPage === 1) {
        // This is a trick to display the new locations reactive
        const filterLocations = this.locations;
        locationsIds = filterLocations.map((x) => x.id).concat(locationsIds);
        locationsIds = [...new Set(locationsIds)];
      }

      const locations = this.findLocationsInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: locationsIds,
          },
          $limit: this.limitLocations,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
        },
      });

      return locations.data;
    },
    totalData() {
      if (!this.locationsLatestQuery || !this.locationsPaginationData.default
          || !this.locationsPaginationData.default[this.locationsLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.locationsLatestQuery;
      const { total } = this.locationsPaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitLocations);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }
      return total;
    },
    polygonPaths() {
      if (!this.mvcPaths) return null;
      return locationHelpers.setPolygonPaths(this.mvcPaths);
    },
  },
  watch: {
    locationsResult(e) {
      this.setInitialLocation(e);
    },
    polygonPaths: throttle(function polygonPaths(paths) {
      const currentPerimeter = this.perimeters[this.selectedPerimeter];
      if (paths && currentPerimeter.type.dbValue
        === this.locationPerimeterType.POLYGON.value.dbValue) {
        [this.perimeters[this.selectedPerimeter].paths] = paths;
      }
    }, 1000),
  },
  created() {
    if (this.validateIfHasCapability([planCapabilities.GEOFENCING.value])
    && this.validateIfHasPermission([permissions.GEOFENCING.value.dbValue])) {
      this.perimeters = locationHelpers.parsePerimetersFromDb(this.locationBounds);
    }
  },
  methods: {
    ...mapActions('devices', { patchDeviceInfo: 'patch' }),
    changeMapMarker(location) {
      if (location && location.loc) {
        this.mapLocation = locationHelpers.parseLatLng(location.loc);
        this.mapMarker = locationHelpers.parseLatLng(location.loc);
      }
    },
    showHideDetails(locationId, show) {
      this.activeTab = show ? 1 : 0;
      const currentLocation = this.locationsResult.find((x) => x.id === locationId);
      this.locationSelected = currentLocation;
      setTimeout(() => {
        this.changeMapMarker(currentLocation);
      }, 100);
    },
    setInitialLocation(locationsParams) {
      const locations = JSON.parse(JSON.stringify(locationsParams));
      if (locations.length === 0) this.mapLocation = null;
      const currentLocation = locations.length > 0 ? locations.sort(
        (a, b) => {
          if (this.sortDirection === 'asc') {
            return moment(a[this.sortField]) - moment(b[this.sortField]);
          }
          return moment(b[this.sortField]) - moment(a[this.sortField]);
        },
      )[0] : null;
      if (currentLocation) {
        this.locationSelected = this.locationsResult.find((x) => x.id === currentLocation.id);
        this.changeMapMarker(currentLocation);
      }
    },
    toggleInfoWindow(event) {
      this.infoWinOpen = !this.infoWinOpen;
      if (this.validateIfHasCapability([planCapabilities.GEOFENCING.value])
        && this.perimeters[this.selectedPerimeter].type.dbValue
        === this.locationPerimeterType.MARKER.value.dbValue
        && this.perimeters[this.selectedPerimeter].center.lat === null) {
        this.perimeters[this.selectedPerimeter].center = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
      }
    },
    updateEdited(mvcPaths) {
      this.mvcPaths = mvcPaths;
    },
    addPath(event) {
      if (this.selectedPerimeter === null) {
        return;
      }
      const currentPerimeter = this.perimeters[this.selectedPerimeter];
      if (currentPerimeter.type.dbValue === this.locationPerimeterType.POLYGON.value.dbValue) {
        this.perimeters[this.selectedPerimeter].paths.push({
          lng: event.latLng.lng(),
          lat: event.latLng.lat(),
        });
      }
      if (this.perimeters[this.selectedPerimeter].center.lat !== null) return;
      if (currentPerimeter.type.dbValue === this.locationPerimeterType.MARKER.value.dbValue) {
        this.perimeters[this.selectedPerimeter].center = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
      }
    },
    updatePerimeters(data) {
      this.perimeters = data.perimeters ? data.perimeters : this.perimeters;
      this.selectedPerimeter = data.selectedPerimeter !== undefined
        ? data.selectedPerimeter : this.selectedPerimeter;
      this.mapLocation = data.mapCenter || this.mapLocation;
      this.zoom = data.zoom || this.zoom;
    },
    async savePerimeters() {
      const finalPerimeters = locationHelpers.parsePerimetersToDb(this.perimeters);
      if (locationHelpers.verifyPerimeters(finalPerimeters)) {
        await this.patchDeviceInfo([this.deviceId, {
          locationBounds: finalPerimeters,
          companyId: this.currentCompany.id,
        }]);
        toastMessage.showSuccess('Device perimeters updated successfuly');
      } else {
        toastMessage.showError('Verify that the number of perimeters on the map matches the perimeters created');
      }
    },
    radiusChanged(event) {
      this.perimeters[this.selectedPerimeter].radius = event;
    },
    centerChanged(event) {
      if (this.perimeters[this.selectedPerimeter].center.lat === null
      || this.perimeters[this.selectedPerimeter].center.lng === null) {
        return;
      }
      if (this.perimeters[this.selectedPerimeter].center.lat === event.lat()
      && this.perimeters[this.selectedPerimeter].center.lng === event.lng()) {
        return;
      }
      this.perimeters[this.selectedPerimeter].center = {
        lat: event.lat(),
        lng: event.lng(),
      };
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipLocations = (this.currentPage - 1) * this.limitLocations;
    },
    sortTable(field, direction) {
      this.sortField = field;
      this.sortDirection = direction;
    },
  },
};
</script>

<style scoped>

#wrapper { position: relative; }
.header-title {
  font-weight: 400;
  font-size: 18px;
}
.map-view {
  width: 100%; /* 30% */
  overflow: hidden;
}
.location-list {
   width: 100%; /* 70% */
}
</style>
