<template>
  <div
    v-if="validateIfHasCapability([planCapabilities.DISK_ENCRYPTION.value])"
    class="mb-3"
  >
    <card external-card="material-card-overflow">
      <b-collapse
        slot="external-header"
        :open="false"
        @open="isCardOpen = true"
        @close="isCardOpen = false"
      >
        <card
          slot="trigger"
          slot-scope="props"
          :internal-card="isCardOpen ? 'card-header' : ''"
          style-internal-header="display:flex; width:100%"
          style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
        >
          <p
            slot="header"
            class="card-header-title header-title"
          >
            FileVault Device Encryption
          </p>
          <a
            slot="body"
            class="card-header-icon"
          >
            <b-icon
              v-show="props.open"
              icon="menu-up"
            />
            <b-icon
              v-show="!props.open"
              icon="menu-down"
            />
          </a>
        </card>
        <card internal-card="material-card-content">
          <div
            slot="body"
          >
            <div>
              <b-message
                v-if="fileVaultError"
                :type="fileVaultError.type"
              >
                {{ fileVaultError.message }}
              </b-message>

              <b-table
                slot="body"
                class="principal-table bitlocker-table"
                :data="diskPartitionsInStore.filter(partition => partition.isSystemPartition)"
                :detailed="diskPartitionsInStore.filter(partition => partition.isSystemPartition)
                  [0].fileVaultStatus !== fileVaultStatusEnum.FULLY_DECRYPTED.value.dbValue
                  && !!currentFileVaultPassword"
                scrollable
                :mobile-cards="false"
                style="margin-top:1rem; width: 100%"
              >
                <b-table-column
                  field="volumeName"
                  label="Volume"
                >
                  <template v-slot="props">
                    {{ props.row.volumeName }}
                  </template>
                </b-table-column>

                <b-table-column
                  field="fileVaultStatus"
                  label="Status"
                >
                  <template v-slot="props">
                    {{ !props.row.fileVaultStatus ? '' : fileVaultStatusEnum.enums.find(
                      x => x.value.dbValue === props.row.fileVaultStatus).value.name }}
                  </template>
                </b-table-column>

                <b-table-column
                  field="encryptionManaged"
                  label="Encryption managed"
                >
                  <template v-slot="props">
                    {{ props.row.encryptionManaged ? 'Yes' : 'No' }}
                  </template>
                </b-table-column>

                <b-table-column
                  field="actions"
                  label="Actions"
                >
                  <template v-slot="props">
                    <div style="display: flex; flex-wrap: wrap; gap: 4px">
                      <b-button
                        icon-left="eye"
                        :class="{'is-loading': true
                          [props.row.letter]}"
                        class="button is-secondary is-small"
                        @click="validateIfNeedReason('FileVault password history',
                                                     getFileVaultKeyHistory, props.row)"
                      >
                        Reveal history
                      </b-button>
                    </div>
                  </template>
                </b-table-column>

                <template
                  slot="detail"
                >
                  <b-table
                    :data="[currentFileVaultPassword]"
                    :mobile-cards="false"
                    class="inner-table"
                    scrollable
                  >
                    <b-table-column
                      field="recoveryKey"
                      label="Recovery Key"
                      max-width="40px"
                    >
                      <template v-slot="propsDetails">
                        <span
                          v-if="fileVaultKey[propsDetails.row.id]"
                          class="password-style material-card-content is-size-7-mobile
                                is-rounded"
                        >
                          <span
                            v-for="(letter, index) in (propsDetails.row.recoveryKey)"
                            :key="index"
                            :class="`${getClass(letter)} mb-2`"
                          >
                            {{ letter }}
                          </span>
                        </span>
                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          ) && fileVaultKey[propsDetails.row.id]"
                          v-clipboard:copy="fileVaultKey[propsDetails.row.id]"
                          icon-left="clipboard-outline"
                          class="button is-small is-primary is-outlined"
                          @click="showToastCopy"
                        >
                          Copy
                        </b-button>
                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          ) && !fileVaultKey[propsDetails.row.id]"
                          class="is-primary is-small"
                          :class="{'is-loading': isGettingfileVaultKey[propsDetails.row.id] }"
                          icon-left="eye"
                          @click="validateIfNeedReason('FileVault recovery key',
                                                       getFileVaultKey, propsDetails.row)"
                        >
                          Reveal
                        </b-button>
                        <b-button
                          v-else-if="validateIfHasPermission(
                            [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          ) && fileVaultKey[propsDetails.row.id]"
                          class="button is-small is-primary ml-1"
                          icon-left="eye-off"
                          @click="getFileVaultKey(propsDetails.row)"
                        >
                          Conceal
                        </b-button>
                        <span v-else>********</span>
                      </template>
                    </b-table-column>

                    <b-table-column
                      field="enabledUser"
                      label="Enabled user"
                    >
                      <template v-slot="propsDetails">
                        {{ propsDetails.row.enabledUser }}
                      </template>
                    </b-table-column>

                    <b-table-column
                      field="enabledDate"
                      label="Activation date"
                    >
                      <template v-slot="propsDetails">
                        {{ propsDetails.row.enabledDate | moment }}
                      </template>
                    </b-table-column>

                    <b-table-column
                      v-if="displayInvalidRecoveryKeyDate"
                      field="invalidRecoveryKeyTimeStamp"
                      label="Detected invalid since"
                    >
                      <template v-slot="propsDetails">
                        {{ propsDetails.row.invalidRecoveryKeyTimeStamp | moment }}
                      </template>
                    </b-table-column>
                  </b-table>
                </template>
              </b-table>
            </div>
          </div>
        </card>
      </b-collapse>
    </card>
    <b-modal
      slot="footer"
      :parent="this"
      :active.sync="isFileVaultKeyHistoryVisible"
      has-modal-card
    >
      <fileVaultKeyHistory
        :disk-partition="activeFileVaultPartition"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import RoleMixin from '@/mixins/roles';
import CompanyMixin from '@/mixins/company';
import ExecutionsMixin from '@/mixins/executions';
import toastMessage from '@/helpers/toastMessage';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import fileVaultKeyHistory from '@/components/device/DeviceFileVaultKeysHistory.vue';
import bgEnums from '../../../../cross/index';

const { planCapabilities } = bgEnums.enum.planCapabilities;
const { executeAction: executeActionEnum } = bgEnums.enum.execution;
const { permissions } = bgEnums.enum.roles;
export default {
  name: 'FileVaultCard',
  components: {
    card,
    fileVaultKeyHistory,
  },
  mixins: [ExecutionsMixin, CompanyMixin, RoleMixin],
  props: {
    device: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      planCapabilities,
      isFileVaultKeyHistoryVisible: false,
      isGettingFileVaultKeyHistory: {},
      fileVaultKey: {},
      isGettingfileVaultKey: {},
      maxQuantity: 10,
      fileVaultStatusEnum: bgEnums.enum.fileVault.fileVaultStatus,
      activeFileVaultPartition: {},
      permissions,
      isCardOpen: false,
      executeActionEnum,
    };
  },
  computed: {
    ...mapGetters('disk-partitions', { findDiskPartitionsInStore: 'find' }),
    ...mapGetters('filevault-keys', { findFileVaultKeysInStore: 'find' }),

    diskPartitionsInStore() {
      const disksPartitions = this.findDiskPartitionsInStore({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          isSystemPartition: true,
          $sort: { position: 1 },
        },
      });
      return disksPartitions.data;
    },

    currentFileVaultPassword() {
      let eList;
      if (this.device.id && this.currentCompany) {
        eList = this.findFileVaultKeysInStore({
          query: {
            deviceId: this.device.id,
            companyId: this.currentCompany.id,
            $sort: { createdAt: -1 },
            $limit: 1,
          },
        });
      }
      return eList && eList.data ? eList.data[0] : undefined;
    },

    fileVaultError() {
      if (!this.diskPartitionsInStore[0]) {
        return false;
      }
      const partition = this.diskPartitionsInStore[0];
      if (partition.fileVaultStatus === this.fileVaultStatusEnum.FULLY_ENCRYPTED.value.dbValue
        && partition.encryptionManaged === false) {
        return {
          title: 'Encryption not managed',
          message: 'Encryption is not managed by Boardgent on this device.',
          type: 'is-danger',
        };
      }
      if (!this.currentFileVaultPassword) return false;
      if (this.currentFileVaultPassword.invalidRecoveryKeyTimeStamp !== null
        && partition.fileVaultStatus === this.fileVaultStatusEnum.FULLY_ENCRYPTED.value.dbValue) {
        return {
          title: 'Invalid recovery key',
          message: 'The key stored on Boardgent is not longer valid on this device.',
          type: 'is-danger',
        };
      }
      return false;
    },
    displayInvalidRecoveryKeyDate() {
      return this.currentFileVaultPassword
        ? !!this.currentFileVaultPassword.invalidRecoveryKeyTimeStamp : false;
    },
  },
  watch: {
    deviceId() {
      this.fetchPartitions();
      this.fetchFileVaultKeys();
    },
    diskPartitionsInStore() {
      this.fetchFileVaultKeys();
    },
  },
  created() {
    this.fetchPartitions();
    this.fetchFileVaultKeys();
  },
  methods: {
    ...mapActions('disk-partitions', { findDiskPartitions: 'find' }),
    ...mapActions('filevault-keys', { findFileVaultKeys: 'find' }),

    async fetchPartitions(skip = 0) {
      if (!this.currentCompany) return;
      const { total, data } = await this.findDiskPartitions({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          $skip: skip,
          $limit: 50,
        },
      });
      const totalGet = skip + data.length;
      if (totalGet < total) {
        this.fetchPartitions(totalGet);
      }
    },

    async fetchFileVaultKeys(skip = 0) {
      if (!this.currentCompany || !this.diskPartitionsInStore) return;
      const { total, data } = await this.findFileVaultKeys({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          $skip: skip,
          $limit: 50,
        },
      });
      const totalGet = skip + data.length;
      if (totalGet < total) {
        this.fetchFileVaultKeys(totalGet);
      }
    },

    async getFileVaultKey(fileVaultKey, reason) {
      if (this.fileVaultKey[fileVaultKey.id]) {
        this.$set(this.fileVaultKey, fileVaultKey.id, '');
        return;
      }
      this.$set(this.isGettingfileVaultKey, fileVaultKey.id, true);
      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeActionEnum.GET_FILEVAULT_KEY.value.id, { reason });
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting FileVault key.');
          return;
        }
        if (execution.results[0].exitCode === '0') {
          this.$set(this.isGettingfileVaultKey, fileVaultKey.id, false);
          this.$set(this.fileVaultKey, fileVaultKey.id, fileVaultKey.recoveryKey);
        }
      } catch (err) {
        toastMessage.showError(err.toString());
        this.$set(this.isGettingfileVaultKey, fileVaultKey.id, false);
      }
    },
    async getFileVaultKeyHistory(diskPartition, reason) {
      if (this.isFileVaultKeyHistoryVisible) {
        this.isFileVaultKeyHistoryVisible = false;
        return;
      }
      this.$set(this.isGettingFileVaultKeyHistory, diskPartition.letter, true);
      this.activeFileVaultPartition = diskPartition;
      try {
        const execution = await this.createExecutions(this.currentCompany.id,
          this.$store.getters['auth/user'].id, [this.device.id],
          executeActionEnum.GET_FILEVAULT_KEY_HISTORY.value.id, { reason });
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting FileVault keys History.');
          return;
        }
        if (execution.results[0].exitCode === '0') {
          this.isFileVaultKeyHistoryVisible = true;
          this.$set(this.isGettingFileVaultKeyHistory, diskPartition.letter, false);
        }
      } catch (err) {
        toastMessage.showError(err.toString());
        this.$set(this.isGettingFileVaultKeyHistory, diskPartition.letter, false);
      }
    },
    showToastCopy() {
      toastMessage.showSuccess('Password copied to clipboard');
    },
    validateIfNeedReason(elementName, callback, fileVaultKey) {
      if (this.currentCompany.needReasonToShowPassword) {
        this.getReason(elementName, callback, fileVaultKey);
      } else {
        callback(fileVaultKey);
      }
    },
    getReason(elementName, callback, param) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: (reason) => (param ? callback(param, reason.text) : callback(reason.text)),
          title: `Show ${elementName} of ${this.device.name}`,
          confirmText: 'Reveal',
          confirmIcon: 'eye',
          message: 'Please type the reason why you need this password',
          getInformationFromTextArea: true,
          informationIsRequired: true,
        },
      });
    },
    getClass(letter) {
      if ('0123456789'.includes(letter)) {
        return 'number-char';
      }
      if ('~!@#$%^&*_-+=`|(){}[]:;"\'<>,.?/'.includes(letter)) {
        return 'special-char';
      }
      return 'text-char';
    },
  },
};
</script>
<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
.informationText {
  margin: 4px 0px;
}
.number-char {
  color: #0572EC;
  display:inline-block;
  width: 8px;
}
.special-char {
  color: #E60000;
  display:inline-block;
  width: 8px;
}
.text-char {
  display:inline-block;
  width: 8px;
}
</style>

<style>
.bitlocker-table .hide-details .chevron-cell a{
  display: none;
}

.lock-tooltip .tooltip-content {
  left: 0 !important;
  transform: initial !important;
}
.lock-tooltip .tooltip-content::before {
  left: 16% !important;
}
</style>
