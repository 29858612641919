export default {
  name: 'ValidationsMixin',
  methods: {
    biosVendorIsSupported(vendor) {
      if (!vendor) return false;
      const supportedVendors = ['dell inc.', 'hewlett-packard', 'hp', 'hp-pavilion', 'lenovo'];
      return supportedVendors.includes(vendor.toLowerCase());
    },
    isLenovo(vendor) {
      if (!vendor) return false;
      const lenovoVendors = ['lenovo'];
      return lenovoVendors.includes(vendor.toLowerCase());
    },
    isHewlettPackard(vendor) {
      if (!vendor) return false;
      const lenovoVendors = ['hewlett-packard', 'hp', 'hp-pavilion'];
      return lenovoVendors.includes(vendor.toLowerCase());
    },
    isDell(vendor) {
      if (!vendor) return false;
      const lenovoVendors = ['dell inc.'];
      return lenovoVendors.includes(vendor.toLowerCase());
    },
  },
};
