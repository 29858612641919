<template>
  <card external-card="material-card">
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            Operating system
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>
      <card
        internal-card="material-card-content"
      >
        <div slot="header">
          <p v-if="device.OS">
            <strong>Name: </strong> {{ device.OS }}
          </p>
          <p
            v-if="device.OSVendor"
          >
            <strong>Vendor: </strong> {{ device.OSVendor }}
          </p>
          <p v-if="device.OSBuild">
            <strong>Version: </strong> {{ device.OSBuild }}
          </p>
          <p v-if="device.osArch">
            <strong>Architecture: </strong> {{ device.osArch }}
          </p>
          <p v-if="device.domain">
            <strong>Domain/Workgroup: </strong> {{ device.domain }}
          </p>
          <p v-if="device.freezeStatus">
            <strong>Freeze status: </strong> {{ getNameFromEnum(device.freezeStatus,
                                                                freezeStatusEnumValues) }}
          </p>
          <p v-if="device.osBootType">
            <strong>Boot type: </strong>
            {{ getNameFromEnum(device.osBootType, bootTypeEnumValues) }}
          </p>
          <p v-if="device.secureBoot">
            <strong>Secure boot: </strong>
            {{ getNameFromEnum(device.secureBoot, secureBootEnumValues) }}
          </p>
          <p v-if="device.licenseStatus">
            <strong>License status: </strong>
            {{ getNameFromEnum(parseInt(device.licenseStatus), licenseEnumValues) }}
          </p>
          <p v-if="device.osInstallationDate">
            <strong>Install date: </strong>
            <span>
              <timeago
                :since="device.osInstallationDate"
                no-future
              />
            </span>
          </p>
        </div>
      </card>
    </b-collapse>
  </card>
</template>

<script>

import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import parseEnum from '@/helpers/parseEnums';
import bgEnums from '../../../../cross/index';

export default {
  name: 'OperatingSystem',
  components: {
    card,
    learnMore,
  },
  props: {
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      bootTypeEnumValues: parseEnum.enumToArray(bgEnums.enum.device.bootType),
      secureBootEnumValues: parseEnum.enumToArray(bgEnums.enum.device.secureBoot),
      freezeStatusEnumValues: parseEnum.enumToArray(bgEnums.enum.device.freezeStatus),
      licenseEnumValues: parseEnum.enumToArray(bgEnums.enum.software.windowsLicense),
    };
  },
  methods: {
    getNameFromEnum(value, enumIn) {
      const values = enumIn;
      return values.find((x) => x.dbValue === value).name;
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
