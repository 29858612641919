<template>
  <card external-card="material-card">
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
          style="flex-grow: 1"
        >
          <span>
            Software
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>

      <card
        v-if="hasCapacity"
        class="material-card-content"
      >
        <b-tabs
          slot="header"
          v-model="activeTab"
          :animated="false"
          style="flex:1"
        >
          <b-tab-item
            v-if="device.OSVendor === 'Microsoft'"
            :label="`Programs and Features (${totalMainSoftware})`"
          >
            <b-table
              :data="softwareResult"
              :paginated="totalData > maxQuantity"
              :mobile-cards="false"
              :per-page="maxQuantity"
              :loading="softwareResult.length === 0 && isFindSoftwarePending"
              :current-page.sync="currentPage"
              default-sort="name"
              backend-pagination
              backend-sorting
              :total="totalData"
              @page-change="changePagination"
              @sort="sortTable"
            >
              <b-table-column
                field="name"
                label="Name"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>

              <b-table-column
                field="vendor"
                label="Vendor"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.vendor }}
                </template>
              </b-table-column>

              <b-table-column
                field="installedOn"
                label="Installed on"
                sortable
              >
                <template v-slot="props">
                  {{
                    props.row.installedOn == 'Invalid date'
                      ? '' : props.row.installedOn | moment
                  }}
                </template>
              </b-table-column>

              <b-table-column
                field="version"
                label="Version"
                sortable
                numeric
              >
                <template v-slot="props">
                  {{ props.row.version }}
                </template>
              </b-table-column>

              <b-table-column
                v-if="device.OSVendor === 'Microsoft'"
                field="license"
                label="License"
                sortable
              >
                <template v-slot="props">
                  {{ getLicenseName(props.row.license) }}
                </template>
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="file"
                        size="is-large"
                      />
                    </p>
                    <p>There are no software to show.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </b-tab-item>
          <b-tab-item :label="`All (${totalSoftware})`">
            <b-table
              :data="softwareResult"
              :paginated="totalData > maxQuantity"
              :mobile-cards="false"
              :per-page="maxQuantity"
              :loading="softwareResult.length === 0 && isFindSoftwarePending"
              :current-page.sync="currentPage"
              default-sort="name"
              backend-pagination
              backend-sorting
              :total="totalData"
              @page-change="changePagination"
              @sort="sortTable"
            >
              <b-table-column
                field="name"
                label="Name"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>

              <b-table-column
                field="vendor"
                label="Vendor"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.vendor }}
                </template>
              </b-table-column>

              <b-table-column
                field="installedOn"
                label="Installed on"
                sortable
              >
                <template v-slot="props">
                  <div v-if="props.row.installedOn">
                    <timeago
                      :since="props.row.installedOn"
                      no-future
                    />
                  </div>
                </template>
              </b-table-column>

              <b-table-column
                field="version"
                label="Version"
                sortable
                numeric
              >
                <template v-slot="props">
                  {{ props.row.version }}
                </template>
              </b-table-column>

              <b-table-column
                v-if="device.OSVendor === 'Microsoft'"
                field="license"
                label="License"
                sortable
              >
                <template v-slot="props">
                  {{ getLicenseName(props.row.license) }}
                </template>
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="file"
                        size="is-large"
                      />
                    </p>
                    <p>There are no software to show.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </b-tab-item>
        </b-tabs>
      </card>
      <disabled-feature
        v-else
        description="You can control your  hardware inventory here.
        Your Boardgent plan does not support it"
      />
    </b-collapse>
  </card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import disabledFeature from '@/components/errors/DisabledFeature.vue';

import bgEnums from '../../../../cross/index';

export default {
  name: 'DeviceSoftware',
  components: {
    card,
    learnMore,
    'disabled-feature': disabledFeature,
  },
  mixins: [makeFindMixin({ service: 'software', watch: true })],
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    maxQuantity: {
      type: Number,
      default: 0,
    },
    hasCapacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
      uiAllPrograms: [],
      programsAndFeatures: [],
      limitSoftware: this.maxQuantity,
      skipSoftware: 0,
      currentPage: 1,
      sortDirection: 'asc',
      sortField: 'name',
      totalSoftware: 0,
      totalMainSoftware: 0,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    currentPaginationQuery() {
      return {
        $limit: this.limitSoftware,
        $skip: this.skipSoftware,
      };
    },
    queryForSearchSoftware() {
      const query = {
        $sort: { [this.sortField]: this.sortDirection === 'asc' ? [1, 1] : [-1, -1] },
        deviceId: this.device.id,
        companyId: this.currentCompany.id,
        enabled: true,
      };

      if (this.activeTab === 0 && this.device.OSVendor === 'Microsoft') {
        query.isMain = true;
      }

      return query;
    },
    softwareParams() {
      const queryWithoutSpecialFilters = JSON.parse(JSON.stringify(
        this.queryForSearchSoftware,
      ));
      delete queryWithoutSpecialFilters.$client;
      queryWithoutSpecialFilters.$sort = { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 };
      return {
        query: {
          ...this.currentPaginationQuery,
          ...queryWithoutSpecialFilters,
        },
      };
    },
    softwareFetchParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchSoftware,
        },
      };
    },
    softwareResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.softwareLatestQuery || !this.softwarePaginationData.default
          || !this.softwarePaginationData.default[this.softwareLatestQuery.queryId]
          || !this.softwarePaginationData.default[this.softwareLatestQuery.queryId][paginationId]) {
        return [];
      }

      const { queryId } = this.softwareLatestQuery;
      let softwareIds = this.softwarePaginationData.default[queryId][paginationId].ids;
      if (this.currentPage === 1) {
        // This is a trick to display the new software reactive
        const filterSofware = this.software;
        softwareIds = filterSofware.map((x) => x.id).concat(softwareIds);
        softwareIds = [...new Set(softwareIds)];
      }

      const software = this.findSoftwareInStore({
        query: {
          companyId: this.currentCompany.id,
          id: { $in: softwareIds },
          $limit: this.limitSoftware,
          $sort: { [this.sortField]: this.sortDirection === 'asc' ? 1 : -1 },
        },
      });
      return software.data;
    },
    totalData() {
      if (!this.softwareLatestQuery || !this.softwarePaginationData.default
        || !this.softwarePaginationData.default[this.softwareLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.softwareLatestQuery;
      const { total } = this.softwarePaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitSoftware);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }

      return total;
    },
  },
  watch: {
    device(newDevice, oldDevice) {
      if (newDevice && oldDevice && newDevice.id !== oldDevice.id) {
        this.activeTab = newDevice.OSVendor === 'Microsoft' ? 0 : 1;
        this.getTotalSoftware();
      }
    },
  },
  created() {
    this.getTotalSoftware();
  },
  methods: {
    ...mapActions('software', { findDeviceSoftware: 'find' }),
    async getTotalSoftware() {
      if (!this.currentCompany) return;
      const query = {
        companyId: this.currentCompany.id,
        deviceId: this.device.id,
        enabled: true,
        $limit: 0,
      };
      const totalSoftware = await this.findDeviceSoftware({ query });
      this.totalSoftware = totalSoftware.total;
      if (this.device.OSVendor === 'Microsoft') {
        query.isMain = true;
        const totalMainSoftware = await this.findDeviceSoftware({ query });
        this.totalMainSoftware = totalMainSoftware.total;
      }
    },
    getLicenseName(id) {
      return bgEnums.enum.software.officeLicense.enums.find((x) => x.value.dbValue === id)
        .value.name;
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipSoftware = (this.currentPage - 1) * this.limitSoftware;
    },
    sortTable(field, direction) {
      this.sortField = field;
      this.sortDirection = direction;
    },
  },
};
</script>
<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
