var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.validateIfHasCapability([_vm.planCapabilities.DISK_ENCRYPTION.value]))?_c('div',{staticClass:"mb-3"},[_c('card',{attrs:{"external-card":"material-card-overflow"}},[_c('b-collapse',{attrs:{"slot":"external-header","open":false},on:{"open":function($event){_vm.isCardOpen = true},"close":function($event){_vm.isCardOpen = false}},slot:"external-header",scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('card',{attrs:{"internal-card":_vm.isCardOpen ? 'card-header' : '',"style-internal-header":"display:flex; width:100%","style-internal-card":"display:flex;flex-direction:row;justify-content:space-between"}},[_c('p',{staticClass:"card-header-title header-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" BitLocker Device Encryption ")]),_c('a',{staticClass:"card-header-icon",attrs:{"slot":"body"},slot:"body"},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(props.open),expression:"props.open"}],attrs:{"icon":"menu-up"}}),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!props.open),expression:"!props.open"}],attrs:{"icon":"menu-down"}})],1)])}}],null,false,2159114363)},[_c('card',{attrs:{"internal-card":"material-card-content"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('p',{staticClass:"informationText"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","type":_vm.bitLockerCanBeEnabled.color,"size":"is-small"}}),_c('strong',[_vm._v(" "+_vm._s(_vm.bitLockerCanBeEnabled.message)+" ")])],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"informationText"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","type":_vm.bitlockerRequirements.tpm === _vm.requirementStatusEnum.GOOD.value
                    ? 'is-success' : 'is-danger',"size":"is-small"}}),(_vm.deviceHardwareInStore.length > 0 && _vm.deviceHardwareInStore[0].version)?_c('span',[_vm._v(" TPM "+_vm._s(parseFloat(_vm.deviceHardwareInStore[0].version).toFixed(1))+" ")]):_c('span',[_vm._v(" TPM 1.2 or higher ")])],1),(_vm.bitlockerRequirements.uefi !== undefined)?_c('p',{staticClass:"informationText"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","type":_vm.bitlockerRequirements.uefi === _vm.requirementStatusEnum.GOOD.value
                    ? 'is-success' : 'is-danger',"size":"is-small"}}),_c('span',[_vm._v(" UEFI boot type ")])],1):_vm._e(),_c('p',{staticClass:"informationText"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","type":_vm.bitlockerRequirements.bitlockerInstalled
                    === _vm.requirementStatusEnum.GOOD.value ? 'is-success' : 'is-danger',"size":"is-small"}}),_c('span',[_vm._v(" BitLocker installed")])],1)]),_c('div',{staticClass:"column"},[(_vm.bitlockerRequirements.gpt !== undefined)?_c('p',{staticClass:"informationText"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","type":_vm.bitlockerRequirements.gpt === _vm.requirementStatusEnum.GOOD.value
                    ? 'is-success' : 'is-danger',"size":"is-small"}}),_c('span',[_vm._v(" GPT partition table")])],1):_vm._e(),_c('p',{staticClass:"informationText"},[_c('b-icon',{attrs:{"icon":"checkbox-blank-circle","type":_vm.bitlockerRequirements.systemPartition
                    === _vm.requirementStatusEnum.GOOD.value ? 'is-success' : 'is-danger',"size":"is-small"}}),_c('span',[_vm._v(" System partition created")])],1)])]),(_vm.bitLockerCanBeEnabled.encryptable && _vm.diskPartitionsInStore.length > 0)?_c('div',[_c('div',{staticClass:"mb-5",staticStyle:{"display":"flex","flex-wrap":"wrap","gap":"8px"}},[_c('b-tooltip',{staticClass:"lock-tooltip",attrs:{"active":!_vm.bitLockerCanBeEnabled.osEncryptable,"label":_vm.bitLockerCanBeEnabled.messageLock,"position":"is-top","type":"is-dark"}},[(_vm.validateIfHasPermission(
                    [_vm.permissions.GEOLOCATION_AND_LOCK_EXECUTIONS.value.dbValue]
                  ))?_c('b-button',{staticClass:"is-danger",attrs:{"disabled":!_vm.bitLockerCanBeEnabled.osEncryptable},on:{"click":_vm.confirmLockDevice}},[_vm._v(" Lock device ")]):_vm._e()],1),_c('b-tooltip',{staticClass:"lock-tooltip",attrs:{"active":_vm.diskPartitionsInStore.filter(x => x.letter !== null)
                  .every(x=> x.bitlockerStatus === _vm.bitlockerStatusEnum.FullyDecrypted.value),"label":"There are no encrypted partitions to get passwords from","position":"is-top","type":"is-dark"}},[(_vm.validateIfHasPermission(
                    [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                  ))?_c('b-button',{staticClass:"button is-secondary",attrs:{"disabled":_vm.diskPartitionsInStore.filter(x => x.letter !== null)
                    .every(x=> x.bitlockerStatus === _vm.bitlockerStatusEnum.FullyDecrypted.value)},on:{"click":function($event){return _vm.getBitlockerKeys()}}},[_vm._v(" Refresh passwords ")]):_vm._e()],1),(_vm.validateIfHasPermission(
                  [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                ))?_c('b-button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.refreshRequirements()}}},[_vm._v(" Refresh requirements ")]):_vm._e(),(_vm.validateIfHasPermission(
                  [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                ) && _vm.diskPartitionsInStore.filter(x => x.letter !== null)
                  .some(x=> x.bitlockerStatus !== _vm.bitlockerStatusEnum.FullyEncrypted.value &&
                    x.bitlockerStatus !== _vm.bitlockerStatusEnum.EncryptionInProgress.value))?_c('b-button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.encrypt()}}},[_vm._v(" Encrypt all ")]):_vm._e(),(_vm.validateIfHasPermission(
                  [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                ) && _vm.diskPartitionsInStore.filter(x => x.letter !== null)
                  .some(x=> x.bitlockerStatus !== _vm.bitlockerStatusEnum.FullyDecrypted.value &&
                    x.bitlockerStatus !== _vm.bitlockerStatusEnum.DecryptionInProgress.value))?_c('b-button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.decrypt()}}},[_vm._v(" Decrypt all ")]):_vm._e()],1),_c('p',[_c('strong',[_vm._v("Bitlocker passwords")])]),_c('card',{attrs:{"internal-card":"material-card-content"}},[_c('b-table',{staticClass:"principal-table bitlocker-table",staticStyle:{"margin-top":"1rem","width":"100%"},attrs:{"slot":"body","data":_vm.diskPartitionsInStore.filter(partition => partition.letter !== null
                  && partition.letter !== '' && partition.isSystemPartition !== true
                  && !partition.isDynamic),"detailed":"","scrollable":"","mobile-cards":true,"row-class":(row) => _vm.calculateRowClass(row)},slot:"body",scopedSlots:_vm._u([{key:"detail",fn:function(props){return (props.row.bitlockerStatus !== _vm.bitlockerStatusEnum.FullyDecrypted.value &&
                    _vm.storeBitlockerPasswords.some((password) => password.isActive &&
                      password.diskPartitionId === props.row.id))?[_c('b-table',{staticClass:"inner-table",attrs:{"data":_vm.storeBitlockerPasswords.filter(password => password.isActive &&
                      password.diskPartitionId === props.row.id),"mobile-cards":false,"scrollable":""}},[_c('b-table-column',{attrs:{"field":"keyProtectorType","label":"Key Id"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(propsDetails.row.keyProtectorId)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"field":"keyProtectorType","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(propsDetails.row.keyProtectorType)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"field":"recoveryPassword","label":"Password","max-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [(_vm.bitlockerRecoveryPassword[propsDetails.row.id])?_c('span',{staticClass:"password-style material-card-content is-size-7-mobile is-rounded"},_vm._l(((propsDetails.row.recoveryPassword
                              || propsDetails.row.password)),function(letter,index){return _c('span',{key:index,class:`${_vm.getClass(letter)} mb-2`},[_vm._v(" "+_vm._s(letter)+" ")])}),0):_vm._e(),(_vm.validateIfHasPermission(
                            [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          ) && _vm.bitlockerRecoveryPassword[propsDetails.row.id])?_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.bitlockerRecoveryPassword[propsDetails.row.id]),expression:"bitlockerRecoveryPassword[propsDetails.row.id]",arg:"copy"}],staticClass:"button is-small is-primary is-outlined",attrs:{"icon-left":"clipboard-outline"},on:{"click":_vm.showToastCopy}},[_vm._v(" Copy ")]):_vm._e(),(_vm.validateIfHasPermission(
                            [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          ) && !_vm.bitlockerRecoveryPassword[propsDetails.row.id])?_c('b-button',{staticClass:"is-primary is-small",class:{'is-loading': _vm.isGettingBitlockerRecoveryPassword[
                            propsDetails.row.id] },attrs:{"icon-left":"eye"},on:{"click":function($event){return _vm.validateIfNeedReason(
                            'BitLocker recovery password', _vm.getBitlockerRecoveryPassword,
                            propsDetails.row)}}},[_vm._v(" Reveal ")]):(_vm.validateIfHasPermission(
                            [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          ) && _vm.bitlockerRecoveryPassword[propsDetails.row.id])?_c('b-button',{staticClass:"button is-small is-primary ml-1",attrs:{"icon-left":"eye-off"},on:{"click":function($event){return _vm.getBitlockerRecoveryPassword(propsDetails.row)}}},[_vm._v(" Conceal ")]):_c('span',[_vm._v("********")])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"createdAt","label":"First detection"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(_vm._f("moment")(propsDetails.row.createdAt))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"field":"lastDetection","label":"Last detection"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(_vm._f("moment")(propsDetails.row.lastDetection ? propsDetails.row.lastDetection : propsDetails.row.createdAt))+" ")]}}],null,true)})],1)]:undefined}}],null,true)},[_c('b-table-column',{attrs:{"field":"letter","label":"Partition"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.letter)+" "),(props.row.isBootPartition)?_c('b-tag',{staticClass:"ml-1",attrs:{"rounded":""}},[_c('b',[_vm._v("OS Partition")])]):_vm._e()]}}],null,false,8415937)}),_c('b-table-column',{attrs:{"field":"bitlockerStatus","label":"BitLocker status"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.bitlockerStatus === null
                        || props.row.bitlockerStatus === undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.bitlockerStatusEnum.Unknown.key.replace(/([A-Z])/g, ' $1'))+" ")]):([_vm.bitlockerStatusEnum.None.value,
                                  _vm.bitlockerStatusEnum.Locked.value]
                        .indexOf(props.row.bitlockerStatus) !== -1
                        && _vm.storeBitlockerPasswords.some((password) => password.isActive &&
                          password.diskPartitionId === props.row.id))?_c('span',[_vm._v(" "+_vm._s(_vm.bitlockerStatusEnum.FullyEncrypted.key.replace(/([A-Z])/g, ' $1'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.bitlockerStatusEnum.enums.find((x) => x.value === props.row.bitlockerStatus).key.replace(/([A-Z])/g, ' $1'))+" ")]),([
                        _vm.bitlockerStatusEnum.EncryptionInProgress.value,
                        _vm.bitlockerStatusEnum.DecryptionInProgress.value,
                      ].indexOf(props.row.bitlockerStatus) !== -1)?_c('span',[_vm._v(" "+_vm._s(props.row.encryptionPercentage)+"% ")]):_vm._e()]}}],null,false,3732767752)}),(_vm.diskPartitionsInStore.some(x => x.encryptionMethod
                    !== _vm.encryptionMethodEnum.Unknown.value.dbValue))?_c('b-table-column',{attrs:{"field":"encryptionMethod","label":"Encryption method"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.encryptionMethodEnum.enums.find(x => x.value.dbValue === props.row.encryptionMethod).value.name)+" ")]}}],null,false,342899006)}):_vm._e(),(_vm.diskPartitionsInStore.filter(x => x.letter !== null)
                    .some(x => x.bitlockerStatus !== _vm.bitlockerStatusEnum.FullyDecrypted.value
                      && x.protectionStatus !== null))?_c('b-table-column',{attrs:{"field":"protectionStatus","label":"Protection status"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.protectionStatus !== null ? _vm.protectionStatusEnum.enums.find( x => x.value.dbValue === props.row.protectionStatus).value.name : '')+" ")]}}],null,false,2379026066)}):_vm._e(),(_vm.diskPartitionsInStore.some(
                    x => x.autoUnlockStatus !== _vm.autoUnlockStatusEnum.Unknown.value.dbValue))?_c('b-table-column',{attrs:{"field":"autoUnlockStatus","label":"Auto-unlock"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.isBootPartition ? '' : _vm.autoUnlockStatusEnum.enums.find( x => x.value.dbValue === props.row.autoUnlockStatus).value.name)+" ")]}}],null,false,2199530481)}):_vm._e(),(_vm.validateIfHasPermission(
                    [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue,
                     _vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                  ))?_c('b-table-column',{attrs:{"field":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","gap":"4px"}},[(_vm.validateIfHasPermission(
                          [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                        ) &&[
                          _vm.bitlockerStatusEnum.EncryptionInProgress.value,
                          _vm.bitlockerStatusEnum.DecryptionInProgress.value,
                        ].indexOf(props.row.bitlockerStatus) !== -1)?_c('b-button',{staticClass:"button is-small is-primary",on:{"click":function($event){return _vm.pause(props.row)}}},[_vm._v(" Pause ")]):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                        ) && [
                          _vm.bitlockerStatusEnum.EncryptionSuspended.value,
                          _vm.bitlockerStatusEnum.DecryptionSuspended.value,
                        ].indexOf(props.row.bitlockerStatus) !== -1)?_c('b-button',{staticClass:"button is-small is-primary",on:{"click":function($event){return _vm.resume(props.row)}}},[_vm._v(" Resume ")]):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                        ) && props.row.protectionStatus === _vm.protectionStatusEnum.Unprotected.value
                          .dbValue && props.row.bitlockerStatus === _vm.bitlockerStatusEnum
                            .FullyEncrypted.value)?_c('b-button',{staticClass:"button is-small is-primary",on:{"click":function($event){return _vm.enableProtection(props.row)}}},[_vm._v(" Protect ")]):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                        ) && props.row.protectionStatus === _vm.protectionStatusEnum.Protected.value
                          .dbValue && props.row.bitlockerStatus === _vm.bitlockerStatusEnum
                            .FullyEncrypted.value)?_c('b-button',{staticClass:"button is-small is-primary",on:{"click":function($event){return _vm.disableProtection(props.row)}}},[_vm._v(" Unprotect ")]):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                        ) && [
                          _vm.bitlockerStatusEnum.FullyEncrypted.value,
                          _vm.bitlockerStatusEnum.EncryptionInProgress.value,
                          _vm.bitlockerStatusEnum.EncryptionSuspended.value,
                          _vm.bitlockerStatusEnum.Locked.value,
                          _vm.bitlockerStatusEnum.None.value,
                        ].indexOf(props.row.bitlockerStatus) === -1)?_c('b-tooltip',{attrs:{"active":props.row.isBootPartition && !_vm.bitLockerCanBeEnabled
                          .osEncryptable,"label":_vm.bitLockerCanBeEnabled.osErrorMessage,"position":"is-top","type":"is-dark"}},[_c('b-button',{staticClass:"is-primary is-small",attrs:{"disabled":props.row.isBootPartition
                            && !_vm.bitLockerCanBeEnabled.osEncryptable},on:{"click":function($event){return _vm.validateIfOSPartitionIsEncrypted(_vm.encrypt, props.row)}}},[_vm._v(" Encrypt ")])],1):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                        ) && [
                          _vm.bitlockerStatusEnum.FullyDecrypted.value,
                          _vm.bitlockerStatusEnum.DecryptionInProgress.value,
                          _vm.bitlockerStatusEnum.DecryptionSuspended.value,
                        ].indexOf(props.row.bitlockerStatus) === -1)?_c('b-button',{staticClass:"is-primary is-small",on:{"click":function($event){return _vm.decrypt(props.row)}}},[_vm._v(" Decrypt ")]):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                        ))?_c('b-button',{staticClass:"button is-secondary is-small",class:{'is-loading': _vm.isGettingBitlockerPasswordHistory
                          [props.row.letter]},attrs:{"icon-left":"eye"},on:{"click":function($event){return _vm.validateIfNeedReason('BitLocker password history',
                                                     _vm.getBitlockerPasswordHistory, props.row)}}},[_vm._v(" Reveal history ")]):_vm._e(),(_vm.validateBitlockerAutoUnlock(props.row, true))?_c('b-button',{staticClass:"is-primary is-small",on:{"click":function($event){return _vm.enableAutoUnlock(props.row)}}},[_vm._v(" Enable auto-unlock ")]):_vm._e(),(_vm.validateBitlockerAutoUnlock(props.row, false))?_c('b-button',{staticClass:"is-primary is-small",on:{"click":function($event){return _vm.disableAutoUnlock(props.row)}}},[_vm._v(" Disable auto-unlock ")]):_vm._e()],1)]}}],null,false,3053565229)}):_vm._e()],1)],1)],1):_vm._e()])])],1)],1),_c('b-modal',{attrs:{"slot":"footer","parent":this,"active":_vm.isBitlockerPasswordHistoryVisible,"has-modal-card":""},on:{"update:active":function($event){_vm.isBitlockerPasswordHistoryVisible=$event}},slot:"footer"},[_c('bitlockerPasswordHistory',{attrs:{"disk-partition":_vm.activeBitlockerPartition}})],1),_c('portal',{attrs:{"to":"modal-container"}},[_c('execution-modal',{attrs:{"component":_vm.LockDeviceParameters,"select-action":_vm.executeActionEnum.LOCK_DEVICE.value,"selected-devices":[_vm.device.id]},model:{value:(_vm.isExecuteModalActive),callback:function ($$v) {_vm.isExecuteModalActive=$$v},expression:"isExecuteModalActive"}})],1),_c('portal',{attrs:{"to":"lock-device-button"}},[_c('b-tooltip',{staticClass:"lock-tooltip",attrs:{"active":!_vm.bitLockerCanBeEnabled.osEncryptable,"label":_vm.bitLockerCanBeEnabled.messageLock,"position":"is-top","type":"is-dark"}},[(_vm.validateIfHasPermission(
          [_vm.permissions.GEOLOCATION_AND_LOCK_EXECUTIONS.value.dbValue]
        ))?_c('b-button',{staticClass:"mb-5 is-danger",attrs:{"disabled":!_vm.bitLockerCanBeEnabled.osEncryptable},on:{"click":_vm.confirmLockDevice}},[_vm._v(" Lock Device ")]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }