<template style="width: auto">
  <card
    internal-card="card modal-material-card modal-card bitlocker-modal"
    url-help=""
  >
    <div
      slot="header"
      class="modal-card-header"
    >
      <p>BitLocker password history on {{ diskPartition.letter }}</p>
    </div>
    <section
      slot="body"
      class="modal-card-body"
    >
      <div>
        <p class="mb-1">
          Filter by date
        </p>
        <b-datepicker
          v-model="datesRange"
          :max-date="new Date()"
          class="dateRange is-outlined dropdown"
          placeholder="Date range"
          icon="calendar-today"
          :mobile-native="false"
          range
        />
        <b-tooltip
          v-show="isActiveFilter"
          label="Reset filters"
          position="is-bottom"
          style="border-radius: 4px"
          class="ml-2"
        >
          <b-button
            type="is-primary"
            @click="clearFilters"
          >
            <b-icon
              icon="filter-off"
            />
          </b-button>
        </b-tooltip>
      </div>
      <b-table
        :data="items"
        :mobile-cards="false"
        backend-pagination
        default-sort="createdAt"
        default-sort-direction="desc"
        style="font-weight: 400"
        :loading="items.length === 0 && isFindServicePending"
        :total="totalData"
        :per-page="limitItems"
        :paginated="totalData > limitItems"
        @page-change="changePagination"
      >
        <b-table-column
          field="createdAt"
          label="Date"
          sortable
        >
          <template v-slot="props">
            {{ props.row.createdAt | moment }}
          </template>
        </b-table-column>

        <b-table-column
          field="lastDetection"
          label="Last detection"
          sortable
        >
          <template v-slot="props">
            {{ props.row.lastDetection ? props.row.lastDetection : props.row.createdAt | moment }}
          </template>
        </b-table-column>

        <b-table-column
          field="keyProtectorId"
          label="Key protector Id"
          sortable
        >
          <template v-slot="props">
            {{ props.row.keyProtectorId }}
          </template>
        </b-table-column>

        <b-table-column
          field="keyProtectorType"
          label="Key protector type"
          sortable
        >
          <template v-slot="props">
            {{ props.row.keyProtectorType }}
          </template>
        </b-table-column>

        <b-table-column
          field="recoveryPassword || password"
          label="Password"
          sortable
        >
          <template v-slot="props">
            <div style="">
              <span
                class="password-style material-card-content is-size-7-mobile is-rounded"
              >
                <span
                  v-for="(letter, index) in (props.row.recoveryPassword || props.row.password)"
                  :key="index"
                  :class="getClass(letter)"
                >
                  {{ letter }}
                </span>
              </span>
              <button
                v-clipboard:copy="props.row.recoveryPassword || props.row.password"
                class="button is-small is-primary is-outlined"
                @click="showToastCopy"
              >
                <span class="icon is-small">
                  <b-icon
                    icon="clipboard-outline"
                    size="is-small"
                  />
                </span>
                <span>Copy</span>
              </button>
            </div>
          </template>
        </b-table-column>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                  icon="file"
                  size="is-large"
                />
              </p>
              <p>There is no BitLocker password history</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer
      slot="footer"
      class="card-footer has-text-centered modal-card-footer"
    >
      <button
        class="button is-danger force-right"
        type="button"
        @click="$parent.close()"
      >
        Close
      </button>
    </footer>
  </card>
</template>

<script>
import moment from 'moment';
import { makeFindMixin } from 'feathers-vuex';
import card from '@/components/cross/Card.vue';
import toastMessage from '@/helpers/toastMessage';

export default {
  name: 'BitlockerPasswordHistory',
  components: {
    card,
  },
  mixins: [makeFindMixin({
    service() {
      return 'bitlocker-passwords';
    },
    name: 'service',
    items: 'items',
    watch: true,
  })],
  props: {
    diskPartition: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      datesRange: [],
      filteredPasswords: [],
      limitItems: 10,
      skipItems: 0,
      currentPage: 1,
    };
  },
  computed: {
    isActiveFilter() {
      return this.datesRange.length >= 2;
    },
    currentPaginationQuery() {
      return {
        $limit: this.limitItems,
        $skip: this.skipItems,
      };
    },
    serviceQuery() {
      const query = {
        $sort: { createdAt: -1 },
        companyId: this.$route.params.companyId,
        deviceId: this.$route.params.deviceId,
        diskPartitionId: this.diskPartition.id,
      };

      if (this.isActiveFilter) {
        query.createdAt = {
          $gte: moment(this.datesRange[0]).startOf('day'),
          $lte: moment(this.datesRange[1]).endOf('day'),
        };
      }
      return query;
    },
    serviceParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.serviceQuery,
        },
      };
    },
    totalData() {
      if (!this.serviceLatestQuery || !this.servicePaginationData.default
          || !this.servicePaginationData.default[this.serviceLatestQuery.queryId]) {
        return 0;
      }
      const { queryId } = this.serviceLatestQuery;
      const { total } = this.servicePaginationData.default[queryId];
      const maximumPage = Math.ceil(total / this.limitItems);
      if (maximumPage !== 0 && this.currentPage > maximumPage) {
        this.changePagination(maximumPage);
      }

      return total;
    },
  },
  methods: {
    clearFilters() {
      this.datesRange = [];
    },
    changePagination(page) {
      this.currentPage = page;
      this.skipItems = (page - 1) * this.limitItems;
    },
    getClass(letter) {
      if ('0123456789'.includes(letter)) {
        return 'number-char';
      }
      if ('~!@#$%^&*_-+=`|(){}[]:;"\'<>,.?/'.includes(letter)) {
        return 'special-char';
      }
      return 'text-char';
    },
    showToastCopy() {
      toastMessage.showSuccess('Password copied to clipboard');
    },
  },
};
</script>

<style>
  .bitlocker-modal{
    width: initial;
  }
</style>

<style scoped>
.number-char {
  color: #0572EC;
  display:inline-block;
  width: 8px;
}
.special-char {
  color: #E60000;
  display:inline-block;
  width: 8px;
}
.text-char {
  display:inline-block;
  width: 8px;
}
</style>
