<template>
  <div
    v-if="bootOrder.length > 0"
    class="mb-3"
  >
    <card external-card="material-card">
      <b-collapse
        slot="external-header"
        :open="false"
        class="card"
      >
        <card
          slot="trigger"
          slot-scope="props"
          internal-card="card-header"
          style-internal-header="display:flex; width:100%"
          style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
        >
          <p
            slot="header"
            class="card-header-title header-title"
          >
            <span>
              Boot order
            </span>
          </p>
          <a
            slot="body"
            class="card-header-icon"
          >
            <b-icon
              v-show="props.open"
              icon="menu-up"
            />
            <b-icon
              v-show="!props.open"
              icon="menu-down"
            />
          </a>
        </card>
        <card
          internal-card="material-card-content"
        >
          <div
            slot="header"
            style="width:100%"
          >
            <div
              class="columns"
            >
              <div
                v-for="boot in bootOrder"
                :key="boot.name"
                class="column"
              >
                <p style="font-weight:bold;">
                  {{ boot.name }}
                </p>
                <b-table
                  :data="boot.devices"
                  :mobile-cards="false"
                >
                  <b-table-column
                    field="item"
                    label="Order"
                  >
                    <template v-slot="props">
                      {{ props.index + 1 }}
                    </template>
                  </b-table-column>
                  <b-table-column
                    field="device"
                    label="Device"
                  >
                    <template v-slot="props">
                      {{ props.row }}
                    </template>
                  </b-table-column>

                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon
                            icon="file"
                            size="is-large"
                          />
                        </p>
                        <p>All devices disabled</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </card>
      </b-collapse>
    </card>
  </div>
</template>

<script>
import validationsMixin from '@/mixins/validations';
import card from '@/components/cross/Card.vue';

export default {
  name: 'BootOrder',
  components: {
    card,
  },
  mixins: [validationsMixin],
  props: {
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    bootOrder() {
      let names = [];
      if (this.isLenovo(this.device.vendor)) {
        names = [
          'Primary boot order',
          'Automatic boot order',
          'Error boot order',
        ];
      } else if (this.isHewlettPackard(this.device.vendor)) {
        names = [
          'UEFI/EFI boot order',
          'BIOS (Legacy) boot order',
          'Error boot order',
        ];
      } else if (this.isDell(this.device.vendor)) {
        names = [
          '',
          'BIOS (Legacy) boot order',
          'UEFI/EFI boot order',
        ];
      }

      const finalBootOrder = [];
      if (this.device.firstBootOrderSetting) {
        const configured = this.device.firstBootOrderSetting.split(';');
        finalBootOrder.push({
          name: names[0],
          devices: configured[0].split('|'),
        });
      }
      if (this.device.secondBootOrderSetting) {
        const configured = this.device.secondBootOrderSetting.split(';');
        finalBootOrder.push({
          name: names[1],
          devices: configured[0].split('|'),
        });
      }
      if (this.device.thirdBootOrderSetting) {
        const configured = this.device.thirdBootOrderSetting.split(';');
        finalBootOrder.push({
          name: names[2],
          devices: configured[0].split('|'),
        });
      }
      return finalBootOrder;
    },
  },
};

</script>
<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
