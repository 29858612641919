<template>
  <div>
    <card
      slot="external-body"
      external-card="card"
      style-internal-card="flex:auto"
      internal-card="material-card material-card-content"
    >
      <h1
        slot="header"
        class="subtitle"
      >
        Intel® AMT Logs
      </h1>

      <div
        slot="body"
        style="display: flex"
      >
        <b-table
          :data="amtLogs"
          :paginated="amtLogs.length > maxQuantity"
          :per-page="maxQuantity"
          hoverable
          :mobile-cards="false"
          pagination-simple
          default-sort="time"
          default-sort-direction="desc"
          style="width: 100%"
        >
          <b-table-column
            field="initiator"
            label="Initiator"
            sortable
          >
            <template v-slot="props">
              {{ props.row.initiator }}
            </template>
          </b-table-column>

          <b-table-column
            field="address"
            label="Address"
            sortable
          >
            <template v-slot="props">
              {{ props.row.address }}
            </template>
          </b-table-column>

          <b-table-column
            field="type"
            label="Type"
            sortable
          >
            <template v-slot="props">
              {{ props.row.type }}
            </template>
          </b-table-column>

          <b-table-column
            field="event"
            label="Event"
            sortable
          >
            <template v-slot="props">
              {{ props.row.event }}
            </template>
          </b-table-column>

          <b-table-column
            field="details"
            label="Details"
            sortable
          >
            <template v-slot="props">
              {{ props.row.details }}
            </template>
          </b-table-column>

          <b-table-column
            field="time"
            label="Date and Time"
            sortable
          >
            <template v-slot="props">
              <div>
                <timeago
                  :key="props.row.id"
                  :since="props.row.time "
                  no-future
                />
              </div>
            </template>
          </b-table-column>
        </b-table>
        <learn-more url="" />
      </div>
      <template>
        <div
          slot="body"
        >
          <div
            v-if="amtLogs.length > 0"
            style="margin-top: 15px"
          />
          <div
            v-else
          >
            <span class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="file"
                    size="is-large"
                  />
                </p>
                <p>No AMT Logs Found.</p>
              </div>
            </span>
          </div>
          <div
            class="centerButton"
          >
            <button
              :class="{ 'button': true, 'is-primary': true }"
              style="margin-bottom: 15px;"
              @click="fetchAMTLogs()"
            >
              <b-icon icon="database-refresh" />
              <span>Refresh</span>
            </button>
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import snackBarMessage from '@/helpers/snackBarMessage';
import groupsExecution from '@/mixins/executions';
import ErrorMixin from '@/mixins/error';
import learnMore from '@/components/cross/LearnMore.vue';
import card from '@/components/cross/Card.vue';
import vtulEnums from '../../../../cross/index';

export default {
  name: 'DeviceAmtLogs',
  components: {
    card,
    learnMore,
  },
  mixins: [groupsExecution, ErrorMixin],
  props: {
    deviceId: {
      type: String,
      required: true,
      default: '',
    },
    maxQuantity: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    const executionEnums = vtulEnums.enum.execution.executeAction;
    return {
      total: 0,
      skip: 0,
      fetchAMTLogsAction:
        executionEnums.enums.find((x) => x === executionEnums.UPDATE_AMT_LOGS),
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('amt-log', { findAmtLogInStore: 'find' }),
    amtLogs() {
      if (!this.deviceId) {
        return [];
      }
      const hList = this.findAmtLogInStore({
        query: {
          deviceId: this.deviceId,
        },
      });
      return hList && hList.data ? hList.data : [];
    },
  },
  watch: {
    deviceId() {
      this.fetchApi();
    },
  },
  created() {
    this.fetchApi();
  },
  methods: {
    ...mapActions('amt-log', { findAmtLog: 'find' }),
    ...mapActions('execution-groups', { createExecutionGroup: 'create' }),
    async fetchApi() {
      if (!this.currentCompany) return;
      const { total, data } = await this.findAmtLog({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.deviceId,
          $skip: this.skip,
        },
      });
      this.total = total;
      this.skip += data.length;
      if (this.skip < total) {
        this.fetchApi();
      }
    },
    async fetchAMTLogs() {
      snackBarMessage.showSuccess('We are updating Intel® AMT Logs.');
      this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
        [this.deviceId], this.fetchAMTLogsAction.value.id);
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
.alignButtonRight {
  display: flex;
  margin-left: auto
}
.centerButton {
  display: flex;
  justify-content: center;
}
</style>
