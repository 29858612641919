var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-desktop"},[_c('card',{staticClass:"column",attrs:{"internal-card":"card material-card","url-help":""}},[_c('section',{staticClass:"modal-card-body",attrs:{"slot":"header"},slot:"header"},[_c('p',[_c('strong',[_vm._v("Manufacturer: ")]),_vm._v(" "+_vm._s(_vm.device.vendor))]),_c('p',[(_vm.device.model)?_c('strong',[_vm._v("Model: ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.device.model))]),(_vm.device.type !== _vm.deviceTypesEnum.UNKNOWN.value.dbValue)?_c('p',[_c('strong',[_vm._v("Type: ")]),_vm._v(" "+_vm._s(_vm.deviceTypesEnum.enums.find(x => x.value.dbValue === _vm.device.type).value.name)+" ")]):_vm._e(),_c('p',[_c('strong',[_vm._v("Serial: ")]),_vm._v(" "+_vm._s(_vm.device.hardwareSerial))]),_c('p',[_c('strong',[_vm._v("Agent version: ")]),_c('span',[_vm._v(_vm._s(_vm.device.agentVersion)+" ")]),(_vm.agentLastVersion !== '0.0.0' && _vm.deviceGroup.agentVersion)?_c('span',[(_vm.currentCompany.planCapabilities.includes(_vm.planCapabilitiesEnum
                .FIXED_AGENT_VERSION.value))?_c('span',[(_vm.semverUi.compare(_vm.agentLastVersion, _vm.device.agentVersion) > 0
                  && _vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue)?_c('a',{staticClass:"is-text link-style",on:{"click":function($event){return _vm.updateAgent({
                  companyId: _vm.currentCompany.id,
                  deviceId: _vm.device.id,
                  userId: _vm.$store.getters['auth/user'].id,
                })}}},[_vm._v(" Update now ")]):_c('a',{staticClass:"is-text link-style",on:{"click":function($event){return _vm.crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);}}},[_vm._v(" "+_vm._s(0 >= _vm.semverUi.compare(_vm.agentLastVersion, _vm.device.agentVersion) ? 'Latest' : 'Outdated')+" ")])]):_c('span',[(_vm.deviceGroup.agentVersion === '0')?_c('span',[(_vm.semverUi.compare(_vm.agentLastVersion, _vm.device.agentVersion) > 0
                    && _vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue)?_c('a',{staticClass:"is-text link-style",on:{"click":function($event){return _vm.updateAgent({
                    companyId: _vm.currentCompany.id,
                    deviceId: _vm.device.id,
                    userId: _vm.$store.getters['auth/user'].id,
                  })}}},[_vm._v(" Update now ")]):_c('a',{staticClass:"is-text link-style",on:{"click":function($event){return _vm.crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);}}},[_vm._v(" "+_vm._s(0 >= _vm.semverUi.compare(_vm.agentLastVersion, _vm.device.agentVersion) ? 'Latest' : 'Outdated')+" ")])]):_c('span',[(_vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue)?_c('a',{staticClass:"is-text link-style",on:{"click":function($event){return _vm.updateAgent({
                    companyId: _vm.currentCompany.id,
                    deviceId: _vm.device.id,
                    userId: _vm.$store.getters['auth/user'].id,
                  })}}},[_vm._v(" "+_vm._s(0 >= _vm.semverUi.compare(_vm.deviceGroup.agentVersion, _vm.device.agentVersion) ? 'Latest' : 'Update now')+" ")]):_c('a',{staticClass:"is-text link-style",on:{"click":function($event){return _vm.crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);}}},[_vm._v(" "+_vm._s(0 >= _vm.semverUi.compare(_vm.deviceGroup.agentVersion, _vm.device.agentVersion) ? 'Latest' : 'Outdated')+" ")])])])]):_vm._e()]),_c('p',{staticStyle:{"display":"inline-block"}},[_c('strong',[_vm._v("Agent: ")]),_c('DeviceStatus',{attrs:{"status":_vm.getAgentColorStatus(_vm.device.status)}}),(_vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue)?_c('span',[_vm._v(" Connected ")]):_c('span',[_vm._v(" Disconnected "),_c('timeago',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.lastSeen),expression:"device.lastSeen"}],attrs:{"since":_vm.device.lastSeen,"no-future":"","no-date":""}})],1)],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue
            || _vm.device.lastSeen),expression:"device.status === deviceStatusEnum.ONLINE.value.dbValue\n            || device.lastSeen"}]},[_c('strong',[_vm._v("Agent last seen date: ")]),(_vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue)?_c('span',[_vm._v(" Right Now ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.device.lastSeen))+" ")])]),(_vm.validateIfHasCapability([_vm.planCapabilitiesEnum.AMT_MANAGEMENT.value]))?_c('p',{staticStyle:{"display":"inline-block"}},[_c('strong',[_vm._v("AMT: ")]),(_vm.device.OSVendor === 'Apple' || !_vm.device.amtVersion
              || _vm.device.amtVersion === '0')?_c('span',[_c('DeviceStatus',{attrs:{"status":_vm.deviceStatusColorEnum.GRAY}}),_vm._v(" Not supported ")],1):(_vm.amtInfo)?_c('span',[_c('DeviceStatus',{attrs:{"status":_vm.getAMTColorStatus(_vm.amtInfo.amtStatus)}}),_c('span',[_vm._v(" "+_vm._s(_vm.amtStatusEnum.enums.find(x => x.value.dbValue === _vm.amtInfo.amtStatus) .value.name)+" ")]),(_vm.amtInfo
                && _vm.amtInfo.amtLastSeen
                && _vm.amtInfo.amtStatus === _vm.amtStatusEnum.OFFLINE.value.dbValue)?_c('timeago',{attrs:{"since":_vm.amtInfo.amtLastSeen,"no-future":"","no-date":""}}):_vm._e()],1):_c('span',[_c('DeviceStatus',{attrs:{"status":_vm.deviceStatusColorEnum.BLUE}}),_vm._v(" Not provisioned ")],1)]):_vm._e(),(_vm.validateIfHasCapability([_vm.planCapabilitiesEnum.AMT_MANAGEMENT.value])
            && _vm.amtInfo && _vm.amtInfo.amtLastSeen
            && _vm.amtInfo.amtStatus !== _vm.amtStatusEnum.CIRA_NOT_SUPPORTED.value.dbValue
            && _vm.amtInfo.amtStatus !== _vm.amtStatusEnum.UNCONFIGURED.value.dbValue)?_c('p',[_c('strong',[_vm._v("AMT Last seen date:")]),(_vm.amtInfo.amtStatus === _vm.amtStatusEnum.ONLINE.value.dbValue ||
              _vm.amtInfo.amtStatus === _vm.amtStatusEnum.CONNECTION_ERROR.value.dbValue)?_c('span',[_vm._v(" Right Now ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.amtInfo.amtLastSeen))+" ")])]):_vm._e(),_c('p',[_c('strong',[_vm._v("Managed since: ")]),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.device.createdAt,false)))])]),(_vm.device.status === _vm.deviceStatusEnum.ONLINE.value.dbValue)?_c('p',[_c('strong',[_vm._v("Uptime: ")]),_c('span',[(_vm.device.uptime)?_c('timeago',{attrs:{"since":_vm.device.uptime,"interval":1000,"format":"HH:mm:ss","no-future":"","no-date":""}}):_vm._e()],1)]):_vm._e()])]),_c('card',{staticClass:"column",attrs:{"internal-card":"card material-card","url-help":""}},[_c('section',{staticClass:"modal-card-body",staticStyle:{"padding-top":"15px"},attrs:{"slot":"header"},slot:"header"},[_c('b-field',{attrs:{"label":"Device group"}},[_c('searchable-dropdown',{staticClass:"group-dropdown",attrs:{"dropdown-properties":{
              id: 'deviceGroup',
              filterKey: 'id',
              propertyToShow: 'name',
              label: _vm.deviceGroup.name,
              showStatus: false,
              icon: {
                showIcon: false,
              },
              canSelectMultiple: false,
              customSort: false,
              expanded: true,
              markAsActive: false,
            },"filter-objects":_vm.deviceGroups,"on-update-parameters":_vm.changeAssociatedDeviceGroup}})],1),_c('b-field',{attrs:{"label":"Description"}},[_c('b-input',{attrs:{"placeholder":"Add a description to this device","expanded":""},nativeOn:{"change":function($event){return _vm.saveDescription.apply(null, arguments)}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-field',{attrs:{"label":"Tags"}},[_c('b-taginput',{attrs:{"value":_vm.tagData,"add":_vm.saveTags,"ellipsis":"","icon":"label","placeholder":"Add tags to classify devices","expanded":""},on:{"input":_vm.LowerCaseTags}})],1)],1)])],1),_c('div',{staticClass:"is-flex",staticStyle:{"justify-content":"space-around"},attrs:{"slot":"body"},slot:"body"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRemoveButton),expression:"!showRemoveButton"}],attrs:{"size":"is-small","icon-left":"delete","type":"is-danger"},on:{"click":_vm.openRemoveDeviceModal}},[_vm._v(" Remove this device ")])],1),_c('b-loading',{attrs:{"is-full-page":"","active":_vm.isRemovingDevice,"can-cancel":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }