<template>
  <div>
    <portal to="rd-options">
      <b-dropdown
        v-model="remoteDesktopType"
        style="margin-right: 10px"
        aria-role="list"
      >
        <template #trigger="{ active }">
          <b-button
            :label="remoteDesktopType.shortName"
            type="is-primary is-light"
            :icon-left="remoteDesktopType.icon"
            :icon-right="active ? 'menu-up' : 'menu-down'"
          />
        </template>
        <b-dropdown-item
          v-for="item in desktopOptions"
          :key="item.value"
          :value="item"
          :disabled="item.disabled"
          aria-role="listitem"
          style="text-align: left"
        >
          <b-icon
            :icon="item.icon"
            style="vertical-align: middle;"
          />
          <span style="vertical-align: middle;">&nbsp;Connect to {{ item.name }} </span>
        </b-dropdown-item>
      </b-dropdown>
    </portal>
    <device-WRTC
      v-if="remoteDesktopType.value === 0"
      :key="`wrtc-${device.id}`"
      ref="deviceWRTC"
      :device="device"
    />
    <device-KVM
      v-else-if="remoteDesktopType.value === 1"
      :key="`kvm-${device.id}`"
      ref="deviceKVM"
      :amt-info="amtInfo"
      :device-id="device.id"
    />
    <not-authorized-for-AMT-actions
      v-else
      :current-company="currentCompany"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import deviceKVM from './DeviceKVM.vue';
import deviceWRTC from './DeviceWRTC.vue';
import notAuthorizedForAMTActions from '../errors/NotAuthorizedForAMTActions.vue';
import vtulEnums from '../../../../cross/index';

export default {
  components: {
    'device-KVM': deviceKVM,
    'device-WRTC': deviceWRTC,
    'not-authorized-for-AMT-actions': notAuthorizedForAMTActions,
  },
  props: {
    amtInfo: {
      type: Object,
      default() {
        return { };
      },
    },
    device: {
      type: Object,
      default() {
        return { };
      },
    },
  },
  data() {
    const desktopOptions = [
      {
        name: 'Boardgent Agent',
        shortName: 'Agent',
        value: 0,
        disabled: false,
        icon: 'microsoft-windows',
      },
      {
        name: 'Intel® vPro AMT KVM',
        shortName: 'KVM',
        value: 1,
        disabled: false,
        icon: 'chip',
      },
    ];

    return {
      amtStatus: vtulEnums.enum.amt.amtStatus,
      deviceStatus: vtulEnums.enum.device.deviceStatus,
      remoteDesktopType: desktopOptions.find((x) => x.value === 0),
      desktopOptions,
      advancedOptionsOpen: false,
      wrtcConnectionStarted: undefined,
      kvmConnectionStarted: undefined,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
  },
  watch: {
    amtInfo: {
      handler(amtInfo) {
        if (this.$refs.deviceKVM && amtInfo
          && amtInfo.amtStatus === this.amtStatus.OFFLINE.value.dbValue) {
          this.$refs.deviceKVM.disconnectDesktop();
        }
        this.setBestConnectionType();
      },
      deep: true,
    },
    device: {
      handler(newDevice, oldDevice) {
        if (oldDevice.id !== newDevice.id) {
          this.forceDisconnectAll();
        } else if (this.$refs.deviceWRTC
          && newDevice.status === this.deviceStatus.OFFLINE.value.dbValue) {
          this.$refs.deviceWRTC.disconnectDesktop();
        }
        this.setBestConnectionType();
      },
      deep: true,
    },
    remoteDesktopType() {
      this.forceDisconnectAll();
    },
  },
  created() {
    this.setBestConnectionType();
    window.addEventListener('beforeunload', this.forceDisconnectAll);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.forceDisconnectAll);
  },
  methods: {
    ...mapActions('stripe-company-information', { findStripeCustomerInfo: 'find' }),

    setBestConnectionType() {
      this.wrtcConnectionStarted = this.$refs.deviceWRTC
        && (this.$refs.deviceWRTC.desktopState.connected
          || this.$refs.deviceWRTC.desktopState.connecting);
      this.kvmConnectionStarted = this.$refs.deviceKVM
        && (this.$refs.deviceKVM.desktopState.connected
          || this.$refs.deviceKVM.desktopState.connecting);

      const deviceIndex = this.desktopOptions.findIndex((x) => x.value === 0);
      const amtIndex = this.desktopOptions.findIndex((x) => x.value === 1);
      if (this.device && this.device.status === this.deviceStatus.ONLINE.value.dbValue
        && (this.device.osArch === 'x64' || this.device.OS.includes('Windows'))) {
        this.desktopOptions[deviceIndex].disabled = false;
        if (!this.kvmConnectionStarted) {
          this.remoteDesktopType = this.desktopOptions[deviceIndex];
        }
      } else {
        this.desktopOptions[deviceIndex].disabled = true;
        if (!this.wrtcConnectionStarted) {
          this.remoteDesktopType = this.desktopOptions[amtIndex];
        }
      }

      if (this.amtInfo && this.amtInfo.amtStatus === this.amtStatus.ONLINE.value.dbValue) {
        this.desktopOptions[amtIndex].disabled = false;
      } else {
        this.desktopOptions[amtIndex].disabled = true;
      }
    },
    forceDisconnectAll() {
      if (this.$refs.deviceKVM) {
        this.$refs.deviceKVM.disconnectDesktop();
      }
      if (this.$refs.deviceWRTC) {
        this.$refs.deviceWRTC.disconnectDesktop();
      }
    },
  },
};
</script>
