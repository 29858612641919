var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.validateIfHasCapability([_vm.planCapabilities.DISK_ENCRYPTION.value]))?_c('div',{staticClass:"mb-3"},[_c('card',{attrs:{"external-card":"material-card-overflow"}},[_c('b-collapse',{attrs:{"slot":"external-header","open":false},on:{"open":function($event){_vm.isCardOpen = true},"close":function($event){_vm.isCardOpen = false}},slot:"external-header",scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('card',{attrs:{"internal-card":_vm.isCardOpen ? 'card-header' : '',"style-internal-header":"display:flex; width:100%","style-internal-card":"display:flex;flex-direction:row;justify-content:space-between"}},[_c('p',{staticClass:"card-header-title header-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" FileVault Device Encryption ")]),_c('a',{staticClass:"card-header-icon",attrs:{"slot":"body"},slot:"body"},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(props.open),expression:"props.open"}],attrs:{"icon":"menu-up"}}),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!props.open),expression:"!props.open"}],attrs:{"icon":"menu-down"}})],1)])}}],null,false,1476051588)},[_c('card',{attrs:{"internal-card":"material-card-content"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',[(_vm.fileVaultError)?_c('b-message',{attrs:{"type":_vm.fileVaultError.type}},[_vm._v(" "+_vm._s(_vm.fileVaultError.message)+" ")]):_vm._e(),_c('b-table',{staticClass:"principal-table bitlocker-table",staticStyle:{"margin-top":"1rem","width":"100%"},attrs:{"slot":"body","data":_vm.diskPartitionsInStore.filter(partition => partition.isSystemPartition),"detailed":_vm.diskPartitionsInStore.filter(partition => partition.isSystemPartition)
                [0].fileVaultStatus !== _vm.fileVaultStatusEnum.FULLY_DECRYPTED.value.dbValue
                && !!_vm.currentFileVaultPassword,"scrollable":"","mobile-cards":false},slot:"body"},[_c('b-table-column',{attrs:{"field":"volumeName","label":"Volume"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.volumeName)+" ")]}}],null,false,1082116818)}),_c('b-table-column',{attrs:{"field":"fileVaultStatus","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(!props.row.fileVaultStatus ? '' : _vm.fileVaultStatusEnum.enums.find( x => x.value.dbValue === props.row.fileVaultStatus).value.name)+" ")]}}],null,false,526697654)}),_c('b-table-column',{attrs:{"field":"encryptionManaged","label":"Encryption managed"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.encryptionManaged ? 'Yes' : 'No')+" ")]}}],null,false,2058103900)}),_c('b-table-column',{attrs:{"field":"actions","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","gap":"4px"}},[_c('b-button',{staticClass:"button is-secondary is-small",class:{'is-loading': true
                        [props.row.letter]},attrs:{"icon-left":"eye"},on:{"click":function($event){return _vm.validateIfNeedReason('FileVault password history',
                                                   _vm.getFileVaultKeyHistory, props.row)}}},[_vm._v(" Reveal history ")])],1)]}}],null,false,1813457930)}),_c('template',{slot:"detail"},[_c('b-table',{staticClass:"inner-table",attrs:{"data":[_vm.currentFileVaultPassword],"mobile-cards":false,"scrollable":""}},[_c('b-table-column',{attrs:{"field":"recoveryKey","label":"Recovery Key","max-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [(_vm.fileVaultKey[propsDetails.row.id])?_c('span',{staticClass:"password-style material-card-content is-size-7-mobile is-rounded"},_vm._l(((propsDetails.row.recoveryKey)),function(letter,index){return _c('span',{key:index,class:`${_vm.getClass(letter)} mb-2`},[_vm._v(" "+_vm._s(letter)+" ")])}),0):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                        ) && _vm.fileVaultKey[propsDetails.row.id])?_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.fileVaultKey[propsDetails.row.id]),expression:"fileVaultKey[propsDetails.row.id]",arg:"copy"}],staticClass:"button is-small is-primary is-outlined",attrs:{"icon-left":"clipboard-outline"},on:{"click":_vm.showToastCopy}},[_vm._v(" Copy ")]):_vm._e(),(_vm.validateIfHasPermission(
                          [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                        ) && !_vm.fileVaultKey[propsDetails.row.id])?_c('b-button',{staticClass:"is-primary is-small",class:{'is-loading': _vm.isGettingfileVaultKey[propsDetails.row.id] },attrs:{"icon-left":"eye"},on:{"click":function($event){return _vm.validateIfNeedReason('FileVault recovery key',
                                                     _vm.getFileVaultKey, propsDetails.row)}}},[_vm._v(" Reveal ")]):(_vm.validateIfHasPermission(
                          [_vm.permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                        ) && _vm.fileVaultKey[propsDetails.row.id])?_c('b-button',{staticClass:"button is-small is-primary ml-1",attrs:{"icon-left":"eye-off"},on:{"click":function($event){return _vm.getFileVaultKey(propsDetails.row)}}},[_vm._v(" Conceal ")]):_c('span',[_vm._v("********")])]}}],null,false,189903612)}),_c('b-table-column',{attrs:{"field":"enabledUser","label":"Enabled user"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(propsDetails.row.enabledUser)+" ")]}}],null,false,4150644521)}),_c('b-table-column',{attrs:{"field":"enabledDate","label":"Activation date"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(_vm._f("moment")(propsDetails.row.enabledDate))+" ")]}}],null,false,2099093573)}),(_vm.displayInvalidRecoveryKeyDate)?_c('b-table-column',{attrs:{"field":"invalidRecoveryKeyTimeStamp","label":"Detected invalid since"},scopedSlots:_vm._u([{key:"default",fn:function(propsDetails){return [_vm._v(" "+_vm._s(_vm._f("moment")(propsDetails.row.invalidRecoveryKeyTimeStamp))+" ")]}}],null,false,2969199263)}):_vm._e()],1)],1)],2)],1)])])],1)],1),_c('b-modal',{attrs:{"slot":"footer","parent":this,"active":_vm.isFileVaultKeyHistoryVisible,"has-modal-card":""},on:{"update:active":function($event){_vm.isFileVaultKeyHistoryVisible=$event}},slot:"footer"},[_c('fileVaultKeyHistory',{attrs:{"disk-partition":_vm.activeFileVaultPartition}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }