<template>
  <div>
    <fullscreen v-model="fullscreen">
      <card
        external-card="card material-card-overflow"
      >
        <div
          slot="external-footer"
          class="material-card-content has-margin-top"
          :style="remoteContainerStyle"
        >
          <b-navbar :style="remoteDesktopNavbarsStyle">
            <template #start>
              <b-navbar-item tag="div">
                <div class="buttons">
                  <portal-target name="rd-options" />
                  <b-button
                    :id="desktopState.connected ? 'wrtc-disconnect' : 'wrtc-connect'"
                    type="is-primary"
                    :disabled="desktopState.connecting || !device.permissionsStatus
                      || !device.permissionsStatus.capturePermission"
                    @click="desktopState.connected ? disconnectDesktop() : connectDesktop()"
                  >
                    <strong>{{ desktopState.buttonText }}</strong>
                  </b-button>
                </div>
              </b-navbar-item>
              <b-navbar-item
                v-show="screenDisplayed"
                tag="div"
              >
                <div class="buttons">
                  <b-tooltip
                    label="File tansfer"
                    type="is-dark"
                    position="is-bottom"
                  >
                    <b-button
                      type="is-white"
                      @click="openTransferFileModal()"
                    >
                      <b-icon
                        icon="file-swap"
                      />
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    label="Paste local clipboard"
                    type="is-dark"
                    position="is-bottom"
                  >
                    <b-button
                      type="is-white"
                      @click="sendLocalClipboard()"
                    >
                      <b-icon
                        icon="content-paste"
                      />
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    v-show="isWindows"
                    label="Ctrl+Alt+Del"
                    type="is-dark"
                    position="is-bottom"
                  >
                    <b-button
                      type="is-white"
                      @click="sendcad()"
                    >
                      <b-icon
                        icon="keyboard"
                      />
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    v-show="isWindows"
                    label="Ctrl+L (Lock PC)"
                    type="is-dark"
                    position="is-bottom"
                  >
                    <b-button
                      type="is-white"
                      @click="sendLock()"
                    >
                      <b-icon
                        icon="account-lock"
                      />
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    label="Take screenshot"
                    type="is-dark"
                    position="is-bottom"
                  >
                    <b-button
                      type="is-white"
                      @click="takeScreenshot()"
                    >
                      <b-icon
                        icon="camera"
                      />
                    </b-button>
                  </b-tooltip>
                  <b-tooltip
                    :label="isRecording ? 'Stop recording screen' : 'Record screen'"
                    type="is-dark"
                    position="is-bottom"
                  >
                    <b-button
                      :type="isRecording ? 'is-primary' : 'is-white'"
                      @click="isRecording ? stopRecording() : startRecording()"
                    >
                      <b-icon icon="record-rec" />
                    </b-button>
                  </b-tooltip>
                </div>
              </b-navbar-item>
              <b-navbar-item v-show="screenDisplayed && device.osArch === 'x64' && isWindows">
                <b-tooltip
                  :label="audioCallLabel"
                  type="is-dark"
                  position="is-bottom"
                >
                  <b-button
                    :id="mediaStream ? 'audio-call-disconnect' : 'audio-call-connect'"
                    :type="mediaStream ? 'is-primary' : 'is-white'"
                    :disabled="blockStartAudioCall"
                    @click="mediaStream ? removeAudioCall() : addAudioCall()"
                  >
                    <b-icon icon="phone-in-talk" />
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  v-if="mediaStream"
                  :label="isMuted ? 'Unmute' : 'Mute'"
                  type="is-dark"
                  position="is-bottom"
                >
                  <b-button
                    type="is-white"
                    :disabled="!mediaStream"
                    @click="isMuted
                      ? toggleMicInAudioCall(true) : toggleMicInAudioCall(false)"
                  >
                    <b-icon
                      v-if="isMuted"
                      icon="microphone-off"
                    />
                    <b-icon
                      v-else
                      icon="microphone"
                    />
                  </b-button>
                </b-tooltip>
              </b-navbar-item>
            </template>
            <template #end>
              <b-navbar-item tag="div">
                <b-navbar-dropdown v-show="screenDisplayed">
                  <template #label>
                    <b-icon icon="monitor" />
                    <span>&nbsp;{{ useLongNavbarVersion ?
                      currentScreen.name : currentScreen.screenNumber }}</span>
                  </template>
                  <b-navbar-item
                    v-for="screen in screens"
                    :key="screen.screenNumber"
                    :active="screen.screenNumber === currentScreen.screenNumber"
                    @click="changeScreen(screen)"
                  >
                    <b-icon icon="monitor" />
                    <span>&nbsp;{{ screen.name }}</span>
                  </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-dropdown>
                  <template #label>
                    <b-icon :icon="currentPossibleResolution.icon" />
                    <span
                      v-if="useLongNavbarVersion"
                    >{{ currentPossibleResolution.name }}</span>
                  </template>
                  <b-navbar-item
                    v-for="possibleResolution in possibleResolutions"
                    :key="possibleResolution.pixels"
                    :active="possibleResolution.pixels === currentPossibleResolution.pixels"
                    @click="changeResolution(possibleResolution)"
                  >
                    <b-icon
                      :icon="possibleResolution.icon"
                    />
                    <span>{{ possibleResolution.name }}</span>
                  </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-dropdown>
                  <template #label>
                    <b-icon :icon="currentFps.icon" />
                    <span
                      v-if="useLongNavbarVersion"
                    >{{ currentFps.name }}</span>
                  </template>
                  <b-navbar-item
                    v-for="fps in possibleFps"
                    :key="fps.fps"
                    :active="fps.fps === currentFps.fps"
                    @click="changeFPS(fps)"
                  >
                    <b-icon
                      :icon="fps.icon"
                    />
                    <span>{{ fps.name }}</span>
                  </b-navbar-item>
                </b-navbar-dropdown>
              </b-navbar-item>
              <b-navbar-item tag="div">
                <b-tooltip
                  :label="captureMouse ? 'Hide remote cursor' :
                    'Show remote cursor'"
                  type="is-dark"
                  position="is-bottom"
                >
                  <b-button
                    type="is-white"
                    @click="captureMouse ? changeCaptureMouse(false) : changeCaptureMouse(true)"
                  >
                    <b-icon
                      v-if="captureMouse"
                      icon="cursor-default-gesture"
                    />
                    <b-icon
                      v-else
                      icon="cursor-default-gesture-outline"
                    />
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  :label="isInReadOnlyMode ? 'Change to interactive mode' :
                    'Change to read-only mode'"
                  type="is-dark"
                  position="is-bottom"
                >
                  <b-button
                    type="is-white"
                    @click="isInReadOnlyMode ? changeIsReadOnly(false) : changeIsReadOnly(true)"
                  >
                    <b-icon
                      v-if="isInReadOnlyMode"
                      icon="keyboard-off-outline"
                    />
                    <b-icon
                      v-else
                      icon="keyboard"
                    />
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  :label="fullscreen ? 'Exit full screen' :
                    'Full screen'"
                  type="is-dark"
                  position="is-bottom"
                >
                  <b-button
                    type="is-white"
                    @click="toggleFullscreen()"
                  >
                    <b-icon
                      v-if="fullscreen"
                      icon="fullscreen-exit"
                    />
                    <b-icon
                      v-else
                      icon="fullscreen"
                    />
                  </b-button>
                </b-tooltip>
              </b-navbar-item>
            </template>
          </b-navbar>
          <div id="remote-screen">
            <b-loading
              :is-full-page="false"
              :active="desktopState.connecting || desktopState.connected && !screenDisplayed"
              :can-cancel="false"
            />
            <video
              id="desktop-video"
              style="display:none;height:0px;width:0px"
              autoplay="true"
              playsinline
            />
            <audio
              id="desktop-audio"
              style="display:none;height:0px;width:0px"
              autoplay="true"
            />
            <canvas
              v-show="device.permissionsStatus && device.permissionsStatus.capturePermission"
              id="desktop-canvas"
              :style="canvasStyle"
              @contextmenu.prevent
            />
            <div
              v-show="device.permissionsStatus && device.permissionsStatus.capturePermission"
              style="height:10px;overflow: hidden;"
            >
              <input
                id="desktop-text-input"
                ref="desktopTextInput"
                type="text"
                style="width: 10px; height:10px; opacity: 0;"
                autocomplete="off"
              >
            </div>
          </div>

          <div
            v-show="!device.permissionsStatus || !device.permissionsStatus.capturePermission"
            style="padding: 60px 0px;"
          >
            <p style="font-size: 20px;margin: 12px;">
              Privacy permissions are disabled, enable them to connect.
            </p>
            <button
              :class="{ 'button': true, 'is-primary': true}"
              @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4895813-how-to-enable-screen-recording-on-mac', true)"
            >
              <span>Learn how to enable privacy permissions</span>
            </button> <br>
            <br> <button
              :class="{ 'button': true, 'is-primary': true}"
              @click="reviewPermissions"
            >
              <span>Check permissions</span>
            </button>
          </div>
          <b-modal
            slot="footer"
            :parent="this"
            :active.sync="fileTransferModalOpen"
            has-modal-card
            width="90vw"
          >
            <transferFile
              :title="'File transfer'"
              :device-name="device.name"
              :folders="fileTransferRemoteFolders"
              :is-read-only="fileTransferReadOnlyPath"
              :custom-message="customMessage"
              :path-to-dir="fileTransferRemotePath"
              :transfering-file="transferingFile"
              :progress-bar-message="progressBarMessage"
              :percentage-file-transferred="percentageFileTransferred"
              :is-canceling="isCanceling"
              :on-get-remote-file="(selectedFile) => getRemoteFile(selectedFile)"
              :on-send-to-remote-file="(selectedFile) => verifyFileSizeOnRemote(selectedFile)"
              :on-cancel-file-transfer="(cancelObject) => cancelFileTransfer(cancelObject)"
              :on-navigate-to="(folderName) => navigateToFolder(folderName)"
            />
          </b-modal>
        </div>
      </card>
    </fullscreen>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { RecordRTCPromisesHandler, invokeSaveAsDialog } from 'recordrtc';
import fixWebmDuration from 'fix-webm-duration';
import semver from 'semver';
import protobuf from 'protobufjs/light';
import { debounce } from 'lodash';
import moment from 'moment';
import fileSaver from 'file-saver';
import snackBarMessage from '@/helpers/snackBarMessage';
import toastMessage from '@/helpers/toastMessage';
import card from '@/components/cross/Card.vue';
import transferFile from '@/components/cross/TransferFileModal.vue';
import ErrorMixin from '@/mixins/error';
import ExecutionsMixin from '@/mixins/executions';
import crossHelper from '@/helpers/cross';
import validations from '@/helpers/validations';
import navigatorInformation from '@/helpers/navigatorInformation';
import vtulEnums from '../../../../cross/index';
import protoMessageFromAgent from '../../../../cross/src/protobufjs/rdFromAgent.json';
import protoMessageFromUI from '../../../../cross/src/protobufjs/rdFromUI.json';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { operationalAgentErrors } = vtulEnums.enum.agent;
const { customMessage: customMessageEnum, operation } = vtulEnums.enum.remoteDesktop;
const { executeAction } = vtulEnums.enum.execution;

const screens = [{ screenNumber: 1, name: 'Display' }];
const possibleResolutions = [
  //  We show same resolutions that appear in Youtube
  //  https://support.google.com/youtube/answer/6375112?co=GENIE.Platform%3DDesktop&hl=en-GB
  {
    pixels: 256, name: '144p', icon: 'standard-definition',
  },
  {
    pixels: 426, name: '240p', icon: 'standard-definition',
  },
  {
    pixels: 640, name: '360p', icon: 'standard-definition',
  },
  {
    pixels: 854, name: '480p', icon: 'standard-definition',
  },
  {
    pixels: 1280, name: '720p', icon: 'high-definition-box',
  },
  {
    pixels: 1920, name: '1080p', icon: 'high-definition-box',
  },
  {
    pixels: 2560, name: '1440p', icon: 'high-definition-box',
  },
  {
    pixels: 3840, name: '2160p', icon: 'video-4k-box',
  },
  {
    pixels: 7680, name: '4320p', icon: 'video-4k-box',
  },
];
const possibleFps = [
  { fps: 0, name: 'Auto', icon: 'speedometer' },
  { fps: 5, name: '5 fps', icon: 'speedometer-slow' },
  { fps: 15, name: '15 fps', icon: 'speedometer-medium' },
  { fps: 25, name: '25 fps', icon: 'speedometer' },
  { fps: 30, name: '30 fps', icon: 'speedometer' },
  { fps: 60, name: '60 fps', icon: 'speedometer' },

];

export default {
  name: 'DeviceWRTC',
  components: {
    card,
    transferFile,
  },
  mixins: [ErrorMixin, ExecutionsMixin],
  props: {
    device: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    connectOnCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      wrtcConfig: null,
      peer: {
        [operation.REMOTE_DESKTOP.value.id]: null,
        [operation.FILE_TRANSFER.value.id]: null,
      },
      mediaStream: null,
      isMuted: false,
      desktopState: {
        connected: false, connecting: false, buttonText: 'Connect', textInCanvas: '',
      },
      currentRotation: 0,
      fullscreen: false,
      executionStatus: vtulEnums.enum.execution.executionStatus,
      remoteActionEnum: vtulEnums.enum.remoteDesktop.action,
      videoClient: null,
      audioClient: null,
      canvasStyle: {
        width: '100%',
        height: '100%',
        cursor: 'auto', // We should change this value programatically when implement remote cursor icon detection
      },
      canvasClient: null,
      canvasContext: null,
      ratio: 1,
      screenResolution: null,
      deviceResponse: null,
      connectionTimeout: null,
      lastCharacterTypedsded: null,
      screens,
      currentScreen: screens[0],
      possibleFps,
      currentFps: possibleFps.find((x) => x.fps === 0),
      possibleResolutions,
      currentPossibleResolution: possibleResolutions.find((x) => x.pixels === 1280),
      isInReadOnlyMode: false,
      messageFromAgent: protobuf.Root.fromJSON(protoMessageFromAgent).lookupType('RDMessageFromAgent'),
      messageFromUI: protobuf.Root.fromJSON(protoMessageFromUI).lookupType('RDMessageFromUI'),
      crossHelper,
      getRemoteMousePosition: this.createGetMousePosition(),
      remoteDesktopExecutionId: null,
      peerConnected: {
        [operation.REMOTE_DESKTOP.value.id]: false,
        [operation.FILE_TRANSFER.value.id]: false,
      },
      screenCapture: null,
      screenDisplayed: false,
      fileTransferRemoteFolders: [],
      customMessage: null,
      fileTransferRemotePath: '',
      fileTransferReadOnlyPath: true,
      fileTransferModalOpen: false,
      fileTransferSize: 0,
      fileReceivedBuffer: null,
      fileReceivedBufferIndex: 0,
      percentageFileTransferred: 0,
      transferingFile: false,
      progressBarMessage: '',
      uiIsSending: false,
      uiIsReceiving: false,
      isCanceling: false,
      canceledTransfer: false,
      countCanceled: 0,
      recorder: null,
      isRecording: false,
      recordingStartTime: null,
      blockStartAudioCall: false,
      captureMouse: false,
      signalListeners: {
        [operation.REMOTE_DESKTOP.value.id]: () => {},
        [operation.FILE_TRANSFER.value.id]: () => {},
      },
      peerListeners: {
        [operation.REMOTE_DESKTOP.value.id]: () => {},
        [operation.FILE_TRANSFER.value.id]: () => {},
      },
      selectedFile: null,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('executions', { getExecutionInStore: 'get' }),
    ...mapState(['mainLayoutStyle']),
    audioCallLabel() {
      if (this.blockStartAudioCall) {
        return 'The audio call is temporarily disabled because another Boardgent user is already on the call or there are not the necessary dependencies.';
      }
      return this.mediaStream ? 'Stop audio call' : 'Start audio call';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    shouldSendInput() {
      return !this.isInReadOnlyMode;
    },
    getCurrentExecutionGroup() {
      return this.getExecutionInStore(this.remoteDesktopExecutionId);
    },
    isWindows() {
      return this.device.OS.includes('Windows');
    },
    useLongNavbarVersion() {
      // We should modified this calculation when add or remove buttons of toolbar
      return this.mainLayoutStyle.mainLayoutWidth > 1200;
    },
    remoteDesktopNavbarsStyle() {
      return {
        padding: this.fullscreen ? '0 2%' : '0 0',
        'z-index': 28,
      };
    },
    remoteContainerStyle() {
      return {
        'text-align': 'center',
        padding: '0',
        'background-color': this.screenDisplayed ? '' : '#f5efef', // Background color used by MacOS Big Sur
      };
    },
  },
  watch: {
    deviceResponse: {
      handler(response) {
        if (!response) {
          return;
        }
        if (!response.startedOn && !response.finishedOn && !this.connectionTimeout) {
          this.connectionTimeout = setTimeout(() => {
            if (this.desktopState.connecting === true) {
              this.showErrorAndDisconnect(
                operationalUiErrors.UNRESPONSE_DEVICE_ERROR.value.message,
              );
            }
          }, 130 * 1000);
        } else if (response.exitCode && response.exitCode !== '0' && response.executionResult
          && !this.peerConnected[operation.REMOTE_DESKTOP.value.id]) {
          this.showErrorAndDisconnect(response.executionResult);
        }
      },
      deep: true,
    },
    peerConnected: {
      handler(response) {
        if (this.fileTransferModalOpen && response[operation.FILE_TRANSFER.value.id]
          && this.fileTransferRemoteFolders.length === 0) {
          this.navigateToFolder();
        }
      },
      deep: true,
    },
    mainLayoutStyle() {
      this.adjustCanvasClientSize();
    },
    percentageFileTransferred() {
      if (this.percentageFileTransferred === 0) this.transferingFile = false;
    },
    'desktopState.textInCanvas': function watchTextInCanvas(newText) {
      this.drawTextInCanvas(newText);
    },
  },
  mounted() {
    this.desktopTextInput = document.getElementById('desktop-text-input');
    this.videoClient = document.getElementById('desktop-video');
    this.audioClient = document.getElementById('desktop-audio');
    this.canvasClient = document.getElementById('desktop-canvas');
    this.canvasContext = this.canvasClient.getContext('2d');
    if (this.connectOnCreate) {
      this.connectDesktop();
    }
    this.adjustCanvasClientSize();
  },
  async beforeDestroy() {
    await this.disconnectDesktop();
  },
  methods: {
    ...mapActions('executions', { createExecution: 'create', updateExecution: 'patch' }),
    ...mapActions('execution-groups', { createExecutionGroup: 'create' }),
    async reviewPermissions() {
      snackBarMessage.showSuccess('Checking permissions');
      await this.createExecutions(
        this.currentCompany.id,
        this.user.id,
        [this.device.id],
        executeAction.CHECK_MACOS_PRIVACY_PERMISSIONS.value.id,
        null,
      );
    },
    async discoverSignalClient(action) {
      return new Promise(((resolve) => {
        let removeListenersDuringDiscover = null;
        function onSocketErrorDuringDiscover() {
          removeListenersDuringDiscover();
          resolve(null);
        }

        function handleSignalDiscover(data) {
          removeListenersDuringDiscover();
          resolve(data);
        }

        removeListenersDuringDiscover = () => {
          this.$signalSocket.removeListener('connect_failed', onSocketErrorDuringDiscover);
          this.$signalSocket.removeListener('connect_error', onSocketErrorDuringDiscover);
          this.$signalSocket.removeListener('disconnect', onSocketErrorDuringDiscover);
          this.$signalSocket.removeListener('deny', onSocketErrorDuringDiscover);
          this.$signalClient.removeListener('discover', handleSignalDiscover);
        };

        this.$signalSocket.on('connect_failed', onSocketErrorDuringDiscover);
        this.$signalSocket.on('connect_error', onSocketErrorDuringDiscover);
        this.$signalSocket.on('disconnect', onSocketErrorDuringDiscover);
        this.$signalSocket.on('deny', onSocketErrorDuringDiscover);
        this.$signalClient.on('discover', handleSignalDiscover);

        this.$signalClient.discover({
          password: this.$signalServerPassword,
          transport: action.transport,
        });
      }));
    },
    async createExecutionForAgent(signalClientID, execution) {
      try {
        const parameters = {
          signalClientID,
          onlyOnlineDevices: true,
        };
        const executionResult = await this.createExecutions(
          this.currentCompany.id,
          this.$store.getters['auth/user'].id,
          [this.device.id],
          execution,
          parameters,
        );
        if (execution === executeAction.CONNECT_REMOTE_DESKTOP.value.id) {
          this.remoteDesktopExecutionId = executionResult.results[0][0].id;
          this.deviceResponse = this.getCurrentExecutionGroup;
        }
      } catch (err) {
        this.showErrorAndDisconnect(`${operationalUiErrors.ERROR_CREATE_CONNECTION.value.message} ${err.message}.`);
      }
    },
    async createTunnel(operationType) {
      const wrtcConfigFromDiscovery = await this.discoverSignalClient(operationType);
      this.wrtcConfig = wrtcConfigFromDiscovery;
      if (!this.wrtcConfig || !this.$signalClient.id) {
        this.showErrorAndDisconnect(operationalUiErrors.ERROR_GETTING_WEBRTC_CONFIG.value.message);
        return;
      }

      this.addSignalListeners(operationType);

      let execution = executeAction.CONNECT_REMOTE_DESKTOP.value.id;
      if (operationType.id === operation.FILE_TRANSFER.value.id) {
        execution = executeAction.CONNECT_FILE_TRANSFER.value.id;
      }

      await this.createExecutionForAgent(this.$signalClient.id, execution);
    },
    async connectDesktop() {
      try {
        await this.disconnectDesktop();
        this.desktopState.connecting = true;
        this.desktopState.connected = false;
        this.desktopState.buttonText = 'Connecting';
        this.desktopState.textInCanvas = 'Creating secure encrypted peer-to-peer tunnel';
        this.peerConnected[operation.REMOTE_DESKTOP.value.id] = false;
        await this.createTunnel(operation.REMOTE_DESKTOP.value);
        this.currentRotation = 0;
      } catch (error) {
        this.showErrorAndDisconnect(`${operationalUiErrors.ERROR_CREATE_CONNECTION.value.message} ${error.message}.`);
      }
    },
    sendRemoteAction(action, operationType) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.peer[operationType.id] && this.peer[operationType.id]._connected) {
        const actionToSend = action;
        actionToSend.signalClientID = this.$signalClient.id;
        if (actionToSend.modifiers) actionToSend.modifiers = actionToSend.modifiers.toString();
        this.peer[operationType.id].send(this.messageFromUI.encode(actionToSend).finish());
      }
    },
    async disconnectDesktop(endedWithError) {
      if (!this.screenDisplayed && this.deviceResponse && this.deviceResponse.exitCode === '0'
        && !endedWithError) {
        this.updateExecution([this.deviceResponse.id, {
          executionResult: 'The user tried to connect but did not wait to successfully connect.',
          finishedOn: Date.now(),
        }, {
          query: {
            companyId: this.currentCompany.id,
          },
        }]);
      }
      if (this.screenDisplayed && this.deviceResponse) {
        const startedOn = moment(this.deviceResponse.createdAt).format('LLL');
        const finishedOn = moment(Date.now()).format('LLL');
        const timeConnected = moment.duration(moment(finishedOn).subtract(moment(startedOn)))
          .humanize();
        this.updateExecution([this.deviceResponse.id, {
          executionResult: `Remote desktop session connected from ${startedOn} to ${finishedOn} (${timeConnected})`,
          finishedOn: Date.now(),
        }, {
          query: {
            companyId: this.currentCompany.id,
          },
        }]);
      }
      this.deviceResponse = null;
      this.screenDisplayed = false;
      clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
      this.removeVideoListeners();
      this.removeAudioCall();
      this.desktopState.connecting = false;
      this.desktopState.connected = false;
      this.desktopState.buttonText = 'Connect';
      this.desktopState.textInCanvas = '';
      this.screens = [{ screenNumber: 1, name: 'Display' }];
      [this.currentScreen] = this.screens;
      this.videoClient.src = null;
      this.videoClient.srcObject = null;
      this.audioClient.src = null;
      this.audioClient.srcObject = null;
      this.screenResolution = null;
      this.canvasContext.clearRect(0, 0, this.canvasClient.width, this.canvasClient.height);
      this.canvasStyle.width = '100%';
      this.canvasStyle.height = '100%';
      this.focusOnRemoteDesktop(false);

      // Destroying the peer after everything, if there is a exception in the peer disconnect
      // We dont have want exception prevents to update the UI
      if (this.peer[operation.REMOTE_DESKTOP.value.id]) {
        this.removePeerListeners(operation.REMOTE_DESKTOP.value);
        this.sendRemoteAction({ type: this.remoteActionEnum.DISCONNECT.value },
          operation.REMOTE_DESKTOP.value);
        this.peer[operation.REMOTE_DESKTOP.value.id].destroy();
        this.peer[operation.REMOTE_DESKTOP.value.id] = null;
      }

      if (this.peer[operation.FILE_TRANSFER.value.id] && semver.gte(this.device.agentVersion, '19.6.7')) {
        this.removePeerListeners(operation.FILE_TRANSFER.value);
        this.sendRemoteAction({ type: this.remoteActionEnum.DISCONNECT.value },
          operation.FILE_TRANSFER.value);
        this.peer[operation.FILE_TRANSFER.value.id].destroy();
        this.peer[operation.FILE_TRANSFER.value.id] = null;
      }

      this.removeSignalListeners(operation.REMOTE_DESKTOP.value);
      this.removeSignalListeners(operation.FILE_TRANSFER.value);
      this.adjustCanvasClientSize();

      // Stop recording when disconnect remote desktop
      if (this.isRecording) await this.stopRecording();
    },
    // rotate(direction) {
    //   this.currentRotation = this.currentRotation + direction;
    //   this.desktop.m.setRotation(this.currentRotation);
    //   this.adjustCanvasClientSize();
    // },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    lockKeysOnFullScreen() {
      if (document.fullscreenElement) {
        navigator.keyboard.lock([
          'MetaLeft',
        ]);
      } else {
        navigator.keyboard.unlock();
      }
    },
    focusOnRemoteDesktop(focus) {
      if (focus) {
        // save current position to prevent scroll in Safari
        const x = window.scrollX;
        const y = window.scrollY;
        this.desktopTextInput.focus({ preventScroll: true });
        window.scrollTo(x, y); // restore position scroll position
      } else {
        this.desktopTextInput.blur();
      }
      this.desktopTextInput.value = '';
    },
    mouseOver() {
      this.focusOnRemoteDesktop(true);
    },
    mouseMove(e) {
      if (!this.shouldSendInput) return this.cancelEvent(e);
      const remotePosition = this.getRemoteMousePosition(e);
      this.sendRemoteAction({
        type: this.remoteActionEnum.MOUSE_MOVE.value,
        x: remotePosition.x,
        y: remotePosition.y,
      }, operation.REMOTE_DESKTOP.value);
      return this.cancelEvent(e);
    },
    mouseUp(e) {
      if (!this.shouldSendInput) return this.cancelEvent(e);
      const remotePosition = this.getRemoteMousePosition(e);
      this.sendRemoteAction({
        type: this.remoteActionEnum.MOUSE_UP.value,
        x: remotePosition.x,
        y: remotePosition.y,
        button: e.button,
      }, operation.REMOTE_DESKTOP.value);
      return this.cancelEvent(e);
    },
    mouseDown(e) {
      if (!this.shouldSendInput) return this.cancelEvent(e);
      const remotePosition = this.getRemoteMousePosition(e);
      this.sendRemoteAction({
        type: this.remoteActionEnum.MOUSE_DOWN.value,
        x: remotePosition.x,
        y: remotePosition.y,
        button: e.button,
      }, operation.REMOTE_DESKTOP.value);
      return this.cancelEvent(e);
    },
    handleMouseWheel(e) {
      if (!this.shouldSendInput) return this.cancelEvent(e);
      this.sendRemoteAction({
        type: this.remoteActionEnum.MOUSE_SCROLL.value,
        x: e.deltaX * this.ratio * -1,
        y: e.deltaY * this.ratio * -1,
      }, operation.REMOTE_DESKTOP.value);
      return this.cancelEvent(e);
    },
    isIgnoredKey(e) {
      switch (e.key) {
        case 'CapsLock':
        case 'AltGraph':
        case 'Dead':
          return true;
        default:
          return false;
      }
    },
    isSpecialKey(e) {
      // Supports combinations between Ctrl or Windows Key and other non-special keys.
      // Support combiantios between all special keys.
      // Currently don't support combinations between Alt or Shift and a non-special key
      if (e.ctrlKey || e.metaKey) return true;
      switch (e.key) {
        case 'Shift':
        case 'Meta':
        case 'Control':
        case 'Escape':
        case 'Backspace':
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'MediaTrackPrevious':
        case 'MediaTrackNext':
        case 'Tab':
        case 'PageUp':
        case 'PageDown':
        case 'End':
        case 'Home':
        case 'Delete':
        case 'Insert':
        case 'Enter':
        case 'PrintScreen':
        case 'F1':
        case 'F2':
        case 'F3':
        case 'F4':
        case 'F5':
        case 'F6':
        case 'F7':
        case 'F8':
        case 'F9':
        case 'F10':
        case 'F11':
        case 'F12':
        case 'CapsLock':
        case 'Alt':
        case 'AltGraph':
          return true;
        default:
          return false;
      }
    },
    getRobotJsModifiers(e) {
      const modifiers = [];
      if (e.key === 'Shift' || e.key === 'Alt'
          || e.key === 'Control' || e.key === 'Meta') {
        return modifiers;
      }

      if (e.altKey) {
        modifiers.push('alt');
      }
      if (e.ctrlKey) {
        modifiers.push('control');
      }
      if (e.shiftKey) {
        modifiers.push('shift');
      }
      if (e.metaKey) {
        modifiers.push('command');
      }
      return modifiers;
    },
    handleKeyUp(e) { // Handle special keys
      if (!this.shouldSendInput) return this.cancelEvent(e);
      if (this.isIgnoredKey(e)) {
        return this.cancelEvent(e);
      }
      if (this.isSpecialKey(e)) {
        this.sendRemoteAction({
          type: this.remoteActionEnum.KEY_UP.value,
          key: e.key,
          modifiers: this.getRobotJsModifiers(e),
        }, operation.REMOTE_DESKTOP.value);
      }
      return this.cancelEvent(e);
    },
    handleKeyDown(e) { // Handle special keys
      if (!this.shouldSendInput) return this.cancelEvent(e);
      if (this.isIgnoredKey(e)) {
        this.lastCharacterTyped = null;
        return this.cancelEvent(e);
      }
      if (this.isSpecialKey(e)) {
        this.sendRemoteAction({
          type: this.remoteActionEnum.KEY_DOWN.value,
          key: e.key,
          modifiers: this.getRobotJsModifiers(e),
        }, operation.REMOTE_DESKTOP.value);
        this.lastCharacterTyped = null;
        return this.cancelEvent(e);
      }
      return true;
    },
    isSameCharacterBeforeLongPress(e) {
      if (!this.lastCharacterTyped) return false;
      if (e.data === this.lastCharacterTyped) return false;

      // Long press keys found in keyboards with english layout
      if ((e.data === 'æ' && this.lastCharacterTyped === 'a')
       || (e.data === 'Æ' && this.lastCharacterTyped === 'A')
       || (e.data === 'œ' && this.lastCharacterTyped === 'o')
       || (e.data === 'Œ' && this.lastCharacterTyped === 'O')
       || (e.data === 'ø' && this.lastCharacterTyped === 'o')
       || (e.data === 'Ø' && this.lastCharacterTyped === 'O')
       || (e.data === 'ß' && this.lastCharacterTyped === 's')
       || (e.data === 'ł' && this.lastCharacterTyped === 'l')
       || (e.data === 'Ł' && this.lastCharacterTyped === 'L')

      // Long press keys found in keyboards with spanish layout
       || (e.data === 'ª' && this.lastCharacterTyped.toLowerCase() === 'a')
       || (e.data === 'º' && this.lastCharacterTyped.toLowerCase() === 'o')
       || (e.data === 'đ' && this.lastCharacterTyped === 'd')
       || (e.data === 'Đ' && this.lastCharacterTyped === 'D')) return true;

      return e.data.normalize('NFD').replace(/[\u0300-\u036f]/g, '') === this.lastCharacterTyped;
    },
    handleInput(e) { // Handle normal characters
      if (!this.shouldSendInput) return this.cancelEvent(e);
      // Ignored, will be manage by handleCompositionEnd
      if (e.isComposing) {
        return this.cancelEvent(e);
      }
      // This happen when using long press in MAC for producing characters
      // We delete the previous characters if the previous one is the same without acent
      if (this.isSameCharacterBeforeLongPress(e)) {
        this.sendRemoteAction({
          type: this.remoteActionEnum.KEY_TAP.value,
          key: 'Backspace',
          modifiers: [],
        }, operation.REMOTE_DESKTOP.value);
        this.sendRemoteAction({
          type: this.remoteActionEnum.TYPE_STRING.value,
          string: e.data,
        }, operation.REMOTE_DESKTOP.value);
        this.lastCharacterTyped = null;
      } else { // Normal character
        this.sendRemoteAction({
          type: this.remoteActionEnum.TYPE_STRING.value,
          string: e.data,
        }, operation.REMOTE_DESKTOP.value);
        this.lastCharacterTyped = e.data;
      }
      return this.cancelEvent(e);
    },
    handleCompositionEnd(e) { // Handle characters that was composed using a dead key
      if (!this.shouldSendInput) return this.cancelEvent(e);
      this.sendRemoteAction({
        type: this.remoteActionEnum.TYPE_STRING.value,
        string: e.data,
      }, operation.REMOTE_DESKTOP.value);
      this.lastCharacterTyped = null;
      return this.cancelEvent(e);
    },
    async addAudioCall() {
      if (!this.peer[operation.REMOTE_DESKTOP.value.id]) return;
      // Remove previous possibles calls before start a new one
      this.removeAudioCall();
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      this.peer[operation.REMOTE_DESKTOP.value.id].addStream(this.mediaStream);
      // Unmute to have mic ON when start a new call
      this.toggleMicInAudioCall(true);
    },
    removeAudioCall() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
        if (this.peer[operation.REMOTE_DESKTOP.value.id]) {
          this.peer[operation.REMOTE_DESKTOP.value.id].removeStream(this.mediaStream);
        }
        this.mediaStream = null;
      }

      this.sendRemoteAction({
        type: this.remoteActionEnum.REMOVE_AUDIOCALL.value,
      }, operation.REMOTE_DESKTOP.value);
    },
    toggleMicInAudioCall(sound) {
      if (!this.mediaStream) return;
      this.mediaStream.getAudioTracks()[0].enabled = sound;
      this.isMuted = !sound;
    },
    sendcad() {
      this.sendRemoteAction({
        type: this.remoteActionEnum.CAD.value,
      }, operation.REMOTE_DESKTOP.value);
    },
    async sendLocalClipboard() {
      this.sendRemoteAction({
        type: this.remoteActionEnum.TYPE_STRING.value,
        string: await navigator.clipboard.readText(),
      }, operation.REMOTE_DESKTOP.value);
      this.$refs.desktopTextInput.focus();
    },
    async getRemoteFile(selectedFile) {
      this.transferingFile = true;
      this.sendRemoteAction({
        type: this.remoteActionEnum.RECEIVE_FILE_FROM_AGENT.value,
        string: `${this.fileTransferRemotePath}${selectedFile}`,
      }, operation.FILE_TRANSFER.value);
    },
    async verifyFileSizeOnRemote(file) {
      this.sendRemoteAction({
        type: this.remoteActionEnum.VERIFY_FILE_TRANSFER.value,
        string: JSON.stringify({
          path: `${this.fileTransferRemotePath}`,
          fileSize: file.size,
        }),
      }, operation.FILE_TRANSFER.value);
      this.selectedFile = file;
    },
    async sendFileToRemote() {
      this.transferingFile = true;
      this.sendRemoteAction({
        type: this.remoteActionEnum.SEND_FILE_TO_AGENT.value,
        string: JSON.stringify({
          path: `${this.fileTransferRemotePath}${this.selectedFile.name}`,
          isCreating: true,
          fileSize: this.selectedFile.size,
        }),
      }, operation.FILE_TRANSFER.value);
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        const buf = Buffer.alloc(event.target.result.byteLength);
        let view = new Uint8Array(event.target.result);
        for (let i = 0; i < buf.length; i += 1) {
          buf[i] = view[i];
        }
        view = null;
        const chunkSize = 260 * 1000; // 260 kb
        const bufferLimit = 16 * 1000 * 1000; // 16 mb
        let startPointer = 0;
        const endPointer = this.selectedFile.size;
        while ((startPointer < endPointer) && !this.cancelingTransfer) {
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            const ftPeer = this.peer[operation.FILE_TRANSFER.value.id];
            /* eslint-disable no-underscore-dangle */
            if (!ftPeer || !ftPeer._channel) {
              resolve();
              return;
            }

            if (ftPeer._channel.bufferedAmount
              && ftPeer._channel.bufferedAmount > bufferLimit) {
              const bufferInterval = setInterval(() => {
                const peer = this.peer[operation.FILE_TRANSFER.value.id];
                if (!peer || !peer._channel || peer._channel.bufferedAmount < bufferLimit) {
                  clearInterval(bufferInterval);
                  resolve();
                }
              }, 1000);
            } else {
              resolve();
            }
          });
          const newStartPointer = startPointer + chunkSize;
          const chunk = buf.slice(startPointer, newStartPointer);
          this.sendRemoteAction({
            type: this.remoteActionEnum.SEND_FILE_TO_AGENT.value,
            bytes: chunk,
          }, operation.FILE_TRANSFER.value);
          this.percentageFileTransferred = (newStartPointer * 100) / this.selectedFile.size;
          startPointer = newStartPointer;
        }
        if (!this.cancelingTransfer) {
          // Tell the agent the transfer has ended
          this.progressBarMessage = 'Processing file';
          this.sendRemoteAction({
            type: this.remoteActionEnum.SEND_FILE_TO_AGENT.value,
            string: JSON.stringify({ isCreating: false }),
          }, operation.FILE_TRANSFER.value);
        }
        setTimeout(() => { // This fix error reporting progress when the transfer ends very fast
          this.percentageFileTransferred = 0;
        }, 100);
      };
      fileReader.readAsArrayBuffer(this.selectedFile);
    },
    cancelFileTransfer(cancelObject) {
      this.cancelingTransfer = true;
      this.isCanceling = true;
      this.progressBarMessage = 'Cancellation in progress';
      this.sendRemoteAction({
        type: this.remoteActionEnum.CANCEL_TRANSFER_FILE.value,
        string: JSON.stringify(cancelObject),
      }, operation.FILE_TRANSFER.value);
    },
    async navigateToFolder(path) {
      if (!path) {
        this.fileTransferRemotePath = '';
      } else {
        this.fileTransferRemotePath = path;
      }
      this.customMessage = null;

      this.sendRemoteAction({
        type: this.remoteActionEnum.SELECT_FOLDER.value,
        string: this.fileTransferRemotePath,
      }, operation.FILE_TRANSFER.value);
    },
    async sendLock() {
      this.sendRemoteAction({
        type: this.remoteActionEnum.LOCK.value,
      }, operation.REMOTE_DESKTOP.value);
    },
    changeScreen(screen) {
      // Don't change screen if the requested is the current one
      if (this.currentScreen.screenNumber === screen.screenNumber) return;
      this.currentScreen = screen;
      this.changeRemoteVideo();
    },
    changeFPS(fps) {
      // Don't change fps if the requested is the current one
      if (this.currentFps.fps === fps.fps) return;
      this.currentFps = fps;
      this.changeRemoteVideo();
    },
    changeResolution(desiredResolution) {
      // Don't change resolution if the requested is the current one
      if (this.currentPossibleResolution.pixels === desiredResolution.pixels) return;
      this.currentPossibleResolution = desiredResolution;
      this.changeRemoteVideo();
    },
    changeRemoteVideo() {
      this.sendRemoteAction({
        type: this.remoteActionEnum.CHANGE_SCREEN.value,
        screen: this.currentScreen.screenNumber,
        maxResolution: this.currentPossibleResolution.pixels,
        fps: this.currentFps.fps,
        captureMouse: this.captureMouse,
      }, operation.REMOTE_DESKTOP.value);
    },
    changeIsReadOnly(readOnly) {
      this.isInReadOnlyMode = readOnly;
      if (this.isInReadOnlyMode) this.canvasStyle.cursor = 'not-allowed';
      else this.canvasStyle.cursor = 'auto';
    },
    changeCaptureMouse(captureMouse) {
      this.captureMouse = captureMouse;
      this.changeRemoteVideo();
    },
    async handleVideoResize(e) {
      const newScreenResolution = {
        width: e.srcElement.videoWidth,
        height: e.srcElement.videoHeight,
      };

      // Validting if new resolution is really different than the previous one
      if (JSON.stringify(newScreenResolution) !== JSON.stringify(this.screenResolution)) {
        // Restart the recording when the resolution changes
        if (this.isRecording) {
          await this.stopRecording();
          this.startRecording();
          snackBarMessage.showSuccess('The screen recording restarted due to a resolution change.');
        }

        this.screenResolution = newScreenResolution;
        this.adjustCanvasClientSize();
      }
      this.focusOnRemoteDesktop(true);
    },
    handleVideoError(e) {
      this.showErrorAndDisconnect(e.toString());
    },
    handleVideoPlay() {
      this.screenDisplayed = true;
      this.desktopState.textInCanvas = '';
      // If the browser supports requestVideoFrameCallback we use that API that is more efficient
      if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) this.drawVideoInCanvas();
      else this.drawVideoInCanvasLegacy();
    },
    removeVideoListeners() {
      this.videoClient.removeEventListener('resize', this.handleVideoResize);
      this.videoClient.removeEventListener('play', this.handleVideoPlay);
      this.videoClient.removeEventListener('error', this.handleVideoError);
      this.desktopTextInput.removeEventListener('keydown', this.handleKeyDown);
      this.desktopTextInput.removeEventListener('keyup', this.handleKeyUp);
      this.desktopTextInput.removeEventListener('input', this.handleInput);
      this.desktopTextInput.removeEventListener('compositionend', this.handleCompositionEnd);
      this.canvasClient.removeEventListener('mousemove', this.mouseMove);
      this.canvasClient.removeEventListener('mousedown', this.mouseDown);
      this.canvasClient.removeEventListener('mouseup', this.mouseUp);
      this.canvasClient.removeEventListener('mouseover', this.mouseOver);
      this.canvasClient.removeEventListener('wheel', this.handleMouseWheel);
      document.removeEventListener('fullscreenchange', this.lockKeysOnFullScreen);
    },
    addVideoListeners() {
      this.videoClient.addEventListener('resize', this.handleVideoResize);
      this.videoClient.addEventListener('play', this.handleVideoPlay);
      this.videoClient.addEventListener('error', this.handleVideoError);
      this.desktopTextInput.addEventListener('keydown', this.handleKeyDown);
      this.desktopTextInput.addEventListener('keyup', this.handleKeyUp);
      this.desktopTextInput.addEventListener('input', this.handleInput);
      this.desktopTextInput.addEventListener('compositionend', this.handleCompositionEnd);
      this.canvasClient.addEventListener('mousemove', this.mouseMove);
      this.canvasClient.addEventListener('mousedown', this.mouseDown);
      this.canvasClient.addEventListener('mouseup', this.mouseUp);
      this.canvasClient.addEventListener('mouseover', this.mouseOver);
      this.canvasClient.addEventListener('wheel', this.handleMouseWheel);
      document.addEventListener('fullscreenchange', this.lockKeysOnFullScreen);
    },
    handlePeerConnect(type) {
      if (type === operation.REMOTE_DESKTOP.value.id) {
        this.desktopState.connecting = false;
        this.desktopState.connected = true;
        this.desktopState.buttonText = 'Disconnect';
        clearTimeout(this.connectionTimeout);
        this.connectionTimeout = null;
        this.addVideoListeners();
      }
      this.peerConnected[type] = true;
    },
    drawTextInCanvas(textString) {
      if (!textString) return;
      this.canvasContext.clearRect(0, 0, this.canvasClient.width, this.canvasClient.height);
      this.canvasContext.fillStyle = '#262525'; // Color of text use by MacOS Big Sur
      const fontFace = 'san-serif';
      let fontSize = 23;
      let textWidth = 0;
      // lower the font size until the text fits the canvas
      do {
        fontSize -= 1;
        this.canvasContext.font = `${fontSize}px ${fontFace}`;
        textWidth = this.canvasContext.measureText(textString).width;
      } while (textWidth > this.canvasClient.width);

      this.canvasContext.fillText(textString, (this.canvasClient.width / 2) - (textWidth / 2), 100);
    },
    drawVideoInCanvas() {
      this.canvasContext.drawImage(this.videoClient, 0, 0);
      // request new frame to draw
      this.videoClient.requestVideoFrameCallback(this.drawVideoInCanvas);
    },
    drawVideoInCanvasLegacy() {
      this.canvasContext.drawImage(this.videoClient, 0, 0);
      // If screen is still displayed request new frame to draw
      if (this.screenDisplayed) requestAnimationFrame(this.drawVideoInCanvasLegacy);
    },
    handlePeerStream(stream, type) {
      if (type === operation.FILE_TRANSFER.value.id) {
        return;
      }
      if (stream.getVideoTracks().length) { // If video track
        this.addSrcToElement(this.videoClient, stream);
        this.recorder = new RecordRTCPromisesHandler(stream, { type: 'video', disableLogs: true });
      } else if (stream.getAudioTracks().length) { // If audio track
        this.addSrcToElement(this.audioClient, stream);
      }
    },
    addSrcToElement(elementParam, stream) {
      const element = elementParam;
      if ('srcObject' in element) {
        element.srcObject = stream;
      } else {
        element.src = window.URL.createObjectURL(stream); // for older browsers
      }
    },
    async handlePeerData(data) {
      const message = this.messageFromAgent.decode(data);
      switch (message.type) {
        case vtulEnums.enum.remoteDesktop.handleData.SCREEN_CAPTURE_PROCESS_READY.value:
          this.changeRemoteVideo();
          break;
        case vtulEnums.enum.remoteDesktop.handleData.SCREENS_INFORMATION.value:
          this.screens = JSON.parse(message.screens);
          this.screens.forEach((screenParam, index) => {
            const screen = screenParam;
            if (!screen.name) screen.name = `Display ${index + 1}`;
          });
          this.currentScreen = this.screens.find(
            (screen) => screen.screenNumber === this.currentScreen.screenNumber,
          );
          break;
        case vtulEnums.enum.remoteDesktop.handleData.ERROR.value:
          this.showErrorAndDisconnect(message.message);
          break;
        case vtulEnums.enum.remoteDesktop.handleData.DRAW_TEXT_IN_CANVAS.value:
          this.desktopState.textInCanvas = message.message;
          break;
        case vtulEnums.enum.remoteDesktop.handleData.SELECT_FOLDER.value: {
          const foldersResponse = JSON.parse(message.message);
          this.fileTransferReadOnlyPath = foldersResponse.isReadOnly;
          this.fileTransferRemoteFolders = foldersResponse.folders;
          break;
        }
        case vtulEnums.enum.remoteDesktop.handleData.CUSTOM_MESSAGE.value:
          if (JSON.parse(message.message) === customMessageEnum.NO_FULL_HARD_DISK_ACCESS.value) {
            this.fileTransferRemoteFolders = [];
            this.customMessage = customMessageEnum.NO_FULL_HARD_DISK_ACCESS.value;
            snackBarMessage.showError('Permissions to access the current folder are disabled.');
          }
          break;
        case vtulEnums.enum.remoteDesktop.handleData.RECEIVE_FILE_FROM_AGENT.value:
          // eslint-disable-next-line no-prototype-builtins
          if (message.hasOwnProperty('fileSize')) {
            this.fileReceivedBuffer = new Uint8Array(message.fileSize);
            this.fileTransferSize = message.fileSize;
          } else if (message.message) {
            const fileName = message.message;
            const file = new File([this.fileReceivedBuffer], fileName, {
              type: 'application/octet-stream',
            });
            fileSaver.saveAs(file);
            this.fileReceivedBuffer = null;
            this.fileReceivedBufferIndex = 0;
            this.percentageFileTransferred = 0;
            this.transferingFile = false;
            toastMessage.showSuccess('File received successfully');
          } else if (message.bytes) {
            if (this.cancelingTransfer) {
              this.fileReceivedBuffer = null;
              this.fileReceivedBufferIndex = 0;
              this.percentageFileTransferred = 0;
              this.transferingFile = false;
              if (this.countCanceled < 1) {
                snackBarMessage.showSuccess('Canceling transfer...');
                this.countCanceled += 1;
              }
              return;
            }
            message.bytes = Array.from(message.bytes); // Here we reuse the same space in memory
            this.fileReceivedBuffer.set(message.bytes, this.fileReceivedBufferIndex);
            this.fileReceivedBufferIndex += message.bytes.length;
            this.percentageFileTransferred = (this.fileReceivedBufferIndex * 100)
            / this.fileTransferSize;
            if (this.isCanceling) {
              this.isCanceling = false;
            }
          }
          break;
        case vtulEnums.enum.remoteDesktop.handleData.TRANSFER_FILE_ERROR.value:
          snackBarMessage.showError(message.message);
          break;
        case vtulEnums.enum.remoteDesktop.handleData.CANCEL_TRANSFER_FILE.value:
          this.fileReceivedBuffer = null;
          this.isCanceling = false;
          this.progressBarMessage = '';
          this.cancelingTransfer = !JSON.parse(message.message).cancelledTransferFile;
          snackBarMessage.showSuccess('Transfer cancelled successfully.');
          break;
        case vtulEnums.enum.remoteDesktop.handleData.FILE_TRANSFER_FINISHED.value:
          this.transferingFile = false;
          this.progressBarMessage = '';
          this.isCanceling = false;
          toastMessage.showSuccess('File sent successfully');
          this.navigateToFolder(this.fileTransferRemotePath);
          break;
        case vtulEnums.enum.remoteDesktop.handleData.BLOCK_AUDIOCALL.value:
          if (!this.mediaStream) {
            this.blockStartAudioCall = true;
          }
          break;
        case vtulEnums.enum.remoteDesktop.handleData.UNBLOCK_AUDIOCALL.value:
          this.blockStartAudioCall = false;
          break;
        case vtulEnums.enum.remoteDesktop.handleData.FILE_TRANSFER_VERIFIED.value:
          await this.sendFileToRemote();
          break;
        default:
          break;
      }
    },
    handlePeerError(e, operationType) {
      if (operationType === operation.FILE_TRANSFER.value) {
        return;
      }
      this.showErrorAndDisconnect(e.toString());
    },
    handlePeerClose(operationType) {
      if (operationType === operation.FILE_TRANSFER.value) {
        return;
      }
      this.showErrorAndDisconnect();
    },
    showErrorAndDisconnect(error = operationalUiErrors.UNEXPECTED_CONNECTION_ERROR.value.message) {
      let finalError = error;
      if (finalError === operationalAgentErrors.ERROR_GETTING_FOLDER_DEPENDENCIES.value.message
        || finalError === operationalAgentErrors.UPDATE_DOWNLOAD_ERROR_MD5.value.message) {
        finalError = 'Dependencies are updating, please try again later.';
      }

      // This message is only issued by agents below version 19.7.11
      if (finalError === 'The remote desktop process was closed. Please connect again') {
        finalError = 'The remote desktop process was closed. Please verify that the device has version 19.7.11 or higher and connect again';
      }
      snackBarMessage.showError(finalError);
      this.disconnectDesktop(true);
    },
    async handleSignalRequest(request, operationType) {
      if (this.peer[operationType.id]) {
        return;
      }
      const { peer } = await request.accept(null,
        {
          initiator: false,
          trickle: true,
          config: this.wrtcConfig,
        });
      this.peer[operationType.id] = peer;
      this.addPeerListeners(operationType);
    },
    removeSignalListeners(operationType) {
      this.$signalClient.removeListener('request', this.signalListeners[operationType.id]);
    },
    addSignalListeners(operationType) {
      this.signalListeners[operationType.id] = (request) => {
        this.handleSignalRequest(request, operationType);
      };
      this.$signalClient.on('request', this.signalListeners[operationType.id]);
    },
    removePeerListeners(operationType) {
      const type = operationType.id;
      if (this.peer[type]) {
        this.peer[type].removeListener('connect', this.peerListeners[type].connect);
        this.peer[type].removeListener('data', this.handlePeerData);
        this.peer[type].removeListener('stream', this.peerListeners[type].stream);
        this.peer[type].removeListener('error', this.peerListeners[type].error);
        this.peer[type].removeListener('close', this.peerListeners[type].close);
      }
    },
    addPeerListeners(operationType) {
      const type = operationType.id;
      this.peerListeners[type] = {
        connect: () => { this.handlePeerConnect(type); },
        stream: (data) => { this.handlePeerStream(data, type); },
        error: (error) => { this.handlePeerError(error); },
        close: () => { this.handlePeerClose(); },
      };
      this.peer[type].on('connect', this.peerListeners[type].connect);
      this.peer[type].on('data', this.handlePeerData);
      this.peer[type].on('stream', this.peerListeners[type].stream);
      this.peer[type].on('error', this.peerListeners[type].error);
      this.peer[type].on('close', this.peerListeners[type].close);
    },
    adjustCanvasClientSize: debounce(function adjustCanvasClientSize() {
      const desiredWidth = this.fullscreen
        ? this.$screen.width : this.mainLayoutStyle.mainLayoutWidth - 94;
      const maxHeight = this.fullscreen
        ? this.$screen.height - 55 : this.mainLayoutStyle.mainLayoutHeight - 119;
      let width = 0;
      let height = 0;
      if (this.screenResolution) {
        width = this.screenResolution.width;
        height = this.screenResolution.height;
      } else {
        width = desiredWidth;
        height = maxHeight;
      }

      const aspectWidth = width / height;
      width = desiredWidth;
      height = width / aspectWidth;
      if (height > maxHeight) {
        const aspectHeight = height / width;
        height = maxHeight;
        width = height / aspectHeight;
      }

      if (this.screenResolution) {
        this.ratio = this.screenResolution.width / width;
        this.canvasClient.width = this.screenResolution.width;
        this.canvasClient.height = this.screenResolution.height;
      } else {
        this.canvasClient.width = width;
        this.canvasClient.height = height;
      }

      this.canvasStyle.width = `${width}px`;
      this.canvasStyle.height = `${height}px`;

      this.drawTextInCanvas(this.desktopState.textInCanvas);
    }, 200, { leading: true, trailing: true }),
    cancelEvent(e) {
      if (e.preventDefault) e.preventDefault();
      if (e.stopPropagation) e.stopPropagation();
      return false;
    },
    createGetMousePosition() {
      if (validations.isFirefox() || navigatorInformation.getChromiumVersion() >= 99) {
        return function getRemoteMousePosition(e) {
          return {
            x: (e.layerX - this.canvasClient.offsetLeft) * this.ratio,
            y: (e.layerY - this.canvasClient.offsetTop) * this.ratio,
          };
        };
      }
      return function getRemoteMousePosition(e) {
        return {
          x: e.layerX * this.ratio,
          y: e.layerY * this.ratio,
        };
      };
    },
    async openTransferFileModal() {
      // To support file transfer for agents before 19.6.7
      if (semver.gte(this.device.agentVersion, '19.6.7')) {
        this.peerConnected[operation.FILE_TRANSFER.value.id] = false;
        await this.createTunnel(operation.FILE_TRANSFER.value);
        this.fileTransferModalOpen = true;
      } else {
        this.peerConnected[operation.FILE_TRANSFER.value.id] = true;
        this.peer[operation.FILE_TRANSFER.value.id] = this.peer[operation.REMOTE_DESKTOP.value.id];
        this.fileTransferModalOpen = true;
        if (this.fileTransferRemoteFolders.length === 0) {
          this.navigateToFolder();
        }
      }
    },
    takeScreenshot() {
      const image = this.canvasClient.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const a = document.createElement('a');
      a.setAttribute('download', `${this.device.name}.png`);
      a.setAttribute('href', image);
      a.click();
    },
    startRecording() {
      this.isRecording = true;
      if (!this.recorder) {
        snackBarMessage.showError('Unexpected error starting the recording');
        return;
      }
      this.recorder.startRecording();
      this.recordingStartTime = Date.now();
    },
    async stopRecording() {
      this.isRecording = false;
      if (!this.recorder) {
        return;
      }
      await this.recorder.stopRecording();
      const duration = Date.now() - this.recordingStartTime;
      const blob = await this.recorder.getBlob();
      this.recorder.reset();
      // Chrome has a bug that creates not seekable videos. We fix it with fixWebmDuration library
      const seekableblob = await fixWebmDuration(blob, duration);
      invokeSaveAsDialog(seekableblob, `${this.device.name}.mkv`);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 769px) {
  .button-row {
    padding-bottom: 0px;
  }
}
@media screen and (min-width: 769px) {
  .right-buttons {
    text-align: right;
    padding-right: 1.5rem;
  }
  .center-buttons {
    text-align: center;
  }
}
.button-margin {
  margin-bottom: 3px;
  margin-left: 5px;
}
.dropdown-item-router {
  padding: 0px !important;
  height: 36px;
}
.buttons-container {
  margin-bottom: 0px;
  padding: 0.50rem 0.75rem 0px;
}
.padding-button {
  padding: 0.75rem;
}

@media screen and (max-width: 600px) {
 .has-margin-top{
    margin-top:10px;
  }
}

.wrap-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#remote-screen{
  /* required to make b-loading not take full screen  */
  position: relative;
}

#remote-screen > .loading-overlay{
  /* prevent conflicts with tooltips while loading  */
  z-index: 1;
}
</style>
