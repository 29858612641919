<template>
  <card external-card="material-card">
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            Users
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>
      <card
        internal-card="material-card material-card-content"
        style-internal-card="flex:auto"
      >
        <div
          slot="body"
          style="display: flex"
        >
          <b-table
            :data="users"
            :paginated="pagination"
            :per-page="maxQuantity"
            :mobile-cards="false"
            default-sort="lastDetection"
            default-sort-direction="desc"
            style="width: 100%"
          >
            <b-table-column
              field="userName"
              label="User name"
              sortable
            >
              <template v-slot="props">
                {{ props.row.userName }}
              </template>
            </b-table-column>

            <b-table-column
              field="sid"
              label="SID"
              sortable
            >
              <template v-slot="props">
                {{ props.row.sid }}
              </template>
            </b-table-column>

            <b-table-column
              field="domain"
              label="Domain"
              sortable
            >
              <template v-slot="props">
                {{ props.row.domain }}
              </template>
            </b-table-column>

            <b-table-column
              field="lastDetection"
              label="Detected"
              sortable
            >
              <template v-slot="props">
                <timeago
                  :key="props.row.id"
                  :since="props.row.lastDetection"
                  no-future
                />
              </template>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="file"
                      size="is-large"
                    />
                  </p>
                  <p>There are no users to show</p>
                </div>
              </section>
            </template>
          </b-table>
          <learn-more url="" />
        </div>
      </card>
    </b-collapse>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import learnMore from '@/components/cross/LearnMore.vue';
import card from '@/components/cross/Card.vue';

export default {
  name: 'DeviceUsers',
  components: {
    learnMore,
    card,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
    maxQuantity: {
      type: Number,
      required: true,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      pagination: false,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('device-users', { findDeviceUsersInStore: 'find' }),
    users() {
      let uList;
      if (this.deviceId && this.currentCompany) {
        uList = this.findDeviceUsersInStore({
          query: {
            deviceId: this.deviceId,
            companyId: this.currentCompany.id,
            $sort: { createdAt: -1 },
          },
        });
      }
      return uList && uList.data ? uList.data : [];
    },
  },
  watch: {
    users(e) {
      this.pagination = e.length > this.maxQuantity;
    },
    deviceId() {
      this.fetchApi();
    },
  },
  created() {
    this.fetchApi();
  },
  methods: {
    ...mapActions('device-users', { findDeviceUsers: 'find' }),
    fetchApi() {
      if (!this.currentCompany) return;
      this.findDeviceUsers({
        query: {
          deviceId: this.deviceId,
          companyId: this.currentCompany.id,
          $sort: { createdAt: -1 },
        },
      });
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
