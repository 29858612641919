<template>
  <div>
    <div
      v-if="storeStorageDisks.length > 0"
      class="mb-3"
    >
      <card external-card="material-card">
        <b-collapse
          slot="external-header"
          :open="false"
        >
          <card
            slot="trigger"
            slot-scope="props"
            internal-card="card-header"
            style-internal-header="display:flex; width:100%"
            style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
          >
            <p
              slot="header"
              class="card-header-title header-title"
            >
              <span>
                Storage
              </span>
            </p>
            <a
              slot="body"
              class="card-header-icon"
            >
              <b-icon
                v-show="props.open"
                icon="menu-up"
              />
              <b-icon
                v-show="!props.open"
                icon="menu-down"
              />
            </a>
          </card>
          <card
            internal-card="material-card-content"
          >
            <b-table
              slot="header"
              :data="storeStorageDisks"
              :paginated="storeStorageDisks.length > maxQuantity"
              :per-page="maxQuantity"
              :mobile-cards="true"
              :striped="true"
              default-sort="name"
              style="margin-top:1rem; width: 100%"
              scrollable
              detailed
              :has-detailed-visible="(row) => storeDiskPartitions.filter
                (x => x.diskId === row.physicalId).length > 0"
              class="principal-table diskTable"
            >
              <b-table-column
                field="name"
                label="Disk"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>

              <b-table-column
                field="size"
                label="Size"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.size|bytesSize(toBytesMetrics()) }}
                </template>
              </b-table-column>

              <b-table-column
                :visible="storeDiskPartitions.some((x) => x.used !== null)"
                field="usedPercentDisk"
                label="Available"
              >
                <template v-slot="props">
                  <div class="progressBarDiv">
                    <b-progress
                      :type="changeProgressBarColorDisk(props.row)"
                      :value="getAvailableSpacePercentByDisk(true, props.row)"
                      show-value
                      format="percent"
                      style="width: 150px;"
                    >
                      <span style="color: #363636">
                        {{ getAvailableSpacePercentByDisk(false, props.row) }}%
                        ({{ getAvailableSpaceByDisk(
                          props.row)|bytesSize(toBytesMetrics()) }})
                      </span>
                    </b-progress>
                  </div>
                </template>
              </b-table-column>

              <b-table-column
                field="health"
                label="Health"
                sortable
              >
                <template v-slot="props">
                  <status-indicator
                    :status="changeColorHealth(props.row.health)"
                  />
                  {{ getValueFromEnum(props.row.health).name }}
                </template>
              </b-table-column>

              <b-table-column
                field="mediaType"
                label="Media Type"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.mediaType }}
                </template>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="partitionType"
                label="Partition table"
                sortable
              >
                <template
                  v-if="props.row.partitionType"
                >
                  {{ props.row.partitionType.toUpperCase() }}
                </template>
              </b-table-column>

              <b-table-column
                field="serialNum"
                label="Serial"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.serialNum }}
                </template>
              </b-table-column>

              <b-table-column
                field="manufacturer"
                label="Manufacturer"
                sortable
              >
                <template v-slot="props">
                  {{ props.row.manufacturer }}
                </template>
              </b-table-column>

              <template
                slot="detail"
                slot-scope="props"
              >
                <b-table
                  :data="storeDiskPartitions.filter(x => x.diskId === props.row.physicalId)"
                  :mobile-cards="false"
                  default-sort="position"
                  scrollable
                  class="inner-table"
                >
                  <b-table-column
                    v-if="device.OSVendor !== 'Apple'"
                    field="position"
                    label="Position"
                    sortable
                  >
                    <template v-slot="propsDetails">
                      {{ `${propsDetails.row.position || 'Unknown'} ` }}
                    </template>
                  </b-table-column>

                  <b-table-column
                    field="letter"
                    label="Volume"
                  >
                    <template v-slot="propsDetails">
                      {{ `${propsDetails.row.letter || propsDetails.row.tag}` }}
                    </template>
                  </b-table-column>

                  <b-table-column
                    field="capacity"
                    label="Size"
                    sortable
                  >
                    <template v-slot="propsDetails">
                      {{ propsDetails.row.capacity |bytesSize(toBytesMetrics()) }}
                    </template>
                  </b-table-column>

                  <b-table-column
                    :visible="storeDiskPartitions.some((x) => x.used !== null)"
                    field="usedPercentPartition"
                    label="Available"
                    sortable
                  >
                    <template v-slot="propsDetails">
                      <div
                        v-if="propsDetails.row.capacity > 0"
                        class="progressBarDiv"
                      >
                        <b-progress
                          :type="changeProgressBarColorPartition(
                            propsDetails.row.capacity, propsDetails.row.used)"
                          :value="getAvailableSpacePercentByPartition(
                            true, propsDetails.row.capacity, propsDetails.row.used)"
                          show-value
                          format="percent"
                        >
                          <span style="color: #363636">
                            {{ getAvailableSpacePercentByPartition(
                              false, propsDetails.row.capacity, propsDetails.row.used) }}%
                            ({{ (propsDetails.row.capacity - propsDetails.row.used)|
                              bytesSize(toBytesMetrics()) }})
                          </span>
                        </b-progress>
                      </div>
                    </template>
                  </b-table-column>

                  <b-table-column
                    v-if="storeDiskPartitions.some((x) => x.isSystemPartition)"
                    field="isSystemPartition"
                    label="System Partition"
                    sortable
                  >
                    <template v-slot="propsDetails">
                      <span v-if="propsDetails.row.isSystemPartition">
                        {{ propsDetails.row.isSystemPartition.toString() | capitalize }}
                      </span>
                    </template>
                  </b-table-column>

                  <b-table-column
                    v-if="props.row.partitionType === 'MBR'"
                    field="isActive"
                    label="Is Active"
                    sortable
                  >
                    <template v-slot="propsDetails">
                      {{ propsDetails.row.isActive.toString() | capitalize }}
                    </template>
                  </b-table-column>
                </b-table>
              </template>
            </b-table>
          </card>
        </b-collapse>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import parseEnum from '@/helpers/parseEnums';
import vtulEnums from '../../../../cross/index';

export default {
  name: 'DiskAndPartitions',
  components: {
    card,
  },
  props: {
    device: {
      type: Object,
      default() {
        return {};
      },
    },
    hasCapacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      diskHealthStatusEnumValuesArray: parseEnum.enumToArray(
        vtulEnums.enum.device.diskHealthStatus,
      ),
      diskHealthStatusEnumValues: vtulEnums.enum.device.diskHealthStatus,
      maxQuantity: 10,
      styleProgressBar: '',
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('disk-partitions', { findDiskPartitionsInStore: 'find' }),
    ...mapGetters('storage-disks', { findStorageDisksInStore: 'find' }),
    storeDiskPartitions() {
      let eList;
      if (this.device.id && this.currentCompany) {
        eList = this.findDiskPartitionsInStore({
          query: {
            deviceId: this.device.id,
            companyId: this.currentCompany.id,
          },
        });
      }
      return eList && eList.data ? eList.data : [];
    },
    storeStorageDisks() {
      let dList;
      if (this.device.id && this.currentCompany) {
        dList = this.findStorageDisksInStore({
          query: {
            deviceId: this.device.id,
            companyId: this.currentCompany.id,
          },
        });
      }
      return dList && dList.data ? dList.data : [];
    },
  },
  watch: {
    device() {
      this.fetchApi();
    },
  },
  created() {
    this.fetchApi();
  },
  methods: {
    ...mapActions('disk-partitions', { findDiskPartitions: 'find' }),
    ...mapActions('storage-disks', { findStorageDisks: 'find' }),
    fetchApi() {
      if (!this.currentCompany) return;
      this.findDiskPartitions({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
        },
      });
      this.findStorageDisks({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
        },
      });
    },
    getValueFromEnum(value) {
      const values = this.diskHealthStatusEnumValuesArray;
      return values.find((x) => x.dbValue === value);
    },
    storageUsedByDisk(physicalId) {
      let partitionsByDisk = this.storeDiskPartitions.filter((x) => x.diskId === physicalId);
      if (this.device.OSVendor === 'Apple') {
        const uniquePositions = {};
        partitionsByDisk = partitionsByDisk.filter((obj) => {
          if (!uniquePositions[obj.apfsContainer]) {
            uniquePositions[obj.apfsContainer] = true;
            return true;
          }
          return false;
        });
      }
      const storageUsedByPartitionArray = partitionsByDisk.map(
        (partition) => Number(partition.used),
      );
      if (storageUsedByPartitionArray.length === 0) return [];
      const storageUsedByDisk = storageUsedByPartitionArray.reduce(
        (usedStorage = 0, partition) => usedStorage + partition,
      );
      return storageUsedByDisk;
    },
    getAvailableSpaceByDisk({ physicalId, size }) {
      const storageUsedByDisk = this.storageUsedByDisk(physicalId);
      return size - storageUsedByDisk;
    },
    getAvailableSpacePercentByDisk(progressBar, data) {
      const availableSpaceByDisk = this.getAvailableSpaceByDisk(data);
      const percent = Math.floor((availableSpaceByDisk * 100) / data.size);
      if (progressBar && percent === 0) {
        return 5;
      }
      return percent;
    },
    getUsedSpacePercentByDisk(bar, data) {
      const usedStorageByDisk = this.storageUsedByDisk(data.physicalId);
      return this.getUsedSpacePercent(bar, data.size, usedStorageByDisk);
    },
    getUsedSpacePercent(bar, size, used) {
      const percent = Math.floor((used * 100) / size);
      return percent;
    },
    getAvailableSpacePercentByPartition(progressBar, capacity, used) {
      const availableSpace = capacity - used;
      const percent = Math.floor((availableSpace * 100) / capacity);
      if (progressBar && percent === 0) {
        return 5;
      }
      return percent;
    },
    changeProgressBarColorDisk(data) {
      const percent = this.getAvailableSpacePercentByDisk(false, data);
      return this.changeProgressBarColor(percent);
    },
    changeProgressBarColorPartition(capacity, used) {
      const percent = this.getAvailableSpacePercentByPartition(false, capacity, used);
      return this.changeProgressBarColor(percent);
    },
    changeProgressBarColor(percent) {
      if (percent <= 30 && percent > 10) return 'is-warning';
      if (percent <= 10) return 'is-danger';

      return 'is-success';
    },
    changeColorHealth(value) {
      const { dbValue } = this.getValueFromEnum(value);
      if (dbValue === this.diskHealthStatusEnumValues.OK.value.dbValue) return 'positive';
      if (dbValue === this.diskHealthStatusEnumValues.BAD.value.dbValue) return 'negative';
      if (dbValue === this.diskHealthStatusEnumValues.CAUTION.value.dbValue) return 'intermediary';

      return 'active';
    },
    toBytesMetrics() {
      return this.device.OSVendor === 'Apple' ? 'metric' : 'iec';
    },
  },
};
</script>
<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>

<style >
.principal-table  td{
  background-color: white;
}
.principal-table  td .progressBarDiv{
  margin-top: 5px;
}
.inner-table table {
  border-collapse: collapse !important;
}
.inner-table tr {
  border-bottom: 1px solid #dbdbdb;
}
</style>
