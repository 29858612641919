<template>
  <div
    v-if="selectedDevice"
    style="position:relative"
  >
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item
          :to="{ name: 'devices'}"
          tag="router-link"
        >
          Managed devices
        </b-breadcrumb-item>
        <b-breadcrumb-item
          active
          class="device-name"
        >
          {{ selectedDevice.name }}
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <portal to="navbar-right">
      <b-navbar-item tag="div">
        <b-tooltip
          label="Previous device"
          type="is-dark"
          position="is-bottom"
          append-to-body
        >
          <b-button
            type="is-white"
            @click="changeDevice(-1)"
          >
            <b-icon
              size="is-small"
              style="font-size: 24px;"
              icon="chevron-left"
            />
          </b-button>
        </b-tooltip>
        <b-tooltip
          label="Next device"
          type="is-dark"
          position="is-bottom"
          append-to-body
        >
          <b-button
            type="is-white"
            @click="changeDevice(1)"
          >
            <b-icon
              size="is-small"
              style="font-size: 24px;"
              icon="chevron-right"
            />
          </b-button>
        </b-tooltip>
      </b-navbar-item>
    </portal>
    <portal to="secondNavbarLeft">
      <div
        id="tabs-device-details"
        class="tabs"
      >
        <ul>
          <li
            v-for="tabUrl in tabUrls"
            :key="tabUrl.index"
            :class="{ 'is-active': activeTab === tabUrl.index }"
            active
          >
            <a
              v-if="tabUrl.visible"
              @click="activeTab = tabUrl.index"
            >{{ tabUrl.name }}</a>
          </li>
        </ul>
      </div>
    </portal>
    <portal to="secondNavbarRight">
      <div
        style="display:flex; align-items: center;"
      >
        <b-button
          v-if="validateIfHasPermission([
            permissions.RUN_EXECUTIONS.value.dbValue
          ])"
          id="start-execution-devicedetails"
          type="is-primary is-small"
          icon-left="lightning-bolt"
          @click="startExecution"
        >
          <span v-if="$screen.tablet">Start execution</span>
        </b-button>
        <b-dropdown
          v-if="validateIfHasPermission([permissions.EXPORT_REPORTS.value.dbValue])"
          v-show="selectedDevice"
          id="reports-devicedetails"
          position="is-bottom-left"
        >
          <button
            slot="trigger"
            class="button is-white"
          >
            <b-icon
              icon="dots-vertical"
            />
          </button>
          <b-dropdown-item
            custom
            class="title-dropdown"
          >
            <b>Export device to CSV</b>
          </b-dropdown-item>
          <b-dropdown-item
            :id="reportsNames.BASIC.value"
            @click="getCSVFromApi(reportsNames.BASIC.value, selectedDevice.id);
                    addLog(reportsNames.BASIC.value)"
          >
            <a
              class="item-dropdown"
            >Overview</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="validateIfHasCapability([planCapabilities.HARDWARE_INVENTORY.value,
                                           planCapabilities.DEVICES_MANAGEMENT.value])"
            :id="reportsNames.HARDWARE.value"
            @click="getCSVFromApi(reportsNames.HARDWARE.value, selectedDevice.id);
                    addLog(reportsNames.HARDWARE.value)"
          >
            <a
              class="item-dropdown"
            >Hardware</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="validateIfHasCapability([planCapabilities.SOFTWARE_INVENTORY.value,
                                           planCapabilities.DEVICES_MANAGEMENT.value])"
            :id="reportsNames.SOFTWARE.value"
            @click="getCSVFromApi(reportsNames.SOFTWARE.value, selectedDevice.id);
                    addLog(reportsNames.SOFTWARE.value)"
          >
            <a
              class="item-dropdown"
            >Software</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="selectedDevice.OSVendor === 'Microsoft'
              && validateIfHasCapability([planCapabilities.UPDATES_INVENTORY.value,
                                          planCapabilities.DEVICES_MANAGEMENT.value])"
            :id="reportsNames.UPDATES.value"
            @click="getCSVFromApi(reportsNames.UPDATES.value, selectedDevice.id);
                    addLog(reportsNames.UPDATES.value)"
          >
            <a
              class="item-dropdown"
            >Updates</a>
          </b-dropdown-item>
          <b-dropdown-item
            :id="reportsNames.NETWORK_INTERFACES.value"
            @click="getCSVFromApi(reportsNames.NETWORK_INTERFACES.value, selectedDevice.id);
                    addLog(reportsNames.NETWORK_INTERFACES.value)"
          >
            <a
              class="item-dropdown"
            >Network interfaces</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="validateIfHasCapability([planCapabilities.LOCATIONS.value])"
            :id="reportsNames.LOCATIONS.value"
            @click="getCSVFromApi(reportsNames.LOCATIONS.value, selectedDevice.id);
                    addLog(reportsNames.LOCATIONS.value)"
          >
            <a
              class="item-dropdown"
            >Geolocation</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="amtInfo && amtInfo.amtStatus !== amtStatus.UNCONFIGURED.value.dbValue
              && validateIfHasCapability([planCapabilities.AMT_MANAGEMENT.value])"
            :id="reportsNames.INTEL_AMT_LOGS.value"
            @click="getCSVFromApi(reportsNames.INTEL_AMT_LOGS.value, selectedDevice.id)
                    addLog(reportsNames.INTEL_AMT_LOGS.value)"
          >
            <a
              class="item-dropdown"
            >Intel® AMT logs</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="validateIfHasCapability([planCapabilities.DEVICES_MANAGEMENT.value,
                                           planCapabilities.SYSTEM_STATUS.value])
              && selectedDevice.OSVendor === 'Microsoft'"
            :id="reportsNames.DISKS.value"
            @click="getCSVFromApi(reportsNames.DISKS.value, selectedDevice.id);
                    addLog(reportsNames.DISKS.value)"
          >
            <a
              class="item-dropdown"
            >Disks partitions</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="validateIfHasCapability([planCapabilities.DISK_ENCRYPTION.value])
              && selectedDevice.OSVendor === 'Microsoft'"
            :id="reportsNames.BITLOCKER_REQUIREMENTS.value"
            @click="getCSVFromApi(reportsNames.BITLOCKER_REQUIREMENTS.value, selectedDevice.id);
                    addLog(reportsNames.BITLOCKER_REQUIREMENTS.value)"
          >
            <a
              class="item-dropdown"
            >BitLocker requirements</a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="validateIfHasCapability([planCapabilities.DISK_ENCRYPTION.value])
              && validateIfHasPermission([permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue])
              && selectedDevice.OSVendor === 'Microsoft'"
            :id="reportsNames.BITLOCKER_PASSWORDS.value"
            @click="getCSVFromApi(reportsNames.BITLOCKER_PASSWORDS.value, selectedDevice.id);
                    addLog(reportsNames.BITLOCKER_PASSWORDS.value)"
          >
            <a
              class="item-dropdown"
            >BitLocker passwords</a>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </portal>
    <div
      v-if="!loading"
      class="material-card-content pt-0"
    >
      <div
        class="is-desktop"
      >
        <device-overview
          v-show="activeTab === getTabById('overview').index"
          :device="selectedDevice"
          :amt-info="amtInfo"
        />

        <div v-show="activeTab === getTabById('inventory').index">
          <div class="mb-3">
            <devices-operating-system
              :device="selectedDevice"
            />
          </div>
          <div
            v-if="validateIfHasCapability([planCapabilities.HARDWARE_INVENTORY.value,
                                           planCapabilities.DEVICES_MANAGEMENT.value])"
            class="mb-3"
          >
            <device-hardware
              ref="deviceHardwares"
              :device-id="selectedDevice.id"
              :os-vendor="selectedDevice.OSVendor"
              :has-capacity="validateIfHasCapability([planCapabilities.HARDWARE_INVENTORY.value,
                                                      planCapabilities.DEVICES_MANAGEMENT.value])"
            />
          </div>
          <div
            v-if="(selectedDevice.OSVendor === 'Microsoft' || selectedDevice.OSVendor === 'Apple')
              && validateIfHasCapability([planCapabilities.SYSTEM_STATUS.value,
                                          planCapabilities.DEVICES_MANAGEMENT.value])"
          >
            <devices-disk-partitions
              ref="DiskPartitions"
              :device="selectedDevice"
              :has-capacity="validateIfHasCapability([planCapabilities.SYSTEM_STATUS.value,
                                                      planCapabilities.DEVICES_MANAGEMENT.value])"
            />
          </div>
          <div
            v-if="validateIfHasCapability([planCapabilities.AMT_MANAGEMENT.value])"
            class="mb-3"
          >
            <device-amt-details
              :device="selectedDevice"
              :amt-info="amtInfo"
              :has-capacity="validateIfHasCapability([planCapabilities.AMT_MANAGEMENT.value])"
            />
          </div>
          <div
            v-if="biosVendorIsSupported(selectedDevice.vendor)
              && validateIfHasCapability([planCapabilities.BOOT_ORDER.value])"
          >
            <devices-boot-order
              ref="BootOrder"
              :device="selectedDevice"
            />
          </div>
          <div
            v-if="validateIfHasCapability([planCapabilities.SOFTWARE_INVENTORY.value,
                                           planCapabilities.DEVICES_MANAGEMENT.value])"
            class="mb-3"
          >
            <device-software
              ref="deviceSoftwares"
              :max-quantity="inventoryQuantityPerPage"
              :device="selectedDevice"
              :has-capacity="validateIfHasCapability([planCapabilities.SOFTWARE_INVENTORY.value,
                                                      planCapabilities.DEVICES_MANAGEMENT.value])"
            />
          </div>

          <div class="mb-3">
            <network-interfaces
              ref="deviceNetworkInterfaces"
              :max-quantity="inventoryQuantityPerPage"
              :device="selectedDevice"
            />
          </div>
          <div class="mb-3">
            <device-users
              :max-quantity="25"
              :device-id="selectedDevice.id"
            />
          </div>
          <div class="mb-3">
            <card external-card="material-card">
              <b-collapse
                slot="external-header"
                :open="false"
                class="card"
              >
                <card
                  slot="trigger"
                  slot-scope="props"
                  internal-card="card-header"
                  style-internal-header="display:flex; width:100%"
                  style-internal-card="display:flex;flex-direction:row;
                    justify-content:space-between"
                >
                  <p
                    slot="header"
                    class="card-header-title header-title"
                  >
                    <span>
                      Device value
                    </span>
                    <learn-more url="" />
                  </p>
                  <a
                    slot="body"
                    class="card-header-icon"
                  >
                    <b-icon
                      v-show="props.open"
                      icon="menu-up"
                    />
                    <b-icon
                      v-show="!props.open"
                      icon="menu-down"
                    />
                  </a>
                </card>

                <card
                  internal-card="material-card-content"
                >
                  <div
                    slot="body"
                  >
                    <b-input
                      v-model="deviceValue"
                      v-autosize
                      type="input"
                      placeholder="Add computer value of this device"
                      maxlength="100"
                    />
                    <b-button
                      class="is-primary force-right"
                      @click="saveDeviceValue"
                    >
                      Save
                    </b-button>
                  </div>
                </card>
              </b-collapse>
            </card>
          </div>
        </div>

        <div v-show="activeTab === getTabById('location').index">
          <portal-target name="lock-device-button" />
          <device-location
            ref="deviceLocations"
            :max-quantity="20"
            :is-online="selectedDevice.status === deviceStatus.ONLINE.value.dbValue"
            :device-id="selectedDevice.id"
            :locations-selected="activeTab === 2"
            :location-bounds="selectedDevice.locationBounds"
          />
        </div>

        <device-remote-desktop
          v-show="activeTab === getTabById('remoteDesktop').index"
          ref="deviceRemoteDesktop"
          :amt-info="amtInfo"
          :device="selectedDevice"
        />

        <div v-show="activeTab === getTabById('security').index">
          <device-bitlocker
            v-if="selectedDevice.OSVendor === 'Microsoft' && parseFloat(selectedDevice.OSBuild)
              >= 10 && validateIfHasCapability([planCapabilities.DISK_ENCRYPTION.value]) &&
              validateIfHasPermission([ permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue])"
            ref="DeviceBitlocker"
            :device="selectedDevice"
          />
          <device-fileVault
            v-if="selectedDevice.OSVendor === 'Apple'
              && validateIfHasCapability([planCapabilities.DISK_ENCRYPTION.value]) &&
              validateIfHasPermission([ permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue])"
            ref="DeviceFileVault"
            :device="selectedDevice"
          />
          <password-management
            v-if="selectedDevice"
            :device="selectedDevice"
            :active-profile="activeProfile"
            :amt-info="amtInfo"
          />
          <div
            v-if="selectedDevice.OSVendor === 'Microsoft'
              && validateIfHasCapability([planCapabilities.SYSTEM_STATUS.value,
                                          planCapabilities.DEVICES_MANAGEMENT.value])"
          >
            <devices-security-features
              ref="DeviceStatus"
              :device="selectedDevice"
            />
          </div>
        </div>

        <div v-show="activeTab === getTabById('maintenance').index">
          <div
            v-if="selectedDevice.OSVendor === 'Microsoft'
              && validateIfHasCapability([planCapabilities.UPDATES_INVENTORY.value,
                                          planCapabilities.DEVICES_MANAGEMENT.value])"
            class="mb-3"
          >
            <device-updates
              ref="deviceUpdates"
              :max-quantity="inventoryQuantityPerPage"
              :device="selectedDevice"
              :has-capacity="validateIfHasCapability([planCapabilities.UPDATES_INVENTORY.value,
                                                      planCapabilities.DEVICES_MANAGEMENT.value])"
            />
          </div>

          <device-maintenance-actions
            v-if="validateIfHasPermission([permissions.POWER_EXECUTIONS.value.dbValue,])"
            class="mt-3"
            :device="selectedDevice"
            type="power"
          />

          <device-maintenance-actions
            v-if="validateIfHasPermission([permissions.OS_MANAGEMENT_EXECUTIONS.value.dbValue,])
              && (selectedDevice.OSVendor === 'Microsoft' || selectedDevice.OSVendor === 'linux')"
            class="mt-3"
            :device="selectedDevice"
            type="optimizations"
          />
        </div>

        <div v-show="activeTab === getTabById('amtLogs').index">
          <div
            v-if="amtInfo && amtInfo.amtStatus !== amtStatus.UNCONFIGURED.value.dbValue"
            class="mb-3"
          >
            <device-amt-logs
              ref="deviceIntelAmtLogs"
              :max-quantity="25"
              :device-id="selectedDevice.id"
            />
          </div>
        </div>

        <div
          v-show="activeTab === getTabById('notes').index"
          class="mb-3"
        >
          <device-log-notes :device-id="this.$route.params.deviceId" />
        </div>
      </div>
    </div>
    <b-loading
      v-model="isRemovingDevice"
      :is-full-page="true"
    />
    <portal-target name="modal-container" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';

import deviceHardware from '@/components/device/DeviceHardware.vue';
import deviceSoftware from '@/components/device/DeviceSoftware.vue';
import deviceUpdates from '@/components/device/DeviceUpdates.vue';
import deviceLocation from '@/components/device/DeviceLocation.vue';
import deviceNetworkInterfaces from '@/components/device/DeviceNetworkInterfaces.vue';
import deviceUsers from '@/components/device/DeviceUsers.vue';
import deviceAmtLogs from '@/components/device/DeviceAmtLogs.vue';
import deviceAmtDetails from '@/components/device/DeviceAmtDetails.vue';
import devicesOperatingSystem from '@/components/device/DevicesOperatingSystem.vue';
import deviceSecurityFeatures from '@/components/device/DeviceSecurityFeatures.vue';
import deviceBootOrder from '@/components/device/DeviceBootOrder.vue';
import DevicesDiskPartitions from '@/components/device/DevicesDiskPartitions.vue';
import card from '@/components/cross/Card.vue';
import DeviceRemoteDesktop from '@/components/device/DeviceRemoteDesktop.vue';
import learnMore from '@/components/cross/LearnMore.vue';
import PasswordManagement from '@/components/device/DevicePasswordManager.vue';
import DeviceOverview from '@/components/device/DeviceOverview.vue';
import DeviceBitlocker from '@/components/device/DeviceBitlocker.vue';
import DeviceFileVault from '@/components/device/DeviceFileVault.vue';
import DeviceMaintenanceActions from '@/components/device/DeviceMaintenanceActions.vue';
import DeviceLogNotes from '@/components/device/DeviceLogNotes.vue';

import ExecutionsMixin from '@/mixins/executions';
import ValidationsMixin from '@/mixins/validations';
import CompanyMixin from '@/mixins/company';
import RoleMixin from '@/mixins/roles';
import CsvMixin from '@/mixins/csv';
import analyticsMixin from '@/mixins/analytics';
import parseEnum from '@/helpers/parseEnums';
import crossHelper from '@/helpers/cross';

import bgEnums from '../../../cross/index';

const { locationDetectionMethod } = bgEnums.enum.location;
const { hardwareTypes } = bgEnums.enum.hardware;
const { reportsNames } = bgEnums.enum.report;
const { planCapabilities } = bgEnums.enum.planCapabilities;
const { permissions } = bgEnums.enum.roles;

export default {
  name: 'DeviceDetails',
  metaInfo() {
    let title = 'Device';
    if (this.selectedDevice) {
      title = `${this.selectedDevice.name} ${this.tabTitle}`;
    }
    return { title };
  },
  components: {
    'device-hardware': deviceHardware,
    'device-software': deviceSoftware,
    'device-updates': deviceUpdates,
    'device-location': deviceLocation,
    'network-interfaces': deviceNetworkInterfaces,
    'device-users': deviceUsers,
    'device-amt-logs': deviceAmtLogs,
    'device-amt-details': deviceAmtDetails,
    'devices-operating-system': devicesOperatingSystem,
    'devices-security-features': deviceSecurityFeatures,
    'devices-boot-order': deviceBootOrder,
    'devices-disk-partitions': DevicesDiskPartitions,
    'password-management': PasswordManagement,
    card,
    'device-remote-desktop': DeviceRemoteDesktop,
    learnMore,
    'device-overview': DeviceOverview,
    'device-bitlocker': DeviceBitlocker,
    'device-fileVault': DeviceFileVault,
    'device-maintenance-actions': DeviceMaintenanceActions,
    'device-log-notes': DeviceLogNotes,
  },
  mixins: [ExecutionsMixin, CompanyMixin, RoleMixin,
    CsvMixin, ValidationsMixin, analyticsMixin],
  data() {
    return {
      inventoryQuantityPerPage: 10,
      amtStatus: bgEnums.enum.amt.amtStatus,
      deviceStatus: bgEnums.enum.device.deviceStatus,
      activeTab: null,
      metrics: null,
      notes: '',
      deviceValue: '',
      reportsNames,
      parseEnum,
      crossHelper,
      tour: null,
      productTourStarted: false,
      rdTour: null,
      canStartRdTour: false,
      isRemovingDevice: false,
      antivirusEnumParser: parseEnum.enumToArray(bgEnums.enum.device.antivirusStatus),
      firewallEnumParser: parseEnum.enumToArray(bgEnums.enum.device.firewallStatus),
      planCapabilities,
      permissions,
      loading: false,
      activeProfile: undefined,
      tabTitle: '',
    };
  },
  computed: {
    ...mapGetters('amt-info', { getDeviceAMTInfoInStore: 'get' }),
    ...mapGetters('devices', { getDeviceInStore: 'get', findDevicesInStore: 'find' }),
    ...mapGetters('employee-profiles', { findEmployeeProfileInStore: 'find' }),
    amtInfo() {
      let amtInfo = null;
      const currentDevice = this.selectedDevice;
      if (currentDevice && currentDevice.amtInfoId) {
        amtInfo = this.getDeviceAMTInfoInStore(currentDevice.amtInfoId);
      }
      return amtInfo;
    },
    selectedDevice() {
      if (!this.$route.params.deviceId) {
        return null;
      }
      return this.getDeviceInStore(this.$route.params.deviceId);
    },
    storeDevices() {
      if (!this.currentCompany || this.loading) return [];
      const dev = this.findDevicesInStore({
        query: {
          companyId: this.currentCompany.id,
          enabled: true,
          $sort: { status: -1 },
        },
      });
      return dev.data;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    showRD() {
      return this.validateIfHasCapability([planCapabilities.REMOTE_DESKTOP.value])
        && this.validateIfHasPermission([permissions.DEVICE_REMOTE_DESKTOP.value.dbValue])
        && ((this.selectedDevice
          && this.selectedDevice.status === this.deviceStatus.ONLINE.value.dbValue
          && (this.selectedDevice.osArch === 'x64' || this.selectedDevice.OS.includes('Windows')))
          || (this.amtInfo && this.amtInfo.amtStatus === this.amtStatus.ONLINE.value.dbValue));
    },
    preparedProfiles() {
      const profiles = this.findEmployeeProfileInStore({
        query: {
          $sort: { name: 1 },
          $select: ['id', 'name', 'biosPasswordAutoChange', 'localAccountPasswordAutoChange'],
          companyId: this.currentCompany.id,
        },
      }).data;
      return profiles;
    },
    tabUrls() {
      return [
        {
          index: 0,
          id: 'overview',
          url: 'overview',
          name: 'Overview',
          icon: 'fa fa-info-circle',
          visible: this.validateIfHasPermission([
            permissions.DEVICE_DETAILS.value.dbValue,
          ]),
        },
        {
          index: 1,
          id: 'inventory',
          url: 'inventory',
          name: 'Inventory',
          visible: this.validateIfHasPermission([
            permissions.DEVICE_INVENTORY.value.dbValue,
          ]),
        },
        {
          index: 2,
          id: 'location',
          url: 'location',
          name: 'Geolocation',
          visible: this.validateIfHasCapability([
            planCapabilities.LOCATIONS.value,
          ]) && this.validateIfHasPermission([
            permissions.DEVICE_LOCATION.value.dbValue,
          ]),
        },
        {
          index: 3,
          id: 'remoteDesktop',
          url: 'remote',
          name: 'Remote support',
          visible: this.showRD,
        },
        {
          index: 4,
          id: 'security',
          url: 'security',
          name: 'Security',
          visible: this.selectedDevice
          && this.validateIfHasPermission([
            permissions.DEVICE_INVENTORY.value.dbValue,
            permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue,
            permissions.SEE_LOCAL_PASSWORDS.value.dbValue,
            permissions.LOCAL_PASSWORD_EXECUTIONS.value.dbValue,
            permissions.SEE_BIOS_PASSWORDS.value.dbValue,
            permissions.BIOS_MANAGEMENT_EXECUTIONS.value.dbValue,
            permissions.SEE_AMT_PASSWORDS.value.dbValue,
            permissions.AMT_MANAGEMENT_EXECUTIONS.value.dbValue,
          ]),
        },
        {
          index: 5,
          id: 'maintenance',
          url: 'maintenance',
          name: 'Maintenance',
          visible: this.validateIfHasCapability([
            planCapabilities.DEVICES_MANAGEMENT.value,
          ]) && this.selectedDevice && ((this.selectedDevice.OSVendor === 'Apple'
          && this.validateIfHasPermission([
            permissions.POWER_EXECUTIONS.value.dbValue,
          ])) || (this.selectedDevice.OSVendor !== 'Apple' && this.validateIfHasPermission([
            permissions.POWER_EXECUTIONS.value.dbValue,
            permissions.OS_MANAGEMENT_EXECUTIONS.value.dbValue,
            permissions.DEVICE_INVENTORY.value.dbValue,
            permissions.UPDATE_EXECUTIONS.value.dbValue,
          ]))),
        },
        {
          index: 6,
          id: 'amtLogs',
          url: 'amtlogs',
          name: 'Logs',
          visible: this.validateIfHasCapability([planCapabilities.AMT_MANAGEMENT.value])
            && this.validateIfHasPermission([permissions.DEVICE_AMT_LOGS.value.dbValue])
            && !!this.amtInfo
            && this.amtInfo.amtStatus !== this.amtStatus.UNCONFIGURED.value.dbValue,
        },
        {
          index: 7,
          id: 'notes',
          url: 'notes',
          name: 'Notes',
          visible: true,
        },
      ];
    },
  },
  watch: {
    activeTab(newTabIndex) {
      // this.startRDProductTour();
      if (this.$route.params.deviceId) {
        const newTab = this.getTabByIndex(newTabIndex);
        this.tabTitle = newTab.name;
        this.$router.replace(
          `/${this.$route.params.companyId}/device/${this.$route.params.deviceId}/${newTab.url}`,
        );
      }
    },
    selectedDevice(newDevice, oldDevice) {
      if (newDevice && oldDevice && newDevice.id !== oldDevice.id) {
        this.fetchApi();
      }
      this.notes = newDevice && newDevice.notes ? newDevice.notes : '';
      this.deviceValue = newDevice && newDevice.deviceValue ? newDevice.deviceValue : '';
    },
    tabUrls() {
      this.checkIfActiveTabIsAvailable();
    },
  },
  async created() {
    this.fetchApi();
    if (this.selectedDevice) {
      if (this.selectedDevice.notes) this.notes = this.selectedDevice.notes;
      if (this.selectedDevice.deviceValue) this.deviceValue = this.selectedDevice.deviceValue;
    }
    await this.fetchEmployeeProfiles();
    await this.findDevices({
      query: {
        companyId: this.currentCompany.id,
      },
    });
    this.metrics = [];
    // We only should start tour after load the current device
    // this.startProductTour();
  },
  beforeDestroy() {
    if (this.productTourStarted && this.tour) {
      this.tour.complete();
    }

    // Revert scrolling default behaviour
    document.documentElement.style.overflow = 'auto';
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.deviceRemoteDesktop) {
      this.$refs.deviceRemoteDesktop.forceDisconnectAll();
    }
    next();
  },
  async mounted() {
    // if (this.selectedDevice) {
    //   this.startProductTour();
    // }
    await this.findDevices({
      query: {
        companyId: this.currentCompany.id,
      },
    });
    this.setActiveTab();
    if (!this.$route.params.tab && this.$route.params.deviceId) {
      this.$router.replace(`/${this.$route.params.companyId}/device/${this.$route.params.deviceId}/${this.getTabByIndex(this.activeTab).url}`);
    }
  },
  methods: {
    ...mapActions('devices', { getDevice: 'get', patchDeviceInfo: 'patch', findDevices: 'find' }),
    ...mapActions('devices', ['updateSelectedDevices']),
    ...mapActions('employee-profiles', { findEmployeeProfiles: 'find' }),
    ...mapActions('amt-info', { getDeviceAMTInfo: 'get' }),
    ...mapActions('user', { patchUser: 'patch' }),
    ...mapActions('executions', { findExecution: 'find' }),
    ...mapActions('emit-to-agent', { emitToAgent: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    async fetchApi() {
      if (!this.$route.params.deviceId) {
        return;
      }
      if (!this.selectedDevice) {
        this.loading = true;
      }
      const device = await this.getDevice([this.$route.params.deviceId, {
        query: {
          enabled: true,
          companyId: this.currentCompany.id,
        },
      }]);
      this.loading = false;
      if (!device) {
        return;
      }
      this.getDeviceProfile(device);
      this.setActiveTab();
      this.checkIfActiveTabIsAvailable();
      if (!device.amtInfoId) {
        return;
      }
      this.getDeviceAMTInfo(device.amtInfoId);
    },
    async fetchEmployeeProfiles(skip) {
      if (!this.currentCompany) return;
      let skipGroups = skip;
      if (!skipGroups) skipGroups = 0;

      const query = {
        $sort: { name: 1 },
        $skip: skipGroups,
        $limit: 50,
        companyId: this.currentCompany.id,
      };

      const { total, data } = await this.findEmployeeProfiles({
        query,
      });
      this.total = total;
      skipGroups += data.length;
      if (skipGroups < total) {
        await this.fetchEmployeeProfiles(skipGroups);
      }
    },

    changeDevice(direction) {
      // -1 previus // 1 next
      let deviceIndex = this.storeDevices.findIndex((x) => x.id === this.selectedDevice.id);
      deviceIndex += direction;

      if (deviceIndex < 0) {
        deviceIndex = this.storeDevices.length - 1;
      } else if (deviceIndex >= this.storeDevices.length) {
        deviceIndex = 0;
      }
      if (this.storeDevices[deviceIndex].id) {
        this.$router.push(`/${this.$route.params.companyId}/device/${this.storeDevices[deviceIndex].id}/${this.getTabByIndex(this.activeTab).url}`);
      }
    },
    async saveNotes() {
      if (!this.selectedDevice) return;
      try {
        await this.patchDeviceInfo([this.selectedDevice.id, {
          notes: this.notes,
          companyId: this.currentCompany.id,
        }]);
        toastMessage.showSuccess('The notes were saved successfully.');
      } catch (error) {
        toastMessage.showError('Unexpected error saving the notes.');
        throw error;
      }
    },
    async saveDeviceValue() {
      if (!this.selectedDevice) return;
      try {
        await this.patchDeviceInfo([this.selectedDevice.id, {
          deviceValue: this.deviceValue,
          companyId: this.currentCompany.id,
        }]);
        const user = await this.$store.getters['auth/user'];
        const log = {
          user: user.email,
          action: `Device value changed to: ${this.deviceValue}`,
          companyId: this.currentCompany.id,
          deviceId: this.$route.params.deviceId,
        };
        await this.createAdminLog(log);
        toastMessage.showSuccess('The device value was saved successfully.');
      } catch (error) {
        toastMessage.showError('Unexpected error saving the device value.');
        throw error;
      }
    },
    getTabIndex(tabUrl) {
      const defaultTab = this.getTabByIndex(0);
      if (!tabUrl) return defaultTab.index;
      const tab = this.tabUrls.find((x) => x.url === tabUrl.toLowerCase());
      if (tab) return tab.index;
      if (this.$route.params.deviceId) {
        this.$router.replace(
          `/${this.$route.params.companyId}/device/${this.$route.params.deviceId}/${defaultTab.url}`,
        );
      }
      return defaultTab.index;
    },
    getTabByIndex(tabIndex) {
      return this.tabUrls.find((x) => x.index === tabIndex);
    },
    getTabById(tabId) {
      return this.tabUrls.find((x) => x.id === tabId);
    },
    getDetectionMethod(data) {
      let tempData;
      const newData = data;
      newData.forEach((elementData, index) => {
        tempData = JSON.parse(JSON.stringify(elementData));
        switch (tempData.detectionMethod) {
          default:
          case locationDetectionMethod.UNKNOWN.value:
            tempData.detectionMethod = 'Unknown';
            break;
          case locationDetectionMethod.IP_ADDRESS.value:
            tempData.detectionMethod = 'IP Address';
            break;
          case locationDetectionMethod.NATIVE.value:
            tempData.detectionMethod = 'Native';
            break;
          case locationDetectionMethod.WIFI.value:
            tempData.detectionMethod = 'Wifi';
            break;
          case locationDetectionMethod.NEARBY_DEVICES.value:
            tempData.detectionMethod = 'Nearby Devices';
            break;
        }
        newData[index] = tempData;
      });
      return newData;
    },
    getMetricOfCapacity(type) {
      const isApple = this.selectedDevice.OSVendor === 'Apple';
      switch (type) {
        case hardwareTypes.MEMORY.value.dbValue:
          return ('iec');
        case hardwareTypes.STORAGE.value.dbValue:
        case hardwareTypes.GRAPHICS_CONTROLLER.value.dbValue:
          return (isApple ? 'metric' : 'iec');
        default:
          return 'metric';
      }
    },
    startProductTour() {
      if (!this.user.productTourStatus.deviceDetailsTour && !this.productTourStarted) {
        this.tour = this.$shepherd({
          useModalOverlay: true,
        });
        this.productTourStarted = true;
        const firstStepButtons = [];
        if (this.showRD) {
          firstStepButtons.push({
            classes: 'button is-small is-light',
            text: 'Skip',
            action: this.endTour,
          });
        }
        firstStepButtons.push({
          classes: 'button is-small is-primary button-to-right',
          text: this.showRD ? 'Next' : 'Done',
          action: this.showRD ? this.tour.next : this.endTour,
        });
        this.tour.addStep({
          attachTo: {
            element: 'ul',
            on: 'top',
          },
          classes: 'product-tour-correction-in-x',
          text: 'Here you can see details of the device',
          isCentered: false,
          scrollTo: true,
          buttons: firstStepButtons,
        });
        if (this.showRD) {
          this.tour.addStep({
            attachTo: { element: '#remoteDesktop', on: 'top' },
            classes: 'product-tour-correction-in-x',
            text: 'Here you can access this device remotely, click here.',
            isCentered: false,
            buttons: [
              {
                classes: 'button is-small is-outlined is-primary button-to-right',
                text: 'Back',
                action: this.tour.back,
              },
              {
                classes: 'button is-small is-primary',
                text: 'Next',
                action: () => {
                  this.tour.complete();
                  this.canStartRdTour = true;
                  // this.startRDProductTour();
                },
              },
            ],
          });
        }
        this.tour.start();
      }
    },
    startRDProductTour() {
      if (this.activeTab === 3 && this.canStartRdTour) {
        this.rdTour = this.$shepherd({
          useModalOverlay: true,
        });
        this.rdTour.addStep({
          attachTo: { element: '#wrtc-connect', on: 'top' },
          classes: 'product-tour-correction-in-x',
          text: 'To start a remote desktop connection, click this button.',
          isCentered: false,
          buttons: [
            {
              classes: 'button is-small is-light',
              text: 'Skip',
              action: () => {
                this.rdTour.complete();
                this.updateProductTourStatus();
              },
            },
            {
              classes: 'button is-small is-outlined is-primary button-to-right',
              text: 'Back',
              action: this.rdTour.back,
            },
            {
              classes: 'button is-small is-primary',
              text: 'Next',
              action: this.rdTour.next,
            },
          ],
        });
        this.rdTour.addStep({
          attachTo: { element: '#audio-call-disconnect', on: 'top' },
          classes: 'product-tour-correction-in-x',
          text: 'Also you can add audio to your remote connection!.',
          isCentered: false,
          buttons: [
            {
              classes: 'button is-small is-outlined is-primary button-to-right',
              text: 'Back',
              action: this.rdTour.back,
            },
            {
              classes: 'button is-small is-primary',
              text: 'Done',
              action: () => {
                this.rdTour.complete();
                this.updateProductTourStatus();
              },
            },
          ],
        });
        this.rdTour.start();
      }
    },
    endTour() {
      this.productTourStarted = false;
      this.tour.complete();
      this.updateProductTourStatus();
    },
    async updateProductTourStatus() {
      this.user.productTourStatus.deviceDetailsTour = true;
      this.patchUser([this.user.id, {
        productTourStatus: this.user.productTourStatus,
      }]);
    },
    async getDeviceProfile(device) {
      const query = {
        companyId: device.companyId,
      };
      if (device.employeeProfileId) {
        query.id = device.employeeProfileId;
      } else {
        query.isDefaultProfile = true;
      }
      const profile = await this.findEmployeeProfiles({ query });
      const profileData = profile.data.length === 1 ? profile.data[0] : { id: null, name: '' };
      this.activeProfile = profileData;
    },
    setActiveTab() {
      this.activeTab = this.getTabIndex(this.$route.params.tab);
    },
    checkIfActiveTabIsAvailable() {
      const activeTab = this.getTabByIndex(this.activeTab);
      if (activeTab && !activeTab.visible && this.$route.params.deviceId) {
        const defaultTab = this.getTabByIndex(0);
        this.activeTab = defaultTab.index;
        this.$router.replace(
          `/${this.$route.params.companyId}/device/${this.$route.params.deviceId}/${defaultTab.url}`,
        );
      }
    },
    startExecution() {
      this.updateSelectedDevices({
        devices: [this.selectedDevice.id],
        companyId: this.currentCompany.id,
      });
      this.$router.push({ name: 'executedevice', params: { actionTab: 0, tabPage: 1 } });
    },
    addLog(report) {
      const user = this.$store.getters['auth/user'];
      const log = {
        user: user.email,
        action: `${this.selectedDevice.name}'s ${report} report exported.`,
        companyId: this.currentCompany.id,
      };
      this.createAdminLog(log);
    },
  },
};
</script>

<style>
.field:not(:last-child) {
  margin-bottom: 0.2rem;
}

.group-dropdown .dropdown-content{
  max-height: 160px !important;
}

#tabs-device-details {
  height: 100%;
}

#tabs-device-details.tabs ul {
  flex-shrink: 1;
  flex-wrap: wrap;
  border-bottom-color: transparent;
}

#tabs-device-details.tabs ul > li {
  align-self: flex-end;
}

.header-title {
  font-weight: 400;
  font-size: 18px;
}

.device-name {
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
  cursor: text;
}
</style>
