<template>
  <card
    external-card="card material-card material-card-overflow"
    internal-card="material-card-content internal-card-file-transfer"
    url-help=""
  >
    <p
      slot="header"
      class="subtitle"
    >
      <b>{{ title }}</b>
    </p>
    <div slot="body">
      <div class="header-container">
        <b-breadcrumb>
          <b-breadcrumb-item
            v-for="breadcrumb in breadcrumbs"
            :key="breadcrumb.index"
            @click="() => getBackTo(breadcrumb)"
          >
            {{ breadcrumbs.length > 1 && breadcrumb.name === breadcrumbs[1].name
              && breadcrumb.name !== '' ?
                `Local Disk (${breadcrumb.name})` : breadcrumb.name }}
          </b-breadcrumb-item>
        </b-breadcrumb>
        <b-tooltip
          label="Refresh"
          type="is-dark"
          position="is-bottom"
        >
          <b-button
            type="is-white"
            @click="onNavigateTo(pathToDir)"
          >
            <b-icon
              icon="refresh"
            />
          </b-button>
        </b-tooltip>
      </div>
      <b-table
        v-if="folders.length >= 1 && customMessage === null"
        :data="folders"
        :selected.sync="selected"
        :sticky-header="true"
        :mobile-cards="false"
        :hoverable="true"
      >
        <b-table-column
          v-slot="props"
          field="name"
          label="Name"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="type"
          label="Type"
          sortable
        >
          {{ props.row.type }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="size"
          label="Size"
          sortable
        >
          <span v-if="props.row.size">
            {{ props.row.size |bytesSize('iec') }}
          </span>
        </b-table-column>
      </b-table>
      <div
        v-else-if="customMessage !== null && noHardDiskAccessMessage(customMessage)"
        style="padding: 60px 0px;"
      >
        <p style="font-size: 20px;margin: 12px;">
          Full Disk access permissions are disabled, enable them to get access in current folder.
        </p>
        <button
          :class="{ 'button': true, 'is-primary': true}"
          @click="crossHelper.openExternalLink('https://help.boardgent.com/en/articles/6719630-how-to-enable-file-transfer-on-mac', true)"
        >
          <span>Learn how to enable disk access permissions</span>
        </button> <br>
        <br> <button
          :class="{ 'button': true, 'is-primary': true}"
          @click="reviewDiskAccessPermissions"
        >
          <span>Check permissions</span>
        </button>
      </div>
      <p v-else>
        The selected folder is empty
      </p>
      <b-loading
        :is-full-page="false"
        :active="isGettingFolder || (breadcrumbs.length <= 1 && folders.length < 1)"
        :can-cancel="false"
      />
      <div
        v-if="transferingFile || progressBarMessage !== ''"
        style="display:flex; width:100%; justify-content: space-between; padding-right:10px"
        class="mt-3 mb-3"
      >
        <div style="align-self:center; flex: 1">
          <b-progress
            v-if="progressBarMessage"
            type="is-primary"
            :size="'is-large'"
            show-value
          >
            {{ progressBarMessage }}
          </b-progress>
          <b-progress
            v-else
            type="is-primary"
            :size="'is-large'"
            :value="percentageFileTransferred"
            show-value
          >
            Transfer in progress {{ percentageFileTransferred.toFixed(1) }}%
          </b-progress>
        </div>
        <div>
          <b-button
            style="margin-left:10px;"
            class="is-secondary"
            :disabled="progressBarMessage !== ''"
            @click.once="() => cancelTransferFile()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </div>
    <footer
      v-if="!fileExistInRemote"
      slot="footer"
      class="card-footer has-text-centered modal-card-footer"
    >
      <b-field
        class="file is-primary my-input force-right"
        :class="!isInsideFolder || transferingFile || progressBarMessage !== ''
          || customMessage !== null || isReadOnly
          ? 'upload-disabled' : ''"
      >
        <b-upload
          :key="count"
          v-model="file"
          :disabled="!isInsideFolder
            || transferingFile
            || progressBarMessage !== ''
            || customMessage !== null
            || isReadOnly"
          class="file-label"
        >
          <span class="file-cta">
            <b-icon
              class="file-icon"
              icon="upload"
            />
            <span class="file-label">Send</span>
          </span>
        </b-upload>
      </b-field>
      <b-tooltip
        :active="selectedBigFile"
        style="margin-left: 0.5em;"
        label="Currently we cannot send files larger than 1GB"
        type="is-dark"
        position="is-bottom"
      >
        <b-button
          :disabled="!selectedFile || selectedBigFile || transferingFile
            || progressBarMessage !== ''"
          class="is-primary"
          style="display:flex;align-items:baseline;"
          @click="() => requestSelectedFile()"
        >
          <b-icon
            icon="download"
          />
          <span style="position: relative;top: -3px;">Receive</span>
        </b-button>
      </b-tooltip>
      <button
        class="button is-outlined is-primary"
        style="margin-left: 0.5em;"
        type="button"
        @click="$parent.close()"
      >
        Close
      </button>
    </footer>
    <footer
      v-else
      slot="footer"
      class="card-footer has-text-centered modal-card-footer"
    >
      <p
        style="padding-top: 8px;"
        class="has-text-weight-medium"
      >
        The selected file already exists in the path. Do you want to transfer anyway?
      </p>
      <div class="force-right">
        <b-button
          class="is-primary"
          style="display:flex;align-items:baseline;"
          @click="() => { fileExistInRemote = false; onSendToRemoteFile(file);}"
        >
          Transfer
        </b-button>
        <b-button
          class="is-primary"
          style="display:flex;align-items:baseline;"
          outlined
          @click="() => fileExistInRemote = false"
        >
          Cancel
        </b-button>
      </div>
    </footer>
  </card>
</template>
<script>
import card from '@/components/cross/Card.vue';
import snackBarMessage from '@/helpers/snackBarMessage';
import crossHelper from '@/helpers/cross';
import vtulCross from '../../../../cross/index';

const fileTypesEnum = vtulCross.enum.remoteDesktop.fileTypes;
const { customMessage: customMessageEnum } = vtulCross.enum.remoteDesktop;

export default {
  name: 'ModalConfirmation',
  components: {
    card,
  },
  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    deviceName: {
      type: String,
      default: 'Remote computer',
    },
    folders: {
      type: Array || Function,
      default: [],
    },
    customMessage: {
      type: Number,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    pathToDir: {
      type: String,
      default: '',
    },
    transferingFile: {
      type: Boolean,
      default: false,
    },
    progressBarMessage: {
      type: String,
      default: '',
    },
    percentageFileTransferred: {
      type: Number,
      default: 0,
    },
    isCanceling: {
      type: Boolean,
      default: false,
    },
    onGetRemoteFile: {
      type: Function,
      default: () => {},
    },
    onSendToRemoteFile: {
      type: Function,
      default: () => {},
    },
    onCancelFileTransfer: {
      type: Function,
      default: () => {},
    },
    onNavigateTo: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      file: null,
      activeTab: 0,
      selected: null,
      selectedFile: null,
      selectedBigFile: false,
      fileExistInRemote: false,
      isInsideFolder: false,
      isGettingFolder: false,
      maxSizeLimit: 1 * 1000 * 1000 * 1000, // 1GB
      count: 0,
      crossHelper,
    };
  },
  computed: {
    breadcrumbs() {
      const route = [
        {
          index: 0,
          name: this.deviceName,
        },
      ];

      route.push(...this.pathToDir.split('\\').map((x, i) => ({
        index: i + 1,
        name: x,
      })));
      return route;
    },
  },
  watch: {
    selected() {
      if (this.selected.type === fileTypesEnum.FILE.value) {
        this.selectedFile = this.selected.name;
        this.selectedBigFile = this.selected.size > this.maxSizeLimit;
      } else {
        this.selectedFile = null;
        this.isGettingFolder = true;
        this.onNavigateTo(`${this.pathToDir}${this.selected.name}\\`);
      }
    },
    breadcrumbs() {
      this.breadcrumbs.pop();
      this.isInsideFolder = this.breadcrumbs.length > 1;
    },
    folders() {
      this.isGettingFolder = false;
    },
    file() {
      this.sendSelectedFile(this.file);
      this.count += 1;
    },
  },
  mounted() {
    this.isGettingFolder = this.folders.length <= 0;
    const initialBreadCrumbs = this.breadcrumbs;
    initialBreadCrumbs.pop();
    this.isInsideFolder = initialBreadCrumbs.length > 1;
  },
  methods: {
    requestSelectedFile() {
      const selectedFile = this.selected.name;
      this.onGetRemoteFile(selectedFile);
    },
    sendSelectedFile(file) {
      if (file.size > this.maxSizeLimit) {
        snackBarMessage.showError('Currently we cannot send files larger than 1GB.');
      } else if (this.folders.some((remoteFolder) => remoteFolder.name === file.name)) {
        this.fileExistInRemote = true;
      } else {
        this.onSendToRemoteFile(file);
      }
    },
    cancelTransferFile() {
      this.onCancelFileTransfer({
        cancelTransferFile: true,
      });
    },
    getBackTo(folderItem) {
      this.isGettingFolder = true;
      let finalPath = '';
      for (let i = 1; i <= folderItem.index; i += 1) {
        finalPath += `${this.breadcrumbs[i].name}\\`;
      }
      this.onNavigateTo(finalPath);
    },
    reviewDiskAccessPermissions() {
      this.onNavigateTo(this.pathToDir);
    },
    noHardDiskAccessMessage(message) {
      return message === customMessageEnum.NO_FULL_HARD_DISK_ACCESS.value;
    },
  },
};
</script>

<style>
.modal footer button:nth-child(2){
    margin-left: 1rem;
}
@media (max-width: 768px) {
  .modal {
    padding: 1rem;
  }
}
.progress-container {
  margin: auto;
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
}

.file {
  border-radius: 4px;
}

.my-input {
  width: max-content;
}

.internal-card-file-transfer {
  max-width: 90vw;
  min-width: 40vw
}

.header-container {
  display: flex;
  justify-content: space-between;
  height: 2.25rem;
}

.upload-disabled span{
  cursor: not-allowed;
}

.upload-disabled {
  opacity: 0.5;
}

table td:not([align]){
  text-align: left;
}

table td{
  cursor: pointer;
}
</style>
