export default {
  isChrome() {
    return (navigator.userAgent.indexOf('Chrome') !== -1) && !this.isOpera() && !this.isEdge();
  },
  isOpera() {
    return navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1;
  },
  isEdge() {
    return navigator.userAgent.indexOf('Edge/') !== -1 || navigator.userAgent.indexOf('Edg/') !== -1;
  },
  isFirefox() {
    return navigator.userAgent.indexOf('Firefox') !== -1;
  },
  isSafari() {
    return navigator.userAgent.indexOf('Safari') !== -1 && !this.isChrome() && !this.isEdge() && !this.isOpera();
  },
};
