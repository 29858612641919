<template>
  <card external-card="material-card">
    <b-collapse
      slot="external-header"
      :open="false"
      class="card"
    >
      <card
        slot="trigger"
        slot-scope="props"
        internal-card="card-header"
        style-internal-header="display:flex; width:100%"
        style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
      >
        <p
          slot="header"
          class="card-header-title header-title"
        >
          <span>
            Network
          </span>
          <learn-more url="" />
        </p>
        <a
          slot="body"
          class="card-header-icon"
        >
          <b-icon
            v-show="props.open"
            icon="menu-up"
          />
          <b-icon
            v-show="!props.open"
            icon="menu-down"
          />
        </a>
      </card>

      <card
        internal-card="material-card-content"
      >
        <div
          slot="body"
        >
          <div
            v-if="device.publicIp"
          >
            <strong> Public IP:</strong> {{ device.publicIp }}
            <br>
            <br>
          </div>
          <strong> Network interfaces </strong>
          <b-table
            slot="body"
            :data="networkInterfaces"
            :paginated="networkInterfaces.length > maxQuantity"
            :per-page="maxQuantity"
            :mobile-cards="false"
            default-sort="name"
            style="flex:1"
          >
            <b-table-column
              field="displayName"
              label="Name"
              sortable
            >
              <template v-slot="props">
                {{ props.row.displayName }}
              </template>
            </b-table-column>

            <b-table-column
              field="macAddress"
              label="MAC address"
              sortable
            >
              <template v-slot="props">
                {{ props.row.macAddress | uppercase }}
              </template>
            </b-table-column>

            <b-table-column
              field="IPv4"
              label="IPv4"
              sortable
            >
              <template v-slot="props">
                {{ props.row.IPv4 }}
              </template>
            </b-table-column>

            <b-table-column
              field="IPv6"
              label="IPv6"
              sortable
            >
              <template v-slot="props">
                {{ props.row.IPv6 }}
              </template>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="file"
                      size="is-large"
                    />
                  </p>
                  <p>There are no network interfaces to show</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </card>
    </b-collapse>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import learnMore from '@/components/cross/LearnMore.vue';

export default {
  name: 'DeviceNetworkInterfaces',
  components: {
    card,
    learnMore,
  },
  props: {
    device: {
      type: Object,
      default() {
        return { };
      },
    },
    maxQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pagination: false,
      publicIp_address: '',
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('network-interfaces', { findDeviceNetworkinterfacesInStore: 'find' }),
    networkInterfaces() {
      let nList;
      if (this.device.id && this.currentCompany) {
        nList = this.findDeviceNetworkinterfacesInStore({
          query: {
            companyId: this.currentCompany.id,
            deviceId: this.device.id,
            $sort: { createdAt: -1 },
          },
        });
      }
      return nList && nList.data ? nList.data : [];
    },
  },
  watch: {
    device() {
      this.fetchApi();
    },
  },
  created() {
    this.fetchApi();
  },
  methods: {
    ...mapActions('network-interfaces', { findDeviceNetworkinterfaces: 'find' }),
    async fetchApi(skip = 0) {
      if (!this.currentCompany) return;
      const { total, data } = await this.findDeviceNetworkinterfaces({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          $skip: skip,
          $limit: 50,
        },
      });

      const totalGet = skip + data.length;
      if (totalGet < total) {
        this.fetchApi(totalGet);
      }
    },
  },
};
</script>

<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
</style>
