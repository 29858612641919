<template>
  <div
    v-if="validateIfHasCapability([planCapabilities.DISK_ENCRYPTION.value])"
    class="mb-3"
  >
    <card external-card="material-card-overflow">
      <b-collapse
        slot="external-header"
        :open="false"
        @open="isCardOpen = true"
        @close="isCardOpen = false"
      >
        <card
          slot="trigger"
          slot-scope="props"
          :internal-card="isCardOpen ? 'card-header' : ''"
          style-internal-header="display:flex; width:100%"
          style-internal-card="display:flex;flex-direction:row;justify-content:space-between"
        >
          <p
            slot="header"
            class="card-header-title header-title"
          >
            BitLocker Device Encryption
          </p>
          <a
            slot="body"
            class="card-header-icon"
          >
            <b-icon
              v-show="props.open"
              icon="menu-up"
            />
            <b-icon
              v-show="!props.open"
              icon="menu-down"
            />
          </a>
        </card>
        <card internal-card="material-card-content">
          <div
            slot="body"
          >
            <p class="informationText">
              <b-icon
                icon="checkbox-blank-circle"
                :type="bitLockerCanBeEnabled.color"
                size="is-small"
              />
              <strong>
                {{ bitLockerCanBeEnabled.message }}
              </strong>
            </p>
            <div class="columns">
              <div class="column">
                <p class="informationText">
                  <b-icon
                    icon="checkbox-blank-circle"
                    :type="bitlockerRequirements.tpm === requirementStatusEnum.GOOD.value
                      ? 'is-success' : 'is-danger'"
                    size="is-small"
                  />
                  <span v-if="deviceHardwareInStore.length > 0 && deviceHardwareInStore[0].version">
                    TPM {{ parseFloat(deviceHardwareInStore[0].version).toFixed(1) }}
                  </span>
                  <span v-else>
                    TPM 1.2 or higher
                  </span>
                </p>
                <p
                  v-if="bitlockerRequirements.uefi !== undefined"
                  class="informationText"
                >
                  <b-icon
                    icon="checkbox-blank-circle"
                    :type="bitlockerRequirements.uefi === requirementStatusEnum.GOOD.value
                      ? 'is-success' : 'is-danger'"
                    size="is-small"
                  />
                  <span> UEFI boot type </span>
                </p>
                <p class="informationText">
                  <b-icon
                    icon="checkbox-blank-circle"
                    :type="bitlockerRequirements.bitlockerInstalled
                      === requirementStatusEnum.GOOD.value ? 'is-success' : 'is-danger'"
                    size="is-small"
                  />
                  <span> BitLocker installed</span>
                </p>
              </div>
              <div class="column">
                <p
                  v-if="bitlockerRequirements.gpt !== undefined"
                  class="informationText"
                >
                  <b-icon
                    icon="checkbox-blank-circle"
                    :type="bitlockerRequirements.gpt === requirementStatusEnum.GOOD.value
                      ? 'is-success' : 'is-danger'"
                    size="is-small"
                  />
                  <span> GPT partition table</span>
                </p>
                <p class="informationText">
                  <b-icon
                    icon="checkbox-blank-circle"
                    :type="bitlockerRequirements.systemPartition
                      === requirementStatusEnum.GOOD.value ? 'is-success' : 'is-danger'"
                    size="is-small"
                  />
                  <span> System partition created</span>
                </p>
              </div>
            </div>
            <div
              v-if="bitLockerCanBeEnabled.encryptable && diskPartitionsInStore.length > 0"
            >
              <div
                class="mb-5"
                style="display: flex; flex-wrap: wrap; gap: 8px"
              >
                <b-tooltip
                  :active="!bitLockerCanBeEnabled.osEncryptable"
                  :label="bitLockerCanBeEnabled.messageLock"
                  position="is-top"
                  type="is-dark"
                  class="lock-tooltip"
                >
                  <b-button
                    v-if="validateIfHasPermission(
                      [permissions.GEOLOCATION_AND_LOCK_EXECUTIONS.value.dbValue]
                    )"
                    :disabled=" !bitLockerCanBeEnabled.osEncryptable"
                    class="is-danger"
                    @click="confirmLockDevice"
                  >
                    Lock device
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  :active="diskPartitionsInStore.filter(x => x.letter !== null)
                    .every(x=> x.bitlockerStatus === bitlockerStatusEnum.FullyDecrypted.value)"
                  label="There are no encrypted partitions to get passwords from"
                  position="is-top"
                  type="is-dark"
                  class="lock-tooltip"
                >
                  <b-button
                    v-if="validateIfHasPermission(
                      [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                    )"
                    class="button is-secondary"
                    :disabled="diskPartitionsInStore.filter(x => x.letter !== null)
                      .every(x=> x.bitlockerStatus === bitlockerStatusEnum.FullyDecrypted.value)"
                    @click="getBitlockerKeys()"
                  >
                    Refresh passwords
                  </b-button>
                </b-tooltip>
                <b-button
                  v-if="validateIfHasPermission(
                    [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                  )"
                  class="button is-primary"
                  @click="refreshRequirements()"
                >
                  Refresh requirements
                </b-button>
                <b-button
                  v-if="validateIfHasPermission(
                    [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                  ) && diskPartitionsInStore.filter(x => x.letter !== null)
                    .some(x=> x.bitlockerStatus !== bitlockerStatusEnum.FullyEncrypted.value &&
                      x.bitlockerStatus !== bitlockerStatusEnum.EncryptionInProgress.value)"
                  class="button is-primary"
                  @click="encrypt()"
                >
                  Encrypt all
                </b-button>
                <b-button
                  v-if="validateIfHasPermission(
                    [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                  ) && diskPartitionsInStore.filter(x => x.letter !== null)
                    .some(x=> x.bitlockerStatus !== bitlockerStatusEnum.FullyDecrypted.value &&
                      x.bitlockerStatus !== bitlockerStatusEnum.DecryptionInProgress.value)"
                  class="button is-primary"
                  @click="decrypt()"
                >
                  Decrypt all
                </b-button>
              </div>
              <p>
                <strong>Bitlocker passwords</strong>
              </p>
              <card
                internal-card="material-card-content"
              >
                <b-table
                  slot="body"
                  class="principal-table bitlocker-table"
                  :data="diskPartitionsInStore.filter(partition => partition.letter !== null
                    && partition.letter !== '' && partition.isSystemPartition !== true
                    && !partition.isDynamic)"
                  detailed
                  scrollable
                  style="margin-top:1rem; width: 100%"
                  :mobile-cards="true"
                  :row-class="(row) => calculateRowClass(row)"
                >
                  <b-table-column
                    field="letter"
                    label="Partition"
                  >
                    <template v-slot="props">
                      {{ props.row.letter }} <b-tag
                        v-if="props.row.isBootPartition"
                        rounded
                        class="ml-1"
                      >
                        <b>OS Partition</b>
                      </b-tag>
                    </template>
                  </b-table-column>

                  <b-table-column
                    field="bitlockerStatus"
                    label="BitLocker status"
                  >
                    <template v-slot="props">
                      <span
                        v-if="props.row.bitlockerStatus === null
                          || props.row.bitlockerStatus === undefined"
                      >
                        {{ bitlockerStatusEnum.Unknown.key.replace(/([A-Z])/g, ' $1') }}
                      </span>
                      <span
                        v-else-if="[bitlockerStatusEnum.None.value,
                                    bitlockerStatusEnum.Locked.value]
                          .indexOf(props.row.bitlockerStatus) !== -1
                          && storeBitlockerPasswords.some((password) => password.isActive &&
                            password.diskPartitionId === props.row.id)"
                      >
                        {{ bitlockerStatusEnum.FullyEncrypted.key.replace(/([A-Z])/g, ' $1') }}
                      </span>
                      <span v-else>
                        {{ bitlockerStatusEnum.enums.find((x) =>
                          x.value === props.row.bitlockerStatus).key.replace(/([A-Z])/g, ' $1')
                        }}
                      </span>
                      <span
                        v-if="[
                          bitlockerStatusEnum.EncryptionInProgress.value,
                          bitlockerStatusEnum.DecryptionInProgress.value,
                        ].indexOf(props.row.bitlockerStatus) !== -1"
                      >
                        {{ props.row.encryptionPercentage }}%
                      </span>
                    </template>
                  </b-table-column>

                  <b-table-column
                    v-if="diskPartitionsInStore.some(x => x.encryptionMethod
                      !== encryptionMethodEnum.Unknown.value.dbValue)"
                    field="encryptionMethod"
                    label="Encryption method"
                  >
                    <template v-slot="props">
                      {{ encryptionMethodEnum.enums.find(x =>
                        x.value.dbValue === props.row.encryptionMethod).value.name }}
                    </template>
                  </b-table-column>

                  <b-table-column
                    v-if="diskPartitionsInStore.filter(x => x.letter !== null)
                      .some(x => x.bitlockerStatus !== bitlockerStatusEnum.FullyDecrypted.value
                        && x.protectionStatus !== null)"
                    field="protectionStatus"
                    label="Protection status"
                  >
                    <template v-slot="props">
                      {{ props.row.protectionStatus !== null ? protectionStatusEnum.enums.find(
                        x => x.value.dbValue === props.row.protectionStatus).value.name : '' }}
                    </template>
                  </b-table-column>

                  <b-table-column
                    v-if="diskPartitionsInStore.some(
                      x => x.autoUnlockStatus !== autoUnlockStatusEnum.Unknown.value.dbValue)"
                    field="autoUnlockStatus"
                    label="Auto-unlock"
                  >
                    <template v-slot="props">
                      {{ props.row.isBootPartition ? '' : autoUnlockStatusEnum.enums.find(
                        x => x.value.dbValue === props.row.autoUnlockStatus).value.name }}
                    </template>
                  </b-table-column>

                  <b-table-column
                    v-if="validateIfHasPermission(
                      [permissions.ENCRYPTION_EXECUTIONS.value.dbValue,
                       permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                    )"
                    field="actions"
                    label="Actions"
                  >
                    <template v-slot="props">
                      <div style="display: flex; flex-wrap: wrap; gap: 4px">
                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                          ) &&[
                            bitlockerStatusEnum.EncryptionInProgress.value,
                            bitlockerStatusEnum.DecryptionInProgress.value,
                          ].indexOf(props.row.bitlockerStatus) !== -1"
                          class="button is-small is-primary"
                          @click="pause(props.row)"
                        >
                          Pause
                        </b-button>

                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                          ) && [
                            bitlockerStatusEnum.EncryptionSuspended.value,
                            bitlockerStatusEnum.DecryptionSuspended.value,
                          ].indexOf(props.row.bitlockerStatus) !== -1"
                          class="button is-small is-primary"
                          @click="resume(props.row)"
                        >
                          Resume
                        </b-button>

                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                          ) && props.row.protectionStatus === protectionStatusEnum.Unprotected.value
                            .dbValue && props.row.bitlockerStatus === bitlockerStatusEnum
                              .FullyEncrypted.value"
                          class="button is-small is-primary"
                          @click="enableProtection(props.row)"
                        >
                          Protect
                        </b-button>

                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                          ) && props.row.protectionStatus === protectionStatusEnum.Protected.value
                            .dbValue && props.row.bitlockerStatus === bitlockerStatusEnum
                              .FullyEncrypted.value"
                          class="button is-small is-primary"
                          @click="disableProtection(props.row)"
                        >
                          Unprotect
                        </b-button>

                        <b-tooltip
                          v-if="validateIfHasPermission(
                            [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                          ) && [
                            bitlockerStatusEnum.FullyEncrypted.value,
                            bitlockerStatusEnum.EncryptionInProgress.value,
                            bitlockerStatusEnum.EncryptionSuspended.value,
                            bitlockerStatusEnum.Locked.value,
                            bitlockerStatusEnum.None.value,
                          ].indexOf(props.row.bitlockerStatus) === -1"
                          :active="props.row.isBootPartition && !bitLockerCanBeEnabled
                            .osEncryptable"
                          :label="bitLockerCanBeEnabled.osErrorMessage"
                          position="is-top"
                          type="is-dark"
                        >
                          <b-button
                            :disabled="props.row.isBootPartition
                              && !bitLockerCanBeEnabled.osEncryptable"
                            class="is-primary is-small"
                            @click="validateIfOSPartitionIsEncrypted(encrypt, props.row)"
                          >
                            Encrypt
                          </b-button>
                        </b-tooltip>

                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.ENCRYPTION_EXECUTIONS.value.dbValue]
                          ) && [
                            bitlockerStatusEnum.FullyDecrypted.value,
                            bitlockerStatusEnum.DecryptionInProgress.value,
                            bitlockerStatusEnum.DecryptionSuspended.value,
                          ].indexOf(props.row.bitlockerStatus) === -1"
                          class="is-primary is-small"
                          @click="decrypt(props.row)"
                        >
                          Decrypt
                        </b-button>

                        <b-button
                          v-if="validateIfHasPermission(
                            [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                          )"
                          icon-left="eye"
                          :class="{'is-loading': isGettingBitlockerPasswordHistory
                            [props.row.letter]}"
                          class="button is-secondary is-small"
                          @click="validateIfNeedReason('BitLocker password history',
                                                       getBitlockerPasswordHistory, props.row)"
                        >
                          Reveal history
                        </b-button>

                        <b-button
                          v-if="validateBitlockerAutoUnlock(props.row, true)"
                          class="is-primary is-small"
                          @click="enableAutoUnlock(props.row)"
                        >
                          Enable auto-unlock
                        </b-button>

                        <b-button
                          v-if="validateBitlockerAutoUnlock(props.row, false)"
                          class="is-primary is-small"
                          @click="disableAutoUnlock(props.row)"
                        >
                          Disable auto-unlock
                        </b-button>
                      </div>
                    </template>
                  </b-table-column>

                  <template
                    v-if="props.row.bitlockerStatus !== bitlockerStatusEnum.FullyDecrypted.value &&
                      storeBitlockerPasswords.some((password) => password.isActive &&
                        password.diskPartitionId === props.row.id)"
                    slot="detail"
                    slot-scope="props"
                  >
                    <b-table
                      :data="storeBitlockerPasswords.filter(password => password.isActive &&
                        password.diskPartitionId === props.row.id)"
                      :mobile-cards="false"
                      class="inner-table"
                      scrollable
                    >
                      <b-table-column
                        field="keyProtectorType"
                        label="Key Id"
                      >
                        <template v-slot="propsDetails">
                          {{ propsDetails.row.keyProtectorId }}
                        </template>
                      </b-table-column>

                      <b-table-column
                        field="keyProtectorType"
                        label="Type"
                      >
                        <template v-slot="propsDetails">
                          {{ propsDetails.row.keyProtectorType }}
                        </template>
                      </b-table-column>

                      <b-table-column
                        field="recoveryPassword"
                        label="Password"
                        max-width="40px"
                      >
                        <template v-slot="propsDetails">
                          <span
                            v-if="bitlockerRecoveryPassword[propsDetails.row.id]"
                            class="password-style material-card-content is-size-7-mobile
                                is-rounded"
                          >
                            <span
                              v-for="(letter, index) in (propsDetails.row.recoveryPassword
                                || propsDetails.row.password)"
                              :key="index"
                              :class="`${getClass(letter)} mb-2`"
                            >
                              {{ letter }}
                            </span>
                          </span>
                          <b-button
                            v-if="validateIfHasPermission(
                              [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                            ) && bitlockerRecoveryPassword[propsDetails.row.id]"
                            v-clipboard:copy="bitlockerRecoveryPassword[propsDetails.row.id]"
                            icon-left="clipboard-outline"
                            class="button is-small is-primary is-outlined"
                            @click="showToastCopy"
                          >
                            Copy
                          </b-button>
                          <b-button
                            v-if="validateIfHasPermission(
                              [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                            ) && !bitlockerRecoveryPassword[propsDetails.row.id]"
                            class="is-primary is-small"
                            :class="{'is-loading': isGettingBitlockerRecoveryPassword[
                              propsDetails.row.id] }"
                            icon-left="eye"
                            @click="validateIfNeedReason(
                              'BitLocker recovery password', getBitlockerRecoveryPassword,
                              propsDetails.row)"
                          >
                            Reveal
                          </b-button>
                          <b-button
                            v-else-if="validateIfHasPermission(
                              [permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue]
                            ) && bitlockerRecoveryPassword[propsDetails.row.id]"
                            class="button is-small is-primary ml-1"
                            icon-left="eye-off"
                            @click="getBitlockerRecoveryPassword(propsDetails.row)"
                          >
                            Conceal
                          </b-button>
                          <span v-else>********</span>
                        </template>
                      </b-table-column>

                      <b-table-column
                        field="createdAt"
                        label="First detection"
                      >
                        <template v-slot="propsDetails">
                          {{ propsDetails.row.createdAt | moment }}
                        </template>
                      </b-table-column>

                      <b-table-column
                        field="lastDetection"
                        label="Last detection"
                      >
                        <template v-slot="propsDetails">
                          {{ propsDetails.row.lastDetection ?
                            propsDetails.row.lastDetection : propsDetails.row.createdAt | moment }}
                        </template>
                      </b-table-column>
                    </b-table>
                  </template>
                </b-table>
              </card>
            </div>
          </div>
        </card>
      </b-collapse>
    </card>
    <b-modal
      slot="footer"
      :parent="this"
      :active.sync="isBitlockerPasswordHistoryVisible"
      has-modal-card
    >
      <bitlockerPasswordHistory
        :disk-partition="activeBitlockerPartition"
      />
    </b-modal>
    <portal to="modal-container">
      <execution-modal
        v-model="isExecuteModalActive"
        :component="LockDeviceParameters"
        :select-action="executeActionEnum.LOCK_DEVICE.value"
        :selected-devices="[device.id]"
      />
    </portal>
    <portal to="lock-device-button">
      <b-tooltip
        :active="!bitLockerCanBeEnabled.osEncryptable"
        :label="bitLockerCanBeEnabled.messageLock"
        position="is-top"
        type="is-dark"
        class="lock-tooltip"
      >
        <b-button
          v-if="validateIfHasPermission(
            [permissions.GEOLOCATION_AND_LOCK_EXECUTIONS.value.dbValue]
          )"
          :disabled=" !bitLockerCanBeEnabled.osEncryptable"
          class="mb-5 is-danger"
          @click="confirmLockDevice"
        >
          Lock Device
        </b-button>
      </b-tooltip>
    </portal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import card from '@/components/cross/Card.vue';
import RoleMixin from '@/mixins/roles';
import CompanyMixin from '@/mixins/company';
import ExecutionsMixin from '@/mixins/executions';
import toastMessage from '@/helpers/toastMessage';
import snackBarMessage from '@/helpers/snackBarMessage';
import bitlockerPasswordHistory from '@/components/device/DeviceBitlockerPasswordHistory.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import LockDeviceParameters from '@/components/executions/LockDeviceParameters.vue';
import ExecutionModal from '@/components/cross/ModalExecuteAction.vue';
import vtulEnums from '../../../../cross/index';

const { planCapabilities } = vtulEnums.enum.planCapabilities;
const { executeAction: executeActionEnum } = vtulEnums.enum.execution;
const { permissions } = vtulEnums.enum.roles;
const {
  requirementStatus: requirementStatusEnum,
  protectionStatus: protectionStatusEnum,
  encryptionMethod: encryptionMethodEnum,
  autoUnlockStatus: autoUnlockStatusEnum,
} = vtulEnums.enum.bitlocker;

export default {
  name: 'BitlockerCard',
  components: {
    card,
    bitlockerPasswordHistory,
    'execution-modal': ExecutionModal,
  },
  mixins: [ExecutionsMixin, CompanyMixin, RoleMixin],
  props: {
    device: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      bootTypeEnum: vtulEnums.enum.device.bootType,
      hardwareTypes: vtulEnums.enum.hardware.hardwareTypes,
      planCapabilities,
      isBitlockerPasswordHistoryVisible: false,
      isGettingBitlockerPasswordHistory: {},
      bitlockerRecoveryPassword: {},
      isGettingBitlockerRecoveryPassword: {},
      maxQuantity: 10,
      bitlockerStatusEnum: vtulEnums.enum.bitlocker.bitlockerStatus,
      activeBitlockerPartition: {},
      permissions,
      requirementStatusEnum,
      isCardOpen: false,
      isExecuteModalActive: false,
      LockDeviceParameters,
      executeActionEnum,
      protectionStatusEnum,
      encryptionMethodEnum,
      autoUnlockStatusEnum,
    };
  },
  computed: {
    ...mapGetters('storage-disks', { findStorageDisksInStore: 'find' }),
    ...mapGetters('disk-partitions', { findDiskPartitionsInStore: 'find' }),
    ...mapGetters('hardware', { findDeviceHardwareInStore: 'find' }),
    ...mapGetters('bitlocker-passwords', { findBitlockerPasswordsInStore: 'find' }),
    ...mapGetters('devices', { findDeviceInStore: 'find' }),
    osPartition() {
      return this.diskPartitionsInStore.find((x) => x.isBootPartition);
    },
    osPartitionEncrypted() {
      const osPartitionEncryptionStatus = this.osPartition.bitlockerStatus;
      if (osPartitionEncryptionStatus !== this.bitlockerStatusEnum.FullyEncrypted.value
      && osPartitionEncryptionStatus !== this.bitlockerStatusEnum.EncryptionInProgress.value) {
        return false;
      }
      return true;
    },
    storageDisksInStore() {
      const disksStorage = this.findStorageDisksInStore({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
        },
      });
      return disksStorage.data;
    },
    diskPartitionsInStore() {
      const disksPartitions = this.findDiskPartitionsInStore({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          $sort: { position: 1 },
        },
      });
      return disksPartitions.data;
    },
    deviceHardwareInStore() {
      const deviceHardware = this.findDeviceHardwareInStore({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          type: this.hardwareTypes.TPM.value.dbValue,
        },
      });
      return deviceHardware.data;
    },
    bitLockerCanBeEnabled() {
      const response = {
        message: 'BitLocker can not be enabled on this device',
        messageLock: 'This device cannot be locked because it doesn\'t meet the BitLocker requirements',
        color: 'is-danger',
        encryptable: false,
        osEncryptable: false,
        osErrorMessage: 'OS Partition cannot be encrypted',
      };

      if (this.bitlockerRequirements.bitlockerInstalled === requirementStatusEnum.BAD.value
        || this.bitlockerRequirements.systemPartition === requirementStatusEnum.BAD.value) {
        return response;
      }
      response.encryptable = true;

      if (Object.values(this.bitlockerRequirements).filter((x) => x !== undefined)
        .every((x) => x === requirementStatusEnum.GOOD.value)) {
        response.osEncryptable = true;
        response.color = 'is-success';
        response.message = 'BitLocker can be enabled on this device';
        return response;
      }

      if (this.diskPartitionsInStore.filter((x) => x.letter !== null).length > 1) {
        response.color = 'is-warning';
        response.message = 'BitLocker can be enabled on some partitions of this device';
      } else {
        response.color = 'is-danger';
        response.message = 'BitLocker can not be enabled on this device';
      }

      if (this.bitlockerRequirements.tpm !== this.requirementStatusEnum.GOOD.value) {
        response.osErrorMessage = 'OS Partition cannot be encrypted without TPM';
      } else {
        response.osErrorMessage = 'OS Partition does not meet the BitLocker requirements';
      }
      return response;
    },
    bitlockerRequirements() {
      const response = {
        tpm: undefined,
        systemPartition: undefined,
        bitlockerInstalled: undefined,
        uefi: undefined,
        gpt: undefined,
      };
      let tpmVersion;
      if (this.deviceHardwareInStore && this.deviceHardwareInStore.length > 0
        && this.deviceHardwareInStore[0].version) {
        tpmVersion = parseFloat(this.deviceHardwareInStore[0].version);
      }

      if (this.diskPartitionsInStore && this.diskPartitionsInStore.length > 0) {
        response.systemPartition = this.diskPartitionsInStore.some((x) => x.isSystemPartition)
          ? this.requirementStatusEnum.GOOD.value : this.requirementStatusEnum.BAD.value;
      }

      response.bitlockerInstalled = this.device.bitlockerInstalled
        ? this.requirementStatusEnum.GOOD.value : this.requirementStatusEnum.BAD.value;

      if (!tpmVersion) {
        response.tpm = this.requirementStatusEnum.BAD.value;
      } else {
        response.tpm = this.requirementStatusEnum.GOOD.value;
      }
      if (tpmVersion === 1.2) {
        return response;
      }

      response.uefi = this.device.osBootType === this.bootTypeEnum.UEFI.value.dbValue
        ? this.requirementStatusEnum.GOOD.value : this.requirementStatusEnum.BAD.value;
      if (this.storageDisksInStore && this.storageDisksInStore.length > 0
        && this.storageDisksInStore.some((x) => x.isSystem)) {
        response.gpt = this.storageDisksInStore.find((x) => x.isSystem).partitionType.toLowerCase() === 'gpt'
          ? this.requirementStatusEnum.GOOD.value : this.requirementStatusEnum.BAD.value;
      }
      return response;
    },
    storeBitlockerPasswords() {
      let eList;
      if (this.device.id && this.currentCompany) {
        eList = this.findBitlockerPasswordsInStore({
          query: {
            deviceId: this.device.id,
            companyId: this.currentCompany.id,
            $sort: { createdAt: -1 },
          },
        });
      }
      return eList && eList.data ? eList.data : [];
    },
  },
  watch: {
    deviceId() {
      this.fetchDisks();
      this.fetchPartitions();
      this.fetchHardware();
      this.fetchBitlockerPasswords();
    },
    diskPartitionsInStore() {
      this.fetchBitlockerPasswords();
    },
  },
  created() {
    this.fetchDisks();
    this.fetchPartitions();
    this.fetchHardware();
    this.fetchBitlockerPasswords();
  },
  methods: {
    ...mapActions('disk-partitions', { findDiskPartitions: 'find' }),
    ...mapActions('storage-disks', { findStorageDisks: 'find' }),
    ...mapActions('hardware', { findDeviceHardware: 'find' }),
    ...mapActions('bitlocker-passwords', { findBitlockerPasswords: 'find' }),
    ...mapActions('hardware', { findDeviceHardware: 'find' }),

    async fetchDisks(skip = 0) {
      if (!this.currentCompany) return;
      const { total, data } = await this.findDiskPartitions({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.deviceId,
          $skip: skip,
          $limit: 50,
        },
      });
      const totalGet = skip + data.length;
      if (totalGet < total) {
        this.fetchDisks(totalGet);
      }
    },
    async fetchPartitions(skip = 0) {
      if (!this.currentCompany) return;
      const { total, data } = await this.findStorageDisks({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          $skip: skip,
          $limit: 50,
        },
      });
      const totalGet = skip + data.length;
      if (totalGet < total) {
        this.fetchPartitions(totalGet);
      }
    },

    async fetchHardware() {
      if (!this.currentCompany) return;
      await this.findDeviceHardware({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          type: this.hardwareTypes.TPM.value.dbValue,
        },
      });
    },

    async fetchBitlockerPasswords(skip = 0) {
      if (!this.currentCompany || !this.diskPartitionsInStore) return;
      const { total, data } = await this.findBitlockerPasswords({
        query: {
          companyId: this.currentCompany.id,
          deviceId: this.device.id,
          $skip: skip,
          $limit: 50,
        },
      });
      const totalGet = skip + data.length;
      if (totalGet < total) {
        this.fetchBitlockerPasswords(totalGet);
      }
    },

    async getBitlockerRecoveryPassword(bitlockerPassword, reason) {
      if (this.bitlockerRecoveryPassword[bitlockerPassword.id]) {
        this.$set(this.bitlockerRecoveryPassword, bitlockerPassword.id, '');
        return;
      }
      this.$set(this.isGettingBitlockerRecoveryPassword, bitlockerPassword.id,
        true);

      try {
        const execution = await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.device.id], executeActionEnum.GET_BITLOCKER_RECOVERY_PASSWORD.value.id, { reason });
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting BitLocker password.');
          return;
        }
        if (execution.results[0].exitCode === '0') {
          this.$set(this.isGettingBitlockerRecoveryPassword, bitlockerPassword.id, false);
          this.$set(this.bitlockerRecoveryPassword, bitlockerPassword.id,
            bitlockerPassword.recoveryPassword || bitlockerPassword.password);
        }
      } catch (err) {
        toastMessage.showError(err.toString());
        this.$set(this.isGettingBitlockerRecoveryPassword, bitlockerPassword.id, false);
      }
    },
    async getBitlockerPasswordHistory(diskPartition, reason) {
      if (this.isBitlockerPasswordHistoryVisible) {
        this.isBitlockerPasswordHistoryVisible = false;
        return;
      }
      this.$set(this.isGettingBitlockerPasswordHistory, diskPartition.letter, true);
      this.activeBitlockerPartition = diskPartition;
      try {
        const execution = await this.createExecutions(this.currentCompany.id,
          this.$store.getters['auth/user'].id, [this.device.id],
          executeActionEnum.GET_BITLOCKER_RECOVERY_PASSWORD.value.id, { reason });
        if (!execution || execution.results.length === 0) {
          toastMessage.showError('Error getting BitLocker password History.');
          return;
        }
        if (execution.results[0].exitCode === '0') {
          this.isBitlockerPasswordHistoryVisible = true;
          this.$set(this.isGettingBitlockerPasswordHistory, diskPartition.letter, false);
        }
      } catch (err) {
        toastMessage.showError(err.toString());
        this.$set(this.isGettingBitlockerPasswordHistory, diskPartition.letter, false);
      }
    },
    showToastCopy() {
      toastMessage.showSuccess('Password copied to clipboard');
    },
    validateIfNeedReason(elementName, cb, bitlockerPassword) {
      if (this.currentCompany.needReasonToShowPassword) {
        this.getReason(elementName, cb, bitlockerPassword);
      } else {
        cb(bitlockerPassword);
      }
    },
    validateIfOSPartitionIsEncrypted(cb, partition) {
      if (!partition.isBootPartition && !this.osPartitionEncrypted) {
        this.askConfirmation(cb, partition);
      } else {
        cb(partition, true);
      }
    },
    getReason(elementName, cb, param) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: (reason) => (param ? cb(param, reason.text) : cb(reason.text)),
          title: `Show ${elementName} of ${this.device.name}`,
          confirmText: 'Reveal',
          confirmIcon: 'eye',
          message: 'Please type the reason why you need this password',
          getInformationFromTextArea: true,
          informationIsRequired: true,
        },
      });
    },
    askConfirmation(cb, partition) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          onConfirm: () => (cb(partition, false)),
          title: 'Warning: Operating system partition not encrypted.',
          confirmText: 'Continue',
          message: `The Auto-unlock feature won't be enabled if you don't encrypt the
            operating system partition first.`,
        },
      });
    },
    getClass(letter) {
      if ('0123456789'.includes(letter)) {
        return 'number-char';
      }
      if ('~!@#$%^&*_-+=`|(){}[]:;"\'<>,.?/'.includes(letter)) {
        return 'special-char';
      }
      return 'text-char';
    },
    async encrypt(selectedPartition) {
      let partition;
      if (selectedPartition) {
        partition = {
          letter: selectedPartition.letter,
        };
      }
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.ENABLE_BITLOCKER.value.id, { partition });
      const router = this.$router;
      snackBarMessage.showSuccess('Enabling BitLocker.', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async decrypt(selectedPartition) {
      let partition;
      if (selectedPartition) {
        partition = {
          letter: selectedPartition.letter,
        };
      }
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.DISABLE_BITLOCKER.value.id, { partition });
      const router = this.$router;
      snackBarMessage.showSuccess('Disabling BitLocker.', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async enableAutoUnlock(partition) {
      if (!this.osPartitionEncrypted) {
        snackBarMessage.showError(`OS Partition (${this.osPartition.letter}) needs to be encrypted.`);
        return;
      }
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.ENABLE_BITLOCKER_AUTOUNLOCK.value.id, {
          partition: {
            letter: partition.letter,
          },
        });
      const router = this.$router;
      snackBarMessage.showSuccess('Enabling Auto-unlock.', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async disableAutoUnlock(partition) {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.DISABLE_BITLOCKER_AUTOUNLOCK.value.id, {
          partition: {
            letter: partition.letter,
          },
        });
      const router = this.$router;
      snackBarMessage.showSuccess('Disabling Auto-unlock.', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async getBitlockerKeys() {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.GET_BITLOCKER_ENCRYPTION_KEYS.value.id);
      const router = this.$router;
      snackBarMessage.showSuccess('Getting BitLocker Keys...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async pause(partition) {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.PAUSE_ENCRYPTION_OR_DECRYPTION.value.id, {
          partition: {
            letter: partition.letter,
          },
        });
      const router = this.$router;
      snackBarMessage.showSuccess('Pausing...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async lockDevice() {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.LOCK_DEVICE.value.id);
      const router = this.$router;
      snackBarMessage.showSuccess('Lock device in progress...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async resume(partition) {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.RESUME_ENCRYPTION_OR_DECRYPTION.value.id, {
          partition: {
            letter: partition.letter,
          },
        });
      const router = this.$router;
      snackBarMessage.showSuccess('Pausing...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async refreshRequirements() {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.REFRESH_BITLOCKER_REQUIREMENTS.value.id);
      const router = this.$router;
      snackBarMessage.showSuccess('Refreshing requirements...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async enableProtection(partition) {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.ENABLE_PROTECTION.value.id, {
          partition: {
            letter: partition.letter,
          },
        });
      const router = this.$router;
      snackBarMessage.showSuccess('Protecting...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async disableProtection(partition) {
      const execution = await this.createExecutions(this.currentCompany.id,
        this.$store.getters['auth/user'].id, [this.device.id],
        executeActionEnum.DISABLE_PROTECTION.value.id, {
          partition: {
            letter: partition.letter,
          },
        });
      const router = this.$router;
      snackBarMessage.showSuccess('Unprotecting...', {
        text: 'See execution',
        run: () => { router.push(`/${this.currentCompany.id}/execution/${execution.executionGroupId}/1`); },
      });
    },
    calculateRowClass(row) {
      if (row.bitlockerStatus === this.bitlockerStatusEnum.FullyDecrypted.value
          || !this.storeBitlockerPasswords.some((password) => password.isActive
          && password.diskPartitionId === row.id)) {
        return 'hide-details';
      }
      return '';
    },
    confirmLockDevice() {
      this.isExecuteModalActive = true;
    },
    validateBitlockerAutoUnlock(partition, enable) {
      if (!this.validateIfHasPermission([permissions.ENCRYPTION_EXECUTIONS.value.dbValue])) {
        return false;
      }
      if (partition.isBootPartition) return false;
      if (partition.bitlockerStatus !== this.bitlockerStatusEnum.FullyEncrypted.value) return false;
      if (partition.autoUnlockStatus === this.autoUnlockStatusEnum.NotSupported.value.dbValue
        || partition.autoUnlockStatus === this.autoUnlockStatusEnum.Unknown.value.dbValue) {
        return false;
      }
      if (partition.autoUnlockStatus === this.autoUnlockStatusEnum.Enabled.value.dbValue
        && enable) return false;
      if (partition.autoUnlockStatus === this.autoUnlockStatusEnum.Disabled.value.dbValue
        && !enable) return false;
      return true;
    },
  },
};
</script>
<style scoped>
.header-title {
  font-weight: 400;
  font-size: 18px;
}
.informationText {
  margin: 4px 0px;
}
.number-char {
  color: #0572EC;
  display:inline-block;
  width: 8px;
}
.special-char {
  color: #E60000;
  display:inline-block;
  width: 8px;
}
.text-char {
  display:inline-block;
  width: 8px;
}
</style>

<style>
.bitlocker-table .hide-details .chevron-cell a{
  display: none;
}

.lock-tooltip .tooltip-content {
  left: 0 !important;
  transform: initial !important;
}
.lock-tooltip .tooltip-content::before {
  left: 16% !important;
}
</style>
