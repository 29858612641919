<template>
  <b-modal
    ref="modal"
    :active="isActive"
    has-modal-card
    :on-cancel="onClose"
    :parent="this"
  >
    <card
      external-card="modal-material-card card material-card-overflow"
    >
      <div
        slot="body"
        class="material-card-content "
      >
        <component
          :is="component"
          :parent="this"
          :title="selectAction.name"
          :on-update-parameters="updateParameters"
          :selected-devices="selectedDevices"
          :on-result="onResultBeforeExecuteAction"
          :is-from-modal="true"
        />
      </div>
      <div
        slot="footer"
        class="card-footer modal-card-footer"
      >
        <b-button
          class="force-right"
          @click="onClose"
        >
          Cancel
        </b-button>
        <b-button
          :class="{'is-loading': onExecution }"
          class="is-primary ml-2"
          @click="beforeExecuteAction(selectAction, currentCompany.id)"
        >
          Accept
        </b-button>
      </div>
    </card>
  </b-modal>
</template>

<script>
import card from '@/components/cross/Card.vue';
import ExecutionsMixin from '@/mixins/executions';
import CompanyMixin from '@/mixins//company';
import snackBarMessage from '@/helpers/snackBarMessage';

import vtulEnums from '../../../../cross';

const { operationalUiErrors } = vtulEnums.enum.ui;

export default {
  name: 'LockDeviceParameters',
  components: {
    card,
  },
  mixins: [ExecutionsMixin, CompanyMixin],
  model: {
    prop: 'isActive',
    event: 'close',
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    selectedDevices: {
      type: Array,
      default() {
        return [];
      },
    },
    component: {
      type: Object,
      default() {
        return {};
      },
    },
    selectAction: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultParameters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      parametersToExecute: {},
      onExecution: false,
    };
  },
  methods: {
    onClose() {
      this.$emit('close', false);
    },
    onResultBeforeExecuteAction(result, actionId, companyId, showError) {
      if (result) {
        this.parametersToExecute = {
          ...this.defaultParameters,
          ...this.parametersToExecute,
        };

        this.executeAction(actionId, companyId);
        return;
      }
      if (showError) {
        snackBarMessage.showError(showError);
      }
      this.onExecution = false;
    },
    updateParameters(actionParameters) {
      this.parametersToExecute = actionParameters;
    },
    beforeExecuteAction(selectedAction, companyId) {
      if (this.selectedDevices.length < 1) {
        snackBarMessage.showError(operationalUiErrors.UNEXPECTED_ERROR.value.message);
        return;
      }

      this.onExecution = true;
      if (selectedAction.uploadAmtFile) {
        this.$emit('uploadAmtProfile', selectedAction.id, companyId);
      } else if (selectedAction.changeOptInState) {
        this.$emit('validateNewOptInState', selectedAction.id, companyId);
      } else if (selectedAction.changeAmtWirelessStatus) {
        this.$emit('validateNewAMTWirelessStatus', selectedAction.id, companyId);
      } else if (selectedAction.changeLockStatus) {
        this.$emit('validateLockStatusInput', selectedAction.id, companyId);
      } else if (selectedAction.selectEventLog) {
        this.$emit('validateWindowsEventLogsInput', selectedAction.id, companyId);
      } else if (selectedAction.changeBootType) {
        this.$emit('validateNewBootType', selectedAction.id, companyId);
      } else if (selectedAction.changeSecureBoot) {
        this.$emit('validateNewSecureBoot', selectedAction.id, companyId);
      } else if (selectedAction.changeTpmStatus) {
        this.$emit('validateNewTpmStatus', selectedAction.id, companyId);
      } else if (selectedAction.powerAction) {
        this.$emit('validateEmptyFields', selectedAction.id, companyId);
      } else if (selectedAction.lockDevice) {
        this.$emit('validateLockDevice', selectedAction.id, companyId);
      } else if (selectedAction.cleanFolders) {
        this.$emit('validateEmptyDayField', selectedAction.id, companyId);
      } else if (selectedAction.distributeOwnSoftware) {
        this.$emit('validateFile', selectedAction.id, companyId);
      } else if (selectedAction.parameters && this.selectedActionParameters) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.parametersToExecute = undefined;
            this.executeAction(selectedAction.id, companyId);
          } else {
            this.onExecution = false;
          }
        }).catch((error) => { throw new Error(error); });
      } else {
        this.parametersToExecute = undefined;
        this.executeAction(selectedAction.id, companyId);
      }
    },
  },
};
</script>
