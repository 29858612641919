<template>
  <div>
    <p
      v-if="deviceSelect && deviceSelect.name"
      class="margin-bottom"
    >
      <router-link
        :to="`/${$route.params.companyId}/device/${deviceSelect.id}`"
        class="is-text link-style"
      >
        <p class="subtitle">
          {{ deviceSelect.name }}
        </p>
      </router-link>
    </p>
    <p>
      <strong>Approximate address: </strong>
      {{ location ? location.address : '' }}
    </p>
    <p v-if="location && location.city">
      <strong>City: </strong>
      <span>
        {{ location.city }}
      </span>
    </p>
    <p v-if="location && location.region">
      <strong>Region: </strong>
      <span>
        {{ location.region }}
      </span>
    </p>
    <p>
      <strong>Country: </strong>
      <span>
        {{ location ? location.country : '' }}
      </span>
    </p>
    <p>
      <strong>Postal code: </strong>
      <span>
        {{ location ? location.postal : '' }}
      </span>
    </p>
    <p>
      <strong>Latitude: </strong>
      {{ location ? location.loc.split(',')[0] : '' }}
    </p>
    <p>
      <strong>Longitude: </strong>
      {{ location ? location.loc.split(',')[1] : '' }}
    </p>
    <p v-if="location && location.altitude">
      <strong>Altitude: </strong>
      <span>{{ Math.round(location.altitude) }} meters</span>
    </p>
    <p v-if="location && location.detectionMethod">
      <strong>Detection method: </strong>
      <span v-if="location.detectionMethod === locationDetectionMethod.IP_ADDRESS.value">
        IP address
      </span>
      <span v-else-if="location.detectionMethod === locationDetectionMethod.NATIVE.value">
        OS native sensors
      </span>
      <span v-else-if="location.detectionMethod === locationDetectionMethod.WIFI.value">
        Wi-Fi positioning
      </span>
      <span v-else-if="location.detectionMethod === locationDetectionMethod.NEARBY_DEVICES.value">
        Based on near computers
      </span>
    </p>
    <p v-if="location && location.horizontalAccuracy">
      <strong>Horizontal accuracy: </strong>
      <span>{{ location.horizontalAccuracy | number('0') }} meters</span>
    </p>
    <p v-if="location && location.verticalAccuracy">
      <strong>Vertical Accuracy: </strong>
      <span>{{ location.verticalAccuracy | number('0') }} meters</span>
    </p>
    <p>
      <strong>Public IP: </strong>
      <span>
        {{ location ? location.publicIp : '' }}
      </span>
    </p>
    <p
      v-if="location && location.userType"
    >
      <strong>Connection type: </strong>
      <span>
        {{ location.userType | capitalize }}
      </span>
    </p>
    <p>
      <strong>ISP: </strong>
      <span>
        {{ location ? location.publicIpOrg : '' }}
      </span>
    </p>
    <p
      v-if="location && location.gatewayMacAddress"
    >
      <strong>Gateway MAC: </strong>
      <span>
        {{ location.gatewayMacAddress | uppercase }}
      </span>
    </p>
    <p>
      <strong>First connection there: </strong>
      <span v-if="location">
        <timeago
          :since="location.createdAt"
          no-future
        />
      </span>
    </p>
    <p>
      <strong>Last detection there: </strong>
      <span v-if="location">
        <span v-if="isOnline && location.firstRecord">
          Still connected
        </span>
        <timeago
          v-else
          :since="location.updatedAt"
          no-future
        />
      </span>
    </p>
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

export default {
  name: 'LocationDetails',
  props: {
    location: {
      type: Object,
      default: () => {},
    },
    deviceSelect: {
      type: Object,
      default: () => {},
    },
    isOnline: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      locationDetectionMethod: vtulEnums.enum.location.locationDetectionMethod,
    };
  },
};
</script>

<style scoped>
.margin-bottom {
  margin-bottom: 16px;
}
</style>
