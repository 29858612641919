<template>
  <div>
    <card
      slot="external-body"
      external-card="card"
      style-internal-card="flex:auto"
      internal-card="material-card material-card-content"
    >
      <div
        slot="body"
      >
        <p style="margin-bottom:8px;font-weight: bold;">
          Notes
        </p>
        <b-input
          id="inputNote"
          v-model="inputNote"
          v-autosize
          v-validate="'required'"
          name="inputNote"
          type="textarea"
          :placeholder="'Add notes to this device, eg: issues found,' +
            ' comments from the user, etc.'"
          maxlength="30000"
        />
        <span
          v-show="emptyNote"
          class="help is-danger"
        >This field is required </span>
        <b-button
          class="is-primary force-right"
          @click="saveNotes"
        >
          Save
        </b-button>
      </div>
    </card>
    <div
      v-for="note in notesResult"
      :key="note.id"
    >
      <card
        slot="external-body"
        external-card="card"
        style-internal-card="flex:auto;"
        internal-card="material-card material-card-content"
        style="margin-top:8px;"
      >
        <div
          slot="body"
        >
          <div
            class="media"
            style="margin-bottom:0px;"
          >
            <figure
              class="media-left"
            >
              <user-image
                :email="note.userEmail ? note.userEmail : 'support@boardgent.com'"
                :size="60"
                :is-in-navbar="false"
                :lazy="false"
              />
            </figure>
            <div
              class="media-content"
              style="margin: auto;"
            >
              <div class="content">
                <p>
                  <strong>{{ note.userName || note.userEmail || ownerUserName }}</strong>  |
                  <timeago
                    :key="note.id"
                    :since="note.createdAt"
                    no-future
                  />
                  <br>
                  <span style="white-space: pre-line;">{{ note.note }}</span>
                </p>
              </div>
            </div>
          </div>
          <learn-more url="" />
        </div>
      </card>
    </div>
    <card
      style-internal-card="flex:auto"
      style="margin-top:8px;"
    >
      <div slot="external-footer">
        <div
          v-show="notesResult.length > 0 && totalData > limitNotes"
          class="pagination-container"
        >
          <b-pagination
            :total="totalData"
            :current.sync="currentPage"
            :per-page="limitNotes"
            @change="changePagination"
          />
        </div>
      </div>
    </card>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import card from '@/components/cross/Card.vue';
import UserImage from '@/components/cross/UserImage.vue';
import toastMessage from '@/helpers/toastMessage';
import learnMore from '@/components/cross/LearnMore.vue';

export default {
  name: 'DeviceLogNotes',
  components: {
    card,
    'user-image': UserImage,
    'learn-more': learnMore,
  },
  mixins: [makeFindMixin({ service: 'device-log-notes', watch: true })],
  props: {
    deviceId: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      inputNote: '',
      limitNotes: 4,
      skipNotes: 0,
      deviceLogNotesIds: null,
      currentPage: 1,
      lastDeviceLogNotesFetch: null,
      initialNotes: [],
      emptyNote: false,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('user', { findUserInStore: 'find' }),
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    currentUser() {
      return this.$store.getters['auth/user'];
    },
    memberships() {
      const memberships = this.findMembershipsInStore({
        query: {
          companyId: this.currentCompany.id,
          isOwner: true,
          $select: ['userId', 'roleId'],
        },
      });
      return memberships.data;
    },
    ownerUserName() {
      const ids = this.memberships.map((membership) => membership.userId);
      const owner = this.findUserInStore({
        query: {
          id: {
            $in: ids,
          },
          isSupportAccount: false,
        },
      });
      if (owner.data.length < 1) {
        return '';
      }
      if (owner.data[0].name && owner.data[0].lastName) {
        return `${owner.data[0].name} ${owner.data[0].lastName}`;
      }
      if (owner.data[0].name) {
        return owner.data[0].name;
      }
      if (owner.data[0].lastName) {
        return owner.data[0].lastName;
      }
      return owner.data[0].email;
    },
    queryForSearchDeviceLogNotes() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by totalData() method.
      const query = {
        $sort: { createdAt: -1 },
        deviceId: this.deviceId,
        companyId: this.currentCompany.id,
      };

      return query;
    },
    currentPaginationQuery() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by notesResult() method
      return {
        $limit: this.limitNotes,
        $skip: this.skipNotes,
      };
    },
    deviceLogNotesParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchDeviceLogNotes,
        },
      };
    },
    totalData() {
      if (!this.deviceLogNotesLatestQuery || !this.deviceLogNotesPaginationData.default
          || !this.deviceLogNotesPaginationData.default[this.deviceLogNotesLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.deviceLogNotesLatestQuery;
      const totalFromFetch = this.deviceLogNotesPaginationData.default[queryId].total;
      const totalFromLocalStore = (this.findDeviceLogNotesInStore({
        query: {
          deviceId: this.deviceId,
          companyId: this.currentCompany.id,
          $limit: 0,
        },
      })).total;
      return Math.max(totalFromFetch, totalFromLocalStore);
    },
    notesResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);

      if (!this.deviceLogNotesLatestQuery || !this.deviceLogNotesPaginationData.default
          || !this.deviceLogNotesPaginationData.default[this.deviceLogNotesLatestQuery.queryId]
          || !this.deviceLogNotesPaginationData
            .default[this.deviceLogNotesLatestQuery.queryId][paginationId]) {
        return [];
      }
      const { queryId } = this.deviceLogNotesLatestQuery;
      let noteIds = this.deviceLogNotesPaginationData
        .default[queryId][paginationId].ids;

      if (this.currentPage === 1) {
        // This is a trick to display the new executions reactive
        noteIds = this.deviceLogNotes.map((x) => x.id);
      }

      const notes = this.findDeviceLogNotesInStore({
        query: {
          deviceId: this.deviceId,
          companyId: this.currentCompany.id,
          id: {
            $in: noteIds,
          },
          $sort: { createdAt: -1 },
          $limit: this.limitNotes,
        },
      });
      return this.addDataUsersToNotes(notes.data);
    },
    isLoading() {
      return this.notesResult.length < 1 && this.isFindDeviceLogNotesPending;
    },
  },
  watch: {
    notesResult(notes) {
      const ids = notes.map((note) => note.userId);
      this.findUsers({
        query: {
          id: {
            $in: [...new Set(ids)],
          },
        },
      });
    },
  },
  async created() {
    await this.fetchApi();
    this.changePagination(this.currentPage);
  },
  methods: {
    ...mapActions('device-log-notes', { createNoteInfo: 'create' }),
    ...mapActions('user', { findUsers: 'find' }),
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    async saveNotes() {
      try {
        const result = await this.$validator.validateAll();
        if (result) {
          await this.createNoteInfo([{
            userId: this.currentUser.id,
            deviceId: this.deviceId,
            companyId: this.currentCompany.id,
            note: this.inputNote.trim(),
          }]);
          this.emptyNote = false;
          this.inputNote = '';
          toastMessage.showSuccess('The notes were saved successfully.');
          const user = await this.$store.getters['auth/user'];
          const log = {
            user: user.email,
            action: 'Note placed.',
            deviceId: this.deviceId,
            companyId: this.currentCompany.id,
          };
          await this.createAdminLog(log);
        } else {
          this.emptyNote = true;
        }
      } catch (error) {
        toastMessage.showError('Unexpected error saving the notes.');
        throw error;
      }
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipNotes = (this.currentPage - 1) * this.limitNotes;
    },
    addDataUsersToNotes(notes) {
      const ids = notes.map((note) => note.userId);
      const cleanIds = [...new Set(ids)];
      const users = this.findUserInStore({
        query: {
          id: {
            $in: cleanIds,
          },
        },
      });
      const notesWithUserData = notes.map((note) => {
        let userData = users.data.find((user) => user.id === note.userId);

        if (!userData) {
          userData = {
            name: null,
            email: null,
          };
        }
        const {
          name,
          lastName,
          email,
        } = userData;
        let userName = '';
        if (name && lastName) {
          userName = `${name} ${lastName}`;
        } else if (name) {
          userName = name;
        } else if (lastName) {
          userName = lastName;
        } else {
          userName = undefined;
        }
        return {
          ...note,
          userName,
          userEmail: email,
        };
      });
      return notesWithUserData;
    },
    async fetchApi() {
      const membershipList = await this.findMemberships({
        query: {
          $sort: { createdAt: -1 },
          companyId: this.currentCompany.id,
          $limit: 2,
          isOwner: true,
        },
      });
      const ids = membershipList.data.map((membership) => membership.userId);
      await this.findUsers({
        query: {
          id: {
            $in: ids,
          },
          $limit: 2,
        },
      });
    },
  },
};
</script>

<style scoped>
  .pagination-container{
    margin-top: 15px;
  }
</style>
