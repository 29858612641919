<template>
  <fullscreen v-model="fullscreen">
    <card
      external-card="card material-card"
    >
      <card
        slot="external-header"
        internal-card="card-header wrap-content"
        style-internal-header="display: flex; flex: auto"
      >
        <div
          slot="header"
          class="card-header-title"
        >
          <portal-target
            name="rd-options"
          />
          <button
            v-show="!desktopState.connected"
            :disabled="desktopState.connecting || !amtInfo || !amtInfo.redirectionPortEnabled
              || amtInfo.amtStatus !== amtStatus.ONLINE.value.dbValue || waitingForRestart"
            class="button button-margin is-primary"
            @click="connectDesktop(amtInfo.amtId)"
          >
            <strong>{{ waitingForRestart ? "Restarting" : desktopState.buttonText }}</strong>
          </button>
          <button
            v-show="desktopState.connected"
            class="button button-margin is-danger"
            @click="disconnectDesktop"
          >
            <strong>{{ desktopState.buttonText }}</strong>
          </button>
        </div>
        <div
          v-if="amtInfo && amtInfo.redirectionPortEnabled"
          slot="footer"
          class="padding-button"
          style="display: flex"
        >
          <button
            :disabled="!amtInfo || !amtInfo.redirectionPortEnabled
              || amtInfo.amtStatus !== amtStatus.ONLINE.value.dbValue"
            class="button"
            @click="toggleFullscreen"
          >
            <b-icon
              v-if="fullscreen"
              key="compress"
              icon="fullscreen-exit"
            />
            <b-icon
              v-else
              key="expand"
              icon="fullscreen"
            />
          </button>
          <learn-more
            url=""
          />
        </div>
      </card>
      <div
        v-show="desktopState.connected"
        slot="external-body"
        class="columns buttons-container"
      >
        <div class="column is-3-desktop is-3-tablet is-full-mobile button-row">
          <b-dropdown>
            <button
              slot="trigger"
              class="button"
            >
              <span>Power Action</span>
              <b-icon
                icon="menu-down"
              />
            </button>
            <b-dropdown-item
              v-for="action in kvmActions"
              :key="action.value.id"
              :disabled="executingAction"
              class="dropdown-item-router"
              @click="executeAction(action.value)"
            >
              <div class="navbar-item alignIconWithText">
                <b-icon
                  :icon="action.value.icon"
                  style="margin-right:10px;"
                />
                <span>{{ action.value.name }}</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-5-desktop is-5-tablet is-full-mobile button-row center-buttons">
          <button
            class="button button-margin"
            @click="sendcad"
          >
            <b-icon icon="keyboard" /><span>Send Ctrl+Alt+Del</span>
          </button>
          <button
            class="button"
            @click="sendWinKey"
          >
            <b-icon icon="keyboard" /><span>Send Windows+R</span>
          </button>
        </div>
        <div class="column is-4-desktop is-4-tablet is-full-mobile button-row right-buttons">
          <button
            class="button button-margin"
            @click="rotate(-1)"
          >
            <b-icon icon="undo" /><span>Rotate Left</span>
          </button>
          <button
            class="button"
            @click="rotate(1)"
          >
            <b-icon icon="redo" /><span>Rotate Right</span>
          </button>
        </div>
      </div>
      <div
        slot="external-footer"
        class="material-card-content remote-container has-margin-top"
        style="position:relative"
      >
        <b-loading
          :is-full-page="false"
          :active="desktopState.connecting || waitingForRestart"
          :can-cancel="false"
        />
        <canvas
          v-show="amtInfo && amtInfo.redirectionPortEnabled"
          id="desktop-canvas"
          moz-opaque=""
          style="width:100%;max-height:100%;background:#f5efef"
          @contextmenu.prevent
        />
        <div
          v-show="!(amtInfo && amtInfo.redirectionPortEnabled)"
          style="padding: 60px 0px;"
        >
          <p style="font-size: 20px;margin: 12px;">
            KVM Redirection is disabled, enable it to connect
          </p>
          <button
            :class="{ 'button': true, 'is-primary': true, 'is-loading': executingAction }"
            @click="executeAction(enableKVMAction.value)"
          >
            <b-icon :icon="enableKVMAction.value.icon" />
            <span>{{ enableKVMAction.value.name }}</span>
          </button>
        </div>
      </div>
    </card>
  </fullscreen>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import card from '@/components/cross/Card.vue';
import snackBarMessage from '@/helpers/snackBarMessage';
import toastMessage from '@/helpers/toastMessage';
import groupsExecution from '@/mixins/executions';
import ErrorMixin from '@/mixins/error';
import learnMore from '@/components/cross/LearnMore.vue';
import vtulEnums from '../../../../cross/index';
import { CreateAmtRedirect } from '../../assets/scripts/amt/amt-redir-ws-0.1.0';
import { CreateAmtRemoteDesktop } from '../../assets/scripts/amt/amt-desktop-0.0.2';

const { programmerUiErrors, operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;

export default {
  name: 'DeviceKVM',
  components: {
    card,
    learnMore,
  },
  mixins: [groupsExecution, ErrorMixin],
  props: {
    amtInfo: {
      type: Object,
      default() {
        return { };
      },
    },
    deviceId: {
      type: String,
      required: true,
      default: '',
    },
    connectOnCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const executionEnums = vtulEnums.enum.execution.executeAction;
    return {
      desktop: null,
      desktopState: { connected: false, buttonText: 'Connect' },
      currentRotation: 0,
      fullscreen: false,
      amtStatus: vtulEnums.enum.amt.amtStatus,
      kvmActions: executionEnums.enums.filter((x) => x.value.isKvmAction),
      kvmBehavior: vtulEnums.enum.kvm.kvmBehaviorOnExecution,
      waitingForRestart: false,
      enableKVMAction: executionEnums.enums.find(
        (x) => x === executionEnums.ENABLE_KVM_REDIRECTION,
      ),
      executingAction: false,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapState(['mainLayoutStyle']),
  },
  watch: {
    amtInfo() {
      this.disconnectDesktop();
    },
    mainLayoutStyle() {
      this.adjustCanvasClient();
    },
  },
  mounted() {
    this.startMouseEvents('desktop-canvas');
    if (this.connectOnCreate) {
      this.connectDesktop();
    }
  },
  beforeDestroy() {
    this.disconnectDesktop();
  },
  methods: {
    ...mapActions('execution-groups', { createExecutionGroup: 'create' }),
    handleKeyDown(e) {
      if (this.desktop && this.desktop.State === 3) {
        if (e.keyCode === 91) return false;
        return this.desktop.m.handleKeyDown(e);
      }
      return false;
    },
    handleKeyUp(e) {
      if (this.desktop && this.desktop.State === 3) {
        if (e.keyCode === 91) return false;
        return this.desktop.m.handleKeyUp(e);
      }
      return false;
    },
    handleKeyPress(e) {
      if (this.desktop && this.desktop.State === 3) {
        if (e.keyCode === 91) return false;
        return this.desktop.m.handleKeys(e);
      }
      return false;
    },
    mousedown(e) {
      if (this.desktop && this.desktop.State === 3) {
        return this.desktop.m.mousedown(e);
      }
      return false;
    },
    mouseup(e) {
      if (this.desktop && this.desktop.State === 3) {
        return this.desktop.m.mouseup(e);
      }
      return false;
    },
    mousemove(e) {
      if (this.desktop && this.desktop.State === 3) {
        return this.desktop.m.mousemove(e);
      }
      return false;
    },
    sendcad() {
      if (this.desktop && this.desktop.State === 3) {
        return this.desktop.m.sendcad();
      }
      return false;
    },
    // if (keyCode == 91) kk = 0xFFEB
    sendWinKey() {
      if (this.desktop && this.desktop.State === 3) {
        return this.desktop.m.sendkey([[0xFFEB, 1],
          [114, 1], [0xFFEB, 0], [114, 0]]);
      }
      return false;
    },
    rotate(direction) {
      this.currentRotation += direction;
      this.desktop.m.setRotation(this.currentRotation);
      this.adjustCanvasClient();
    },
    createDesktop() {
      this.desktop = CreateAmtRedirect(CreateAmtRemoteDesktop('desktop-canvas'));
      this.desktop.onStateChanged = this.onDesktopStateChange;
      this.addEventListeners();
    },
    connectDesktop(uuid) {
      this.disconnectDesktop();
      this.createDesktop();
      this.currentRotation = 0;
      this.desktop.m.bpp = 1; // 1 for 8 bit-per-pixel, 2 for 16 bits-per-pixel
      this.desktop.m.useZRLE = true; // True, use compression. False, RAW tiles.
      this.desktop.m.showmouse = false; // True to show local mouse cursor.
      this.desktop.m.frameRateDelay = 200; // Frame rate limit
      this.desktop.m.focusmode = 0;
      this.desktop.Start(uuid, 16994, '*', '*', 0, this.amtInfo.companyId);
    },
    disconnectDesktop() {
      if (this.desktop) this.desktop.Stop();
      this.removeEventListeners();
      this.desktop = null;
    },
    disconnectDesktopUntilRestart(time) {
      this.waitingForRestart = true;
      this.disconnectDesktop();
      setTimeout(() => {
        this.connectDesktop(this.amtInfo.amtId);
        this.waitingForRestart = false;
      }, time);
    },
    async onDesktopStateChange(desktop, state) {
      if (desktop.connectstate === 0 && state === 0) {
        toastMessage.showError(operationalUiErrors.CONNECTION_ALREADY_ESTABLISHED.value.message);
      }
      switch (state) {
        case 1:
          await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
            [this.deviceId], vtulEnums.enum.execution.executeAction
              .CONNECT_KVM_REMOTE_DESKTOP.value.id);
          this.desktopState.connecting = true;
          this.desktopState.connected = false;
          this.desktopState.buttonText = 'Connecting';
          break;
        case 2:
          this.desktopState.connecting = true;
          this.desktopState.connected = false;
          this.desktopState.buttonText = 'Connecting';
          break;
        case 3:
          this.desktopState.connecting = false;
          this.desktopState.connected = true;
          this.desktopState.buttonText = 'Disconnect';
          this.adjustCanvasClient();
          break;
        default:
          this.desktopState.connecting = false;
          this.desktopState.connected = false;
          this.desktopState.buttonText = 'Connect';
      }
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      // This was part of a previous fullscreen code, test if needed
      // this.rotate(0);
    },
    removeEventListeners() {
      if (this.desktop) {
        document.body.removeEventListener('keydown', this.handleKeyDown);
        document.body.removeEventListener('keyup', this.handleKeyUp);
        document.body.removeEventListener('keypress', this.handleKeyPress);
        document.body.removeEventListener('mousedown', this.mousedown);
        document.body.removeEventListener('mouseup', this.mouseup);
        document.body.removeEventListener('mousemove', this.mousemove);
      }
    },
    addEventListeners() {
      if (this.desktop) {
        document.body.addEventListener('keydown', this.handleKeyDown);
        document.body.addEventListener('keyup', this.handleKeyUp);
        document.body.addEventListener('keypress', this.handleKeyPress);
        document.body.addEventListener('mousedown', this.mousedown);
        document.body.addEventListener('mouseup', this.mouseup);
        document.body.addEventListener('mousemove', this.mousemove);
      }
    },
    startMouseEvents(id) {
      const container = document.getElementById(id);
      container.onmouseover = this.addEventListeners;
      container.onmouseout = this.removeEventListeners;
    },
    adjustCanvasClient() {
      const canvasClient = document.getElementById('desktop-canvas');
      const { canvas } = canvasClient.getContext('2d');

      let { height } = canvas;
      let { width } = canvas;
      const maxHeight = this.mainLayoutStyle.mainLayoutHeight - (this.fullscreen ? 0 : 180);
      const maxWidth = this.mainLayoutStyle.mainLayoutWidth - (this.fullscreen ? 0 : 106);

      if (width > maxWidth) {
        const ratio = maxWidth / width;
        width = maxWidth;
        height *= ratio;
      }

      if (height > maxHeight) {
        const ratio = maxHeight / height;
        height = maxHeight;
        width *= ratio;
      }

      canvasClient.style.height = `${height}px`;
      canvasClient.style.width = `${width}px`;
    },
    async executeAction(action) {
      this.executingAction = true;
      try {
        if (action.kvmBehavior === this.kvmBehavior.DISCONNECT.value) {
          this.disconnectDesktop();
        } else if (action.kvmBehavior === this.kvmBehavior.RESTART.value) {
          this.disconnectDesktopUntilRestart(17000);
        }
        await this.createExecutions(this.currentCompany.id, this.$store.getters['auth/user'].id,
          [this.deviceId], action.id);
        this.executingAction = false;
        snackBarMessage.showSuccess('Action sent.');
      } catch (err) {
        this.executingAction = false;
        this.throwVtulError(programmerUiErrors.ERROR_SEND_ACTION,
          alertBox.SNACKBAR.value, err);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 769px) {
  .button-row {
    padding-bottom: 0px;
  }
}
@media screen and (min-width: 769px) {
  .right-buttons {
    text-align: right;
  }
  .center-buttons {
    text-align: center;
  }
}
.button-margin {
  margin-bottom: 3px;
}
.dropdown-item-router {
  padding: 0px !important;
  height: 36px;
}
.buttons-container {
  margin-bottom: 0px;
  padding: 0.50rem 0.75rem 0px;
}
.padding-button {
  padding: 0.75rem;
}
.remote-container {
  text-align: center;
  padding: 0px 16px 16px 16px;
}

@media screen and (max-width: 600px) {
 .has-margin-top{
    margin-top:10px;
  }
}

.wrap-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

</style>
