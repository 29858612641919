<template>
  <div>
    <div
      class="columns is-desktop"
    >
      <card
        class="column"
        internal-card="card material-card"
        url-help=""
      >
        <section
          slot="header"
          class="modal-card-body"
        >
          <p><strong>Manufacturer: </strong> {{ device.vendor }}</p>
          <p><strong v-if="device.model">Model: </strong> {{ device.model }}</p>
          <p v-if="device.type !== deviceTypesEnum.UNKNOWN.value.dbValue">
            <strong>Type: </strong>
            {{ deviceTypesEnum.enums.find(x => x.value.dbValue === device.type).value.name }}
          </p>
          <p><strong>Serial: </strong> {{ device.hardwareSerial }}</p>
          <p>
            <strong>Agent version: </strong>
            <span>{{ device.agentVersion }} </span>
            <span
              v-if="agentLastVersion !== '0.0.0' && deviceGroup.agentVersion"
            >
              <span
                v-if="currentCompany.planCapabilities.includes(planCapabilitiesEnum
                  .FIXED_AGENT_VERSION.value)"
              >
                <a
                  v-if="semverUi.compare(agentLastVersion, device.agentVersion) > 0
                    && device.status === deviceStatusEnum.ONLINE.value.dbValue"
                  class="is-text link-style"
                  @click="updateAgent({
                    companyId: currentCompany.id,
                    deviceId: device.id,
                    userId: $store.getters['auth/user'].id,
                  })"
                >
                  Update now
                </a>
                <a
                  v-else
                  class="is-text link-style"
                  @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);"
                >
                  {{
                    0 >= semverUi.compare(agentLastVersion, device.agentVersion)
                      ? 'Latest' : 'Outdated'
                  }}
                </a>
              </span>
              <span
                v-else
              >
                <span
                  v-if="deviceGroup.agentVersion === '0'"
                >
                  <a
                    v-if="semverUi.compare(agentLastVersion, device.agentVersion) > 0
                      && device.status === deviceStatusEnum.ONLINE.value.dbValue"
                    class="is-text link-style"
                    @click="updateAgent({
                      companyId: currentCompany.id,
                      deviceId: device.id,
                      userId: $store.getters['auth/user'].id,
                    })"
                  >
                    Update now
                  </a>
                  <a
                    v-else
                    class="is-text link-style"
                    @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);"
                  >
                    {{
                      0 >= semverUi.compare(agentLastVersion, device.agentVersion)
                        ? 'Latest' : 'Outdated'
                    }}
                  </a>
                </span>
                <span
                  v-else
                >
                  <a
                    v-if="device.status === deviceStatusEnum.ONLINE.value.dbValue"
                    class="is-text link-style"
                    @click="updateAgent({
                      companyId: currentCompany.id,
                      deviceId: device.id,
                      userId: $store.getters['auth/user'].id,
                    })"
                  >
                    {{
                      0 >= semverUi.compare(deviceGroup.agentVersion, device.agentVersion)
                        ? 'Latest' : 'Update now'
                    }}
                  </a>
                  <a
                    v-else
                    class="is-text link-style"
                    @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4600207-how-the-auto-update-works', true);"
                  >
                    {{
                      0 >= semverUi.compare(deviceGroup.agentVersion, device.agentVersion)
                        ? 'Latest' : 'Outdated'
                    }}
                  </a>
                </span>
              </span>
            </span>
          </p>
          <p style="display: inline-block">
            <strong>Agent: </strong>
            <DeviceStatus :status="getAgentColorStatus(device.status)" />
            <span v-if="device.status === deviceStatusEnum.ONLINE.value.dbValue">
              Connected
            </span>
            <span v-else>
              Disconnected
              <timeago
                v-show="device.lastSeen"
                :since="device.lastSeen"
                no-future
                no-date
              /></span>
          </p>
          <p
            v-show="device.status === deviceStatusEnum.ONLINE.value.dbValue
              || device.lastSeen"
          >
            <strong>Agent last seen date: </strong>
            <span v-if="device.status === deviceStatusEnum.ONLINE.value.dbValue">
              Right Now
            </span>
            <span v-else>
              {{ device.lastSeen | moment }}
            </span>
          </p>
          <p
            v-if="validateIfHasCapability([planCapabilitiesEnum.AMT_MANAGEMENT.value])"
            style="display: inline-block;"
          >
            <strong>AMT: </strong>
            <span
              v-if="device.OSVendor === 'Apple' || !device.amtVersion
                || device.amtVersion === '0'"
            >
              <DeviceStatus :status="deviceStatusColorEnum.GRAY" />
              Not supported
            </span>
            <span
              v-else-if="amtInfo"
            >
              <DeviceStatus :status="getAMTColorStatus(amtInfo.amtStatus)" />
              <span>
                {{ amtStatusEnum.enums.find(x => x.value.dbValue === amtInfo.amtStatus)
                  .value.name }}
              </span>
              <timeago
                v-if="amtInfo
                  && amtInfo.amtLastSeen
                  && amtInfo.amtStatus === amtStatusEnum.OFFLINE.value.dbValue"
                :since="amtInfo.amtLastSeen"
                no-future
                no-date
              />
            </span>
            <span v-else>
              <DeviceStatus :status="deviceStatusColorEnum.BLUE" />
              Not provisioned
            </span>
          </p>
          <p
            v-if="validateIfHasCapability([planCapabilitiesEnum.AMT_MANAGEMENT.value])
              && amtInfo && amtInfo.amtLastSeen
              && amtInfo.amtStatus !== amtStatusEnum.CIRA_NOT_SUPPORTED.value.dbValue
              && amtInfo.amtStatus !== amtStatusEnum.UNCONFIGURED.value.dbValue"
          >
            <strong>AMT Last seen date:</strong>
            <span
              v-if="amtInfo.amtStatus === amtStatusEnum.ONLINE.value.dbValue ||
                amtInfo.amtStatus === amtStatusEnum.CONNECTION_ERROR.value.dbValue"
            >
              Right Now
            </span>
            <span v-else>
              {{ amtInfo.amtLastSeen | moment }}
            </span>
          </p>
          <p>
            <strong>Managed since: </strong>
            <span>{{ device.createdAt | moment(false) }}</span>
          </p>
          <p
            v-if="device.status === deviceStatusEnum.ONLINE.value.dbValue"
          >
            <strong>Uptime: </strong>
            <span>
              <timeago
                v-if="device.uptime"
                :since="device.uptime"
                :interval="1000"
                format="HH:mm:ss"
                no-future
                no-date
              />
            </span>
          </p>
        </section>
      </card>
      <card
        class="column"
        internal-card="card material-card"
        url-help=""
      >
        <section
          slot="header"
          class="modal-card-body"
          style="padding-top: 15px"
        >
          <b-field
            label="Device group"
          >
            <searchable-dropdown
              :dropdown-properties="{
                id: 'deviceGroup',
                filterKey: 'id',
                propertyToShow: 'name',
                label: deviceGroup.name,
                showStatus: false,
                icon: {
                  showIcon: false,
                },
                canSelectMultiple: false,
                customSort: false,
                expanded: true,
                markAsActive: false,
              }"
              :filter-objects="deviceGroups"
              :on-update-parameters="changeAssociatedDeviceGroup"
              class="group-dropdown"
            />
          </b-field>
          <b-field
            label="Description"
          >
            <b-input
              v-model="description"
              placeholder="Add a description to this device"
              expanded
              @change.native="saveDescription"
            />
          </b-field>
          <b-field
            label="Tags"
          >
            <b-taginput
              :value="tagData"
              :add="saveTags"
              ellipsis
              icon="label"
              placeholder="Add tags to classify devices"
              expanded
              @input="LowerCaseTags"
            />
          </b-field>
        </section>
      </card>
    </div>
    <div
      slot="body"
      class="is-flex"
      style="justify-content: space-around"
    >
      <b-button
        v-show="!showRemoveButton"
        size="is-small"
        icon-left="delete"
        type="is-danger"
        @click="openRemoveDeviceModal"
      >
        Remove this device
      </b-button>
    </div>
    <b-loading
      is-full-page
      :active="isRemovingDevice"
      :can-cancel="false"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import semver from 'semver';

import card from '@/components/cross/Card.vue';
import DeviceStatus from '@/components/device/DeviceStatus.vue';
import timeago from '@/components/cross/Timeago.vue';
import SearchableDropdown from '@/components/cross/SearchableDropdown.vue';
import ColorStatus from '@/helpers/colorStatus';
import Confirmation from '@/components/cross/ModalConfirmation.vue';

import AgentUpdatedMixin from '@/mixins/agentUpdated';
import CompanyMixin from '@/mixins/company';
import AnalyticsMixin from '@/mixins/analytics';
import ErrorMixin from '@/mixins/error';
import toastMessage from '@/helpers/toastMessage';
import snackBarMessage from '@/helpers/snackBarMessage';
import rolesMixin from '@/mixins/roles';
import vtulEnums from '../../../../cross';

const {
  deviceTypes: deviceTypesEnum, deviceStatus: deviceStatusEnum,
  deviceStatusColor: deviceStatusColorEnum, deviceLockStatus: deviceLockStatusEnum,
} = vtulEnums.enum.device;
const { planCapabilities: planCapabilitiesEnum } = vtulEnums.enum.planCapabilities;
const { executeAction: executionEnums } = vtulEnums.enum.execution;
const { emitToAgent: emitToAgentEnum } = vtulEnums.enum.emitEvent;
const { amtStatus: amtStatusEnum } = vtulEnums.enum.amt;
const { permissions } = vtulEnums.enum.roles;

export default {
  name: 'DeviceOverview',
  components: {
    DeviceStatus,
    timeago,
    SearchableDropdown,
    card,
  },
  mixins: [ErrorMixin, AgentUpdatedMixin, CompanyMixin, AnalyticsMixin, rolesMixin],
  props: {
    device: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    amtInfo: {
      type: Object,
      default() {
        return { };
      },
    },
  },
  data() {
    return {
      deviceTypesEnum,
      deviceStatusEnum,
      planCapabilitiesEnum,
      deviceStatusColorEnum,
      amtStatusEnum,
      semverUi: semver,
      tagData: [],
      description: '',
      deviceGroup: '',
      permissions,
      isRemovingDevice: false,
    };
  },
  computed: {
    ...mapGetters('employee-profiles', { findDeviceGroupInStore: 'find' }),
    deviceGroups() {
      return this.findDeviceGroupInStore({
        query: {
          $sort: { name: 1 },
          $select: ['id', 'name', 'biosPasswordAutoChange', 'localAccountPasswordAutoChange'],
          companyId: this.currentCompany.id,
        },
      }).data;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    showRemoveButton() {
      return this.device && this.device.lockStatus
        && this.device.lockStatus.status !== deviceLockStatusEnum.UNLOCKED.value.dbValue;
    },
  },
  watch: {
    tagData() {
      this.saveTags();
    },
    device(newDevice, oldDevice) {
      if (newDevice && oldDevice && newDevice.id !== oldDevice.id) {
        this.fetchApi();
      }
      this.tagData = newDevice && newDevice.tags ? newDevice.tags : [];
      this.description = newDevice && newDevice.description ? newDevice.description : '';
      this.getDeviceGroup(newDevice);
    },
  },
  created() {
    this.fetchApi();
    if (this.device) {
      if (this.device.tags) this.tagData = this.device.tags;
      if (this.device.description) this.description = this.device.description;
    }
  },
  methods: {
    ...mapActions('devices', { getDevice: 'get', patchDeviceInfo: 'patch' }),
    ...mapActions('devices', ['deleteStoredSelectedDevices', 'updateSelectedDevices']),
    ...mapActions('employee-profiles', { findDeviceGroups: 'find' }),
    ...mapActions('emit-to-agent', { emitToAgent: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    async fetchApi() {
      if (!this.$route.params.deviceId) {
        return;
      }
      const device = await this.getDevice([this.$route.params.deviceId, {
        query: {
          enabled: true,
          companyId: this.currentCompany.id,
        },
      }]);
      if (!device) {
        return;
      }
      this.getDeviceGroup(device);
    },
    changeAssociatedDeviceGroup: debounce(async function changeAssociatedDeviceGroup(deviceGroup) {
      if (this.device && deviceGroup && deviceGroup.id) {
        const device = await this.patchDeviceInfo([this.device.id, {
          employeeProfileId: deviceGroup.id,
          companyId: this.currentCompany.id,
        }]);
        const user = this.$store.getters['auth/user'];
        const log = {
          user: user.email,
          action: `${this.device.name}'s group changed to ${deviceGroup.name}.`,
          companyId: this.currentCompany.id,
        };
        this.createAdminLog(log);
        this.getDeviceGroup(device);
        this.emitToAgent({
          query: {
            type: emitToAgentEnum.EMIT_EXECUTION.value.id,
            executionId: executionEnums.UPDATE_AGENT_ENVIRONMENT_VARIABLES.value.id,
            devicesId: [this.device.id],
          },
        });
      }
    }, 1000),
    saveDescription: debounce(function saveDescription() {
      if (this.device) {
        this.patchDeviceInfo([this.device.id, {
          description: this.description,
          companyId: this.currentCompany.id,
        }]);
      }
      const user = this.$store.getters['auth/user'];
      const log = {
        user: user.email,
        action: `${this.device.name}'s description changed.`,
        companyId: this.currentCompany.id,
      };
      this.createAdminLog(log);
    }, 1000, { leading: true }),
    async getDeviceGroup(device) {
      const query = {
        companyId: device.companyId,
      };
      if (device.employeeProfileId) {
        query.id = device.employeeProfileId;
      } else {
        query.isDefaultProfile = true;
      }
      const deviceGroup = await this.findDeviceGroups({ query });
      this.deviceGroup = deviceGroup.data.length === 1 ? deviceGroup.data[0] : { id: null, name: '' };
    },
    getAMTColorStatus(status) {
      return ColorStatus.getAMTColorStatus(status);
    },
    getAgentColorStatus(status) {
      return ColorStatus.getAgentColorStatus(status);
    },
    saveTags() {
      if (this.device) {
        this.patchDeviceInfo([this.device.id, {
          tags: this.tagData,
          companyId: this.currentCompany.id,
        }]);
      }
    },
    async LowerCaseTags(tags) {
      const tempArray = tags.map((tag) => tag.toLowerCase());
      this.tagData = [...new Set(tempArray)];
      const user = await this.$store.getters['auth/user'];
      const log = {
        user: user.email,
        action: `${this.device.name}'s tags changed.`,
        companyId: this.currentCompany.id,
      };
      await this.createAdminLog(log);
    },
    async removeDevice() {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Remove Device', {
          companyName: this.currentCompany.name,
          companyAddress: this.currentCompany.address,
          companyCity: this.currentCompany.city,
          companyCountry: this.currentCompany.country,
          companyStripeCustomerId: this.currentCompany.stripeCustomerId,
          companyUrl: this.currentCompany.url,
        });
      this.isRemovingDevice = true;
      try {
        await this.createExecutions(
          this.currentCompany.id,
          this.user.id,
          [this.device.id],
          vtulEnums.enum.execution.executeAction.UNINSTALL_AGENT.value.id,
          null,
        );
        await this.patchDeviceInfo([this.device.id, {
          enabled: false,
          status: this.deviceStatusEnum.UNKNOWN.value.dbValue,
        }, {
          query: {
            companyId: this.currentCompany.id,
          },
        }]);
        await this.deleteStoredSelectedDevices({
          device: this.device,
          companyId: this.currentCompany.id,
        });
        this.isRemovingDevice = false;
        toastMessage.showSuccess('The device was removed successfully.');
        this.$router.push(`/${this.currentCompany.id}/devices`);
      } catch (e) {
        this.isRemovingDevice = false;
        snackBarMessage.showError(e.message);
        throw e;
      }
    },
    async openRemoveDeviceModal() {
      this.$buefy.modal.open({
        parent: this,
        component: Confirmation,
        hasModalCard: true,
        props: {
          onConfirm: () => this.removeDevice(),
          title: `Are you sure you want to remove ${this.device.name} from Boardgent?`,
          confirmText: 'Remove device',
          message: `This action cannot be undone. You will not be able to manage this computer because the Boardgent Agent will be uninstalled.  If you need to manage this computer in the future, you will have to reinstall the Boardgent Agent manually.
          <br><br>Please type <b>${this.device.name}</b> to confirm.`,
          expectedText: this.device.name,
        },
      });
    },
    startExecution() {
      this.updateSelectedDevices({
        devices: [this.device.id],
        companyId: this.currentCompany.id,
      });
      this.$router.push({ name: 'executedevice', params: { actionTab: 0, tabPage: 1 } });
    },
  },
};
</script>
